@import "../../../theme/colors";

.dashboard-carousel{
    &#news{
        background-color: $color-dodgerBlue;
        color: $color-white;
        padding: 0 1rem;
        min-height: 200px;
        overflow: hidden;
        position: relative;
        font-weight: 200;

        .carousel-control-prev{
            top: -3rem;
            left: unset;
            bottom: unset;
            right: 2rem;
            cursor: pointer;
        }
        .carousel-control-next{
            top: -3rem;
            bottom: unset;
            cursor: pointer;
        }

        h4 {
            font-size: 1.1rem !important;
            font-weight: 600 !important;
            padding: 1rem 0;
        }
        h5{
            color: $color-white;
            padding: 1rem 0;
            font-weight: 400 !important;
        }

        .promo {
            min-width: 160px;
            padding-right: 1rem;
            margin:0 0 0px 0;
            overflow: hidden;    
             
            // position: absolute;
            // bottom: 5px;
            // left: 5px;
            img {
                width: 100%;
                max-width: 150px;
            }
        }
       
        small{
            color: #f1f1f1;
        }
        .pattern {
            
            background: rgba(255, 255, 255, 0.1);
            width:100%;
            height: 100%;
            border-radius: 50%;
            position: absolute;
            right: -50%;
            top:  -50%;
        }

        button {
            border: 0px solid #f1f1f1;
            border-radius: 4px;
            background-color: #fafafa;
            box-shadow: 0px 0px 0px 0px #f1f1f1;
            color: #009ed7;
        }
    }
}


@media screen and (max-width: 1200px) {
    
    .dashboard-carousel{
        &#news{

            .promo {
                display: none;
            }

        }
    }
}

@media screen and (max-width: 576px) {
    .dashboard-carousel .carousel-inner .btn{
        min-height: 45px;
    }
}