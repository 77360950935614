@import "reset", "colors", "variables", "utilities";
@import "~bootstrap/scss/bootstrap";

body {
  overflow-y: auto;
}

.bg-ligth-blue-color {
  background-color: $color-lightBlue !important;
}

.roundBorder {
  border-radius: 4px;
}

.cardBlueBorder {
  border: 2px solid $color-ethicsBlue;
  border-radius: 5px;
  background: $color-lightBlue;
}

.cardNoBorder {
  border: none;
}

.blueInput {
  border: 1px solid $color-ethicsBlue !important;
  border-radius: 5px !important;
  background: $color-lightBlue !important;
  font-size: 14px !important;
}

.dropdown-flags {
  font-size: 14px !important;
  height: 30px !important;
  width: auto !important;
}

label {
  margin-bottom: 0px !important;
}

ul {
  list-style-type: "- ";
  padding-left: 15px;
}

// MAIN PAGE CONTAINER
.page {
  background-color: $color-lightBackground;
  padding: 20px 30px 30px 30px;
  position: relative;
  flex-grow: 1;

  @media (min-width: 768px) {
    padding: 20px 30px 30px 60px;
    
  }

}
.dark-mode .page {
  background-color: $color-darkBackground;
}

// MAIN MODALS CONTAINER
.modal {
  backdrop-filter: blur(1px);
}
.modal-content {
  padding: 30px;
}
.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}
.dark-mode .modal-content {
  background-color: $color-darkThree;
  color: #b0bec5;
}

// DROPDOWN
.dropdown {
  .dropdown-menu {
    border-radius: 4px;
    padding: 8px 6px;
    .dropdown-item {
      border-radius: 4px;
      svg {
        width: 24px;
        height: 24px;
      }
      svg g {
        fill: $color-ethicsBlue !important;
      }
    }
  }
}
.dark-mode .dropdown {
  .dropdown-menu {
    color: $color-blueGray2;
    background-color: $color-oxfordBlue2;
    // padding: 8px 6px;
    .dropdown-item {
      border-radius: 4px;
      &:hover {
        background-color: $color-darkThree;
      }
    }
  }
}
// BACKGROUND
.dyBackground {
  background-color: $color-lightBackground;
}
.dark-mode .dyBackground {
  background-color: $color-darkBackground;
}

// DYNAMYC THEME PARA CUADROS DE CONTENIDO
.dyTheme1 {
  background-color: $color-white;
}
.dark-mode .dyTheme1 {
  background-color: $color-darkThree;
  label,
  h3,
  h4,
  h5,
  h6 {
    color: $color-coolGray;
  }
  a,
  p,
  span,
  small,
  li {
    color: $color-blueGray2;
  }
}

.dyTheme2 {
  background-color: $color-blueGray50;
}
.dark-mode .dyTheme2 {
  background-color: $color-darkThree;
  label,
  h3,
  h4,
  h5,
  h6 {
    color: $color-coolGray;
  }
  p,
  span,
  small,
  li {
    color: $color-blueGray2;
  }
}

.dyTheme3 {
  background-color: $color-paleBlue;
}
.dark-mode .dyTheme3 {
  background-color: $color-darkFour;
  label,
  h3,
  h4,
  h5,
  h6 {
    color: $color-coolGray;
  }
  p,
  span,
  small,
  li {
    color: $color-blueGray2;
  }
}

.dyTheme4 {
  background-color: $color-lightBackground;
}
.dark-mode .dyTheme4 {
  background-color: $color-darkFour;
  label,
  h3,
  h4,
  h5,
  h6,
  strong {
    color: $color-coolGray;
  }
  p,
  span,
  small,
  li {
    color: $color-blueGray2;
  }
}

.dyBorder1 {
  border: 1px solid $color-lightBorder;
}
.dark-mode .dyBorder1 {
  border: 1px solid $color-darkBorder;
}

.dyBorder2 {
  border: 1px solid $color-lightBlue2;
}
.dark-mode .dyBorder2 {
  border: 1px solid $color-darkBorder;
}

.dyBorder3 {
  border: 1px solid $color-green;
}

.dark-mode .border-bottom {
  border-bottom-color: $color-darkBorder;
}

.rounded {
  border-radius: 6px;
}
.half-rounded {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

//DYNAMIC THEME FOR ICON
.dyIcon1 {
  path {
    fill: $color-darkBackground;
  }
}
.dark-mode .dyIcon1 {
  path {
    fill: $color-blueGray50;
  }
}
.dyIcon2 {
  path {
    fill: $color-blueGray900;
  }
}

.dark-mode .dyIcon2 {
  path {
    fill: $color-blueGray2;
  }
}

.dyIcon3 {
  path {
    fill: $color-silver2;
  }
}

.dyIcon3 {
  path {
    fill: $color-silver2;
  }
}

.fill-yellow {
  path {
    fill: $color-yellow;
  }
}

//dark-mode - table list
.dark-mode .rdt_TableRow:hover {
  background-color: $color-darkFour;
  border: none;
  border-radius: 1px;
  outline-color: $color-blueGray700;
}

.dark-mode pre {
  color: white;
}
//----custom ScrollBar ----//
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: $color-gray2;
}

.dark-mode ::-webkit-scrollbar-track {
  background-color: $color-oxfordBlue2 !important;
}

::-webkit-scrollbar-thumb {
  background: $color-ethicsBlue;
  border-radius: 25px;
}

.dark-mode ::-webkit-scrollbar-thumb {
  background: $color-blue2;
}

::-webkit-scrollbar-thumb:hover {
  background: $color-blue;
}
