@import '../../../theme/colors';

label.selected {
    color: #546E7A !important;

    &.dark {
        color: #fff !important;
    }
}

label {
    font-weight: 600;
    font-size: 0.85rem;
    margin-bottom: 0.5em !important;
    &.dark {
      color: $color-coolGray;
    }
    small{
      font-size: 14px;
      font-weight: normal;
    }
  }

.required {
    color: #d81b60 !important;
}

.helptip {
  width: 18px;
  height: 18px;
  background: linear-gradient(180deg, #f9fbfd 0%, #d7dee3 100%);
  font-size: 11px;
  text-align: center;
  color: #8aa1ac !important;
  border-radius: 9px;
  position: absolute;
  margin: 2px 0 0 5px;
  cursor: pointer;

  &:hover {
    background: linear-gradient(180deg, #d7dee3 0%, #f9fbfd 100%);
    color: #9ec2d3 !important;
  }
}