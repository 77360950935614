

.breadcrumb {
    margin-top: 1em;
    font-size: 11px !important;
    font-weight: 400;
    width: 100%;
    // padding: 0px !important;

    .item {
        position: relative;
        // padding: 5px 0;
        color:#546F7A;
        // font-style: italic;

        a {
            cursor: pointer;
        }

        &:hover {
            color: #66818d;
        }
        
       border:0px solid #000;
       background: none;
    }

    // Item from second one to the last one
    // .item:not(:first-child) {
    //     padding-left: 30px;
    //     padding-right: 0px;
    // }

    // add an icon 
    // .item:not(:first-child)::before {
    //     content: '';
    //     display: block;
    //     position: absolute;
    //     width: 14px;
    //     height: 14px;
    //     margin: 0 0px;
    //     left: 10px;
    //     top:9px;
    //     background-image: url('/assets/icons/vuesax-outline-arrow-right_4.svg'); /* Replace with your arrow SVG */
    //     background-size: cover;
    //     filter: invert(50%) sepia(39%) saturate(226%) hue-rotate(153deg) brightness(89%) contrast(90%);
    // }

    .item:last-child {
        font-weight: 600;
        font-size: 11px;
    }

}