@import "../../theme/colors";

.general-header {
  border-radius: 6px;
  padding: 1.25rem 1rem 1.25rem 2rem;
}

.dark-mode .general-header {
  .general-header-title {
    color: $color-paleGrey;
  }

  .backButton {
    filter: contrast(100%) brightness(0%) invert(95%) sepia(100%) saturate(0%) hue-rotate(350deg) brightness(103%) contrast(103%);
  }
}

.title-icon {
  padding: 0 auto;
  margin: 0 0.25vw;
}


#header-report {
  border-radius: 5px;
  border: 2px solid #009ed7;
  background: linear-gradient(186deg, #ffffff 0%, #009ed7 520%);
  background-blend-mode: normal;

  .report-info {
    display: flex;
  }

  .title-icon {
    margin: 0 .25vw;
    padding: 0 auto
  }

  .general-header-title {
    font-size: 24px;
    font-weight: bolder !important;
  }

  // .progress-bar {
  //   background-color: #009ed7 !important
  // }

  .progress-bar-animated {
    animation: progress-bar-stripes 1s linear infinite
  }

  .progress-bar-striped {
    background-image: linear-gradient(45deg, #ffffff26 25%, #0000 0, #0000 50%, #ffffff26 0, #ffffff26 75%, #0000 0, #0000);
    background-size: var(--bs-progress-height) var(--bs-progress-height)
  }

  .bg-info {
    --bs-bg-opacity: 1;
    background-color: rgba(13, 202, 240, var(--bs-bg-opacity)) !important;
    background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important
  }

  .breadcrumb {
    justify-content: right;
    margin-bottom: 0px;
  }

  path {
    fill: #009ed7;
  }

}

#inform {

  .data-inform {
    align-items: center;
  }



  .status {
    display: flex;
    align-items: center;
  }

  .data-status {
    width: 199px;
    height: 37px;
    border-radius: 5px;
    border: 2px solid !important;
    // background: #ffe594;
    background-blend-mode: normal;
    box-shadow: 0px 2px 4px rgba(100, 100, 100, 0.5);
    padding: 5px;

    &.new {
      border-color: $color-blue !important;
      background-color: lighten($color: $color-blue, $amount: 30) !important;
    }

    &.finish {
      border-color: $color-yellow !important;
      background-color: lighten($color: $color-yellow, $amount: 30) !important;
    }

    &.pending {
      border-color: $color-purple !important;
      background-color: lighten($color: $color-purple, $amount: 30) !important;
    }

    &.process {
      border-color: $color-green !important;
      background-color: lighten($color: $color-green, $amount: 30) !important;
    }

    &.closed {
      border-color: $color-red !important;
      background-color: lighten($color: $color-red, $amount: 30) !important;
    }

    &.draft {
      border-color: $color-blueGray !important;
      background-color: lighten($color: $color-blueGray, $amount: 30) !important;
    }
  }

  .data-status span {
    display: flex;
    justify-content: center;
  }

  .users {
    margin-left: 25px;
    display: flex;
  }

  .users .user1 {
    left: 1.36px;
    top: 1.36px;
    width: 24px;
    height: 24px;
    background: #009ed7;
    border: 2px solid #dee1e4;
    background-blend-mode: normal;
    border-radius: 50%;
    margin-left: -10px;
  }

  .users .user-text {
    margin-left: 5px;
  }

  .followup {
    font-size: 14px;
  }

  .followup-info {
    font-size: 22px;
    font-weight: bold;
    color: #009ed7;
  }

  .actions-icon {
    display: flex;
    justify-content: flex-end;
  }

  .actions-icon .actionicon {
    display: flex;
    margin: 0px 5px;
    flex-wrap: nowrap;
    flex-direction: row;
  }



  .background.dark-mode .btn.download-btn {
    background-image: linear-gradient(180deg, #2c2c3a, #263238 120%) !important;
    border: 1px solid #2c2c3a !important;
    color: #78909c !important
  }

  .blocking g {
    fill: #FF0000;
    align-items: center;
    animation: pulse-icon 1.5s infinite;
  }

  @keyframes pulse-icon {
    0% {
      transform: scale(0.7);
      opacity: 0.8;
    }

    50% {
      transform: scale(1);
      opacity: 1;
    }

    100% {
      transform: scale(0.7);
      opacity: 0.8;
    }
  }

}




.channel-icon {
  background-color: #ffaf00;
  border-radius: 4px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.newmonitor-iconnew {
  border-radius: 4px;
  width: 32px;

  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-blue;

  g {
    fill: $color-white !important;
  }
}

.newmonitor-icondraft {
  border-radius: 4px;
  width: 32px;

  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-Gray;

  g {
    fill: $color-white !important;
  }
}

.newmonitor-iconpending {
  border-radius: 4px;
  width: 32px;

  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-purple;

  g {
    fill: $color-white !important;
  }

}

.newmonitor-iconprocess {
  border-radius: 4px;
  width: 32px;

  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-green;

  g {
    fill: $color-white !important;
  }
}

.newmonitor-iconfinish {
  border-radius: 4px;
  width: 32px;

  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-yellow;

  g {
    fill: $color-white !important;
  }
}

.newmonitor-iconclosed {
  border-radius: 4px;
  width: 32px;

  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-red;

  g {
    fill: $color-white !important;
  }
}

.headers {
  &__primary {
    border: 2px solid $color-ethicsBlue;
    background: linear-gradient(186deg, $color-white 0%, $color-ethicsBlue 520%);
    g :not(.backButton), path :not(.backButton) {
      fill: $color-ethicsBlue;
    }
  }
  &__danger {
    border: 2px solid $color-pink;
    background: linear-gradient(186deg, $color-white 0%, $color-pink 520%);
    g :not(.backButton), path :not(.backButton) {
      fill: $color-pink;
    }
  }
}