@import '../../../theme/colors';
@import '../../../theme/variables';

.form-preview {
  textarea {
    resize: none;
  }
  .form-control {
    transition: all 250ms;
    border-radius: 4px;
    margin-top: 0px;
    // font-size: 14px;
    &:focus {
      border-color: $color-ethicsBlue;
      box-shadow: none;
    }
  }
  .is-invalid-container {
    background-color: rgba(216,27,96,0.3);
    border: 2px solid rgb(216,27,96) !important;
  }
  // header del preview donde esta el steper y el titulo del step
  .form-preview-header {
    display: flex;
    justify-content: space-between;
    .form-preview-header-name {
      h3 {
        font-weight: 600;
        font-size: 16px;
      }
      p {
        font-size: 14px;
        color: $color-blueGray;
      }
    }
    .form-preview-header-description {
      font-size: 14px !important;
      color: $color-Gray !important;
    }
  }
  // contenedor que engloba a todos los inputs
  .form-grid {
    position: relative;
    * {
      color: $color-blueGray;  
    }
    label {
      font-weight: 500;
      font-size: 18px;
      margin-bottom: 0.5em !important;
      &.m-0 {
        margin: 0!important;
      }
      &.dark-mode {
        color: $color-coolGray;
      }
    }
  }
  // contenedor de cada input
  .preview-input-container {
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding-top: 6px;
    padding-bottom: 6px;
    animation: flashAnimation 800ms ease-in-out;
    .preview-input-container-inp {
      padding: 0px;
      margin-top: auto;
      &.no-mt-auto {
        margin-top: 0px;
      }
    }
    // .form-select-input {
    //   width: 90% !important;
    // }
  }
  // .preview-input-err-msg {
  //   margin-bottom: 24px;
  // }

  .form-check {
    .form-check-label  {
      font-size: 16px;
    }
  }


  .people-involved {
    border-radius: 4px;
    border: solid 1px #bdcad0;
    // display: grid;
    // grid-template-columns: repeat(3, 1fr);
    margin-top: 1.5rem !important;
    padding: 1em !important;

    button.mcbtn.text-center.outline.btn.btn-primary {
        font-size: 12px !important;
        padding: 0px;

        label {
          font-size: 12px !important;
        }
    }

    /* src/SelectableList.css */
    .selectable-list {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    }

    .selectable-item {
      position: relative;
      background-color: grey;
      color: white !important;
      font-size: 14px;
      padding: 5px 10px;
      padding-right: 3em;
      border-radius: 5px;
      display: flex;
      align-items: center;

      margin: 5px;
      
      button {
        position: absolute;
        right: 0px;
        bottom:0px;
        height: 100%;
        background-color: darkgray; /* Cambia este color según lo que necesites */
        border: none;
        color: white;
        margin-left: 10px;
        padding: 2px 10px;
        border-radius: 3px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        font-size: 12px;
        cursor: pointer;
      }
      strong {
        color: white;
      }
    }

    .victim {
      //background-color: #5B9AFF;
      border: 1px solid #5B9AFF;
      background-color: white;
      color: #3181ff !important;

      button {
        background-color: #3181ff;
      }

      strong {
        color: #5B9AFF !important;
      }
    }

    .witness {
      background-color: #69D586;
      
      border: 1px solid #69D586;
      background-color: white;
      color: #44CB66 !important;

      button {
        background-color: #44CB66;
      }
      strong {
        color: #44CB66 !important;
      }
    }


    .offender {
      background-color: #E56B64;

      border: 1px solid #DE463D;
      background-color: white;
      color: #DE463D !important;

      button {
        background-color: #DE463D;
      }

      strong {
        color: #DE463D !important;
      }
    }


    .accomplice {
      background-color: #FFBF32;

    
      border: 1px solid darkgrey;
      background-color: white;
      color: darkgrey !important;

      button {
        background-color: darkgrey;
      }

      strong {
        color: darkgrey !important;
      }
    }

  .legend {
      margin-bottom: 10px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      font-size: 14px;
      justify-items: center; /* Centra horizontalmente los elementos en cada celda */
      align-items: center; 
  }
  
  .legend-item {
    display: flex;
    align-items: center;
    justify-content: center; /* Centra horizontalmente el contenido dentro de cada `legend-item` */
    margin-right: 15px;
    text-align: center;
  }
  
  .legend-color {
      width: 20px;
      height: 20px;
      margin-right: 5px;
      border-radius: 50%;
  }
  
  .legend-victim { background-color: #3181ff; }
  .legend-witness { background-color: #44CB66; }
  .legend-offender { background-color: #DE463D; }
  .legend-accomplice { background-color: #FFAF00; }
  
  .not-involved {
    text-align: center;
    color: #999;
  }


  }

  .form-input-involved {
    .form-input-involved__add-involved {
      button {
        width: 100%;
        margin-top: 8px;
        margin-left: 4px;
      }
      @media (min-width: 992px) {
        display: flex;
        align-items: end;
        & > div:nth-child(2) {
          padding: 0px 4px 0px 8px;
        }
      }
    }
    .form-input-involved__columns {
      ul {
        padding: 0;
        margin: 0;
        list-style: none;
      }
      @media (min-width: 992px) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        margin-top: 16px;
      }
      
    }
    .delete-involved {
      cursor: pointer;
      color: white;
      line-height: 12px;
      margin-left: 4px;
      padding-left: 4px;
      border-radius: 4px;
      height: 16px;
      width: 16px;
      background-color: $color-red;
    }
  }

  .invalid-msg {
    color: #dc3545;
  }


  .date-range-input-start {
    padding: 0px;
    padding-right: 10px;
  }
  .date-range-input-end {
    padding: 0px;
    padding-left: 10px;
  }

  .stepWrapper {
    margin: 0px;
    .step-row-2 {
      &::before{
        background: $color-paleGrey2!important;
      }
    }
    .steps {
      margin-right: 20px;
      i {
        font-family: 'Segoe UI'!important;
      }
      &:first-child {
        i::before {
          content: "1";
        }
      }
      &:nth-child(2) {
        i::before {
          content: "2";
        }
      }
      &:nth-child(3) {
        i::before {
          content: "3";
        }
      }
      &:nth-child(4) {
        i::before {
          content: "4";
        }
      }
      .default-node {
        width: 25px!important;
        height: 25px!important;
        border: 1px solid $color-silver!important;
        background: linear-gradient(to bottom, $color-white, $color-paleGrey2 120%)!important;
        color: #8aa1ac!important;
        box-shadow: none!important;
        &.active {
          background: $color-ethicsBlue!important;
          border-color: $color-ethicsBlue!important;
          color: $color-white!important;
        }
        i {
          font-size: 12px!important;
        }
      }
    }
    .steps:last-child {
      margin-right: 0;
    }
  }


}

.form-preview::-webkit-scrollbar {
  width: 3px;
}

.form-preview::-webkit-scrollbar-thumb {
  background: #009ED7;
}

.form-buttons {
  // padding-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  .btn-form, .btn-send {
    font-size: 13px;
    font-weight: $font-weight-semibold;
    height: 45px;
    width: 100%;
    margin: 8px 0px;
    outline: none;
    border: none;
    &:focus {
      box-shadow: none;
    }
  }
  .btn-form {
    background-color: #009ED7!important;
    &:hover {
      background-color: #0089c4!important;
      box-shadow: 0px 0px 10px 0px rgba(0,158,215, 0.889);
    }
  }
  .btn-form.ant{
    background-color: #a0d4fa !important;
    color: #ffffff !important;
    &:hover {
      background-color: #87cbfc !important;
      box-shadow: 0px 0px 10px 0px rgba(160,212,250, 0.889);
    }
  }
  .btn-send {
    background-color: #007b6f !important;
    &:hover {
      background-color: #006e65 !important;
      box-shadow: 0px 0px 10px 0px rgba(0,123,111, 0.889);
    }
  }
}

.label-required {
  color: #d81b60 !important;
}

.container-checkbox {
  background-color: #f3f7fa;
  border-radius: 15px;
  border: 1px solid #d8dfe3;
  &.dark-mode {
    background-color: #242836;
    border: 1px solid #363742;
    input[type="radio"] {
      border: 1px solid #8aa1ac;
    }
    a.selected {
      color: #fff;
    }
    &.selected {
      background-color: rgba(92, 102, 110, 0.7);
      border: 1px solid #fff;
      box-shadow: rgb(255, 255, 255) 0px 0px 6px 1px;
    }
  }
  &.selected {
    background-color: rgb(232, 245, 253); 
    border: 1px solid #a0d4fa;
    box-shadow: #a0d4fa 0px 0px 6px 2px;
  }
}

.container-checkbox2 {
  // background-color: #f3f7fa;
  // border-radius: 15px;
  // border: 1px solid #d8dfe3;
  display: flex;
  flex-wrap: wrap;
  &.dark-mode {
    // background-color: #242836;
    // border: 1px solid #363742;
    input[type="radio"] {
      border: 1px solid #8aa1ac;
    }
    a.selected {
      color: #fff;
    }
    &.selected {
      // background-color: rgba(92, 102, 110, 0.7);
      // border: 1px solid #fff;
      // box-shadow: rgb(255, 255, 255) 0px 0px 6px 1px;
    }
  }
  &.selected {
    // background-color: rgb(232, 245, 253); 
    // border: 1px solid #a0d4fa;
    // box-shadow: #a0d4fa 0px 0px 6px 2px;
  }
}
.catalogue-container {
  display: grid;
  padding: 0;
  column-gap: 16px;
  &.t-col-4 {
    grid-template-columns: repeat(3, 1fr);
  }
  &.t-col-6 {
    grid-template-columns: repeat(2, 1fr);
  }
  &.t-col-12 {
    grid-template-columns: 1fr;
  }
  &.col-gap {
    column-gap: 16px;
  }
  &.row-gap {
    row-gap: 16px;
  }
}



a.dark-mode {
  color: $color-coolGray !important;
}

label.selected, p.selected {
  color: #546E7A !important;
  &.dark-mode {
    color: #fff !important;
  }
}

input[type="radio"],
input[type="checkbox"] {
  cursor: pointer;
  border: 1px solid #363742;
}

input[type="radio"]:hover,
input[type="checkbox"]:hover {
  border: 1px solid #009ed7 !important;
  box-shadow: #009ed750 0px 0px 3px 2px;
}

.modal-example-dialog.dark-mode {
  background-color: #242836;
  border: 1px solid #363742;
}

.cus-modal-title {
  color: rgb(0, 0, 0) !important;
}

.animation {
  position: relative;
  animation: animation 0.2s ease-out;
}

.flash-new-child {
  animation: flashAnimation 800ms ease-in-out;
}

hr {
  border-top: 2px solid !important;
}

@keyframes animation {
  0% {
    opacity: 0;
    right: -100px;
    // right: -70px;
  }

  100% {
    opacity: 1;
    right: 0;
  }
}

@keyframes flashAnimation {
  0% {
    background-color: rgba(205, 220, 57, 0.4);
    border: none;
  }
  100% {
    background-color: transparent;
    border: none;
  }
}

@media (min-width: 768px) {
  .form-buttons {
    flex-direction: row;
    .btn-form, .btn-send {
      margin: 0 4px;
      width: 160px;
    }
  }
}