@import "../../../../../theme/colors";
//From AddSimpleInv.jsx
.simpleInvestigation {
  padding: 0.8rem;
  margin-top: 20px;
  &__infoGeneral {
    margin-top: 2vh;
    margin-bottom: 5px;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    padding: 15px;
  }
  &__subHeader {
    display: flex;
    justify-content: space-between;
    padding: 10px;
  }
  &__line-chart {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  &__line {
    position: relative;
    width: 100%;
    height: 2px;
  }
  &__axis {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 3px;
    font-size: 0.75rem;
    font-weight: 600;
  }
  .tick {
    margin-top: 3px;
  }
  &__actionBtns {
    min-height: 8vh;
    display: flex;
    justify-content: end;
    align-items: center;
    margin: auto 0;
  }
}

.informative {
  border: none !important;
  background: transparent !important;
  border: none;
}

.conclusionbox {
  font-size: 14px;
  font-weight: 400;
  white-space: pre-line;
  margin-bottom: 2rem;
}

.conclusionhead {
  font-size: 16px !important;
  font-weight: 500 !important;
}

//dark-mode
.dark-mode .informative {
  background-color: transparent !important;
  font-family: "Courier New", Courier, monospace;
  text-align: justify;
  border: none !important;
}

//From content Modal of ConcludeInv.jsx
.concludeInv {
  .modal-header .btn-close {
    color: antiquewhite !important;
  }
  &__header {
    gap: 1.5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column wrap;
    text-align: center;
    position: relative;
  }
  &__body {
    max-width: 100%;
    margin: auto;
  }
  &__actionBtns {
    display: flex;
    justify-content: space-evenly;
  }
  &__confirm {
    text-align: center;
  }
  .description {
    width: 75%;
    margin: 0 auto;
  }
  .descriptionText {
    text-align: justify;
  }
}

//From content Modal of ReOpenInv.jsx
.reOpenInv {
  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    margin: 0 auto;
    position: relative;
  }
  &__icon {
    margin: 0 auto 2vh;
    scale: 1.6;
    path {
      fill: $color-yellow;
    }
  }
  &__text {
    display: flex;
    text-align: center;
  }
  &__actionBtns {
    margin-top: 3vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}
.btnClose {
  background-color: transparent;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  border: none;
  padding: 1px;
  position: absolute;
  top: 15px;
  right: 20px;
  color: $color-blueGray500;
}

.btnClose:hover {
  color: $color-charcoalGrey !important;
  background-color: $color-blueGray100;
}
//dark-mode
.dark-mode .btn-close{
    color: $color-white;
  }
.dark-mode .btnClose:hover {
    background-color: $color-blueGray500;
  }
  
.dark-mode .reOpenInv {
  &__title {
    color: $color-white;
  }
}
