@import "../../../../../theme/colors";

.custom-paginate {
  width: 100%;
}

.custom-pagination {
  .MuiPaginationItem-root {
    min-width: 26px;
    height: 26px;
    
    &:hover {
      background-color: $color-blueV;
    }

    &.Mui-selected {
      background-color: $color-ethicsBlue;
      color: $color-white;
    }

    &.Mui-selected:hover {
      background-color: $color-cobalt;
      color: $color-white;
    }
  }

  // .MuiPaginationItem-root.Mui-selected {
  //   font-weight: bold;
  // }
}
