@import "../../../theme/colors";
@import "../../../theme/fontFace";

.mcbtn {
  &.btn {
    min-height: 38px;
    font-size: 13px;
    font-weight: 600;
    border-radius: 4px;
  }

  .btn-icon {
    svg {
      height: 16px;
      width: 16px;
      g {
        transition: all 150ms ease;
      }
    }
  }

  &.btn.btn-primary {
    color: $color-white;
    border: solid 1px $color-ethicsBlue;
    background-color: $color-ethicsBlue;
    svg g path {
      fill: $color-white;
    }
    &:hover {
      border: solid 1px darken($color: $color-ethicsBlue, $amount: 10);
      background-color: darken($color: $color-ethicsBlue, $amount: 10);
    }
    &.outline {
      color: $color-ethicsBlue;
      border: solid 1px $color-ethicsBlue;
      background-color: transparent;
      svg g path {
        fill: $color-ethicsBlue;
      }
      &:hover {
        color: $color-white;
        border: solid 1px darken($color: $color-ethicsBlue, $amount: 10);
        background-color: darken($color: $color-ethicsBlue, $amount: 10);
        svg g path {
          fill: $color-white;
        }
      }
    }
  }

  &.btn.btn-success {
    color: $color-white;
    border: solid 1px $color-green;
    background-color: $color-green;
    svg g path {
      fill: $color-white;
    }
    &:hover {
      color: $color-white;
      border: solid 1px darken($color: $color-green, $amount: 10);
      background-color: darken($color: $color-green, $amount: 10);
    }
    &.outline {
      color: $color-green;
      border: solid 1px $color-green;
      background-color: transparent;
      svg g path {
        fill: $color-green;
      }
      &:hover {
        color: $color-white;
        border: solid 1px $color-green;
        background-color: $color-green;
        svg g path {
          fill: $color-white;
        }
      }
    }
  }

  &.btn.btn-warning {
    color: $color-white;
    border: solid 1px $color-yellow;
    background-color: $color-yellow;
    svg g path {
      fill: $color-white;
    }
    &:hover {
      color: $color-white;
      border: solid 1px darken($color: $color-yellow, $amount: 10);
      background-color: darken($color: $color-yellow, $amount: 10);
    }
    &.outline {
      color: $color-yellow;
      border: solid 1px $color-yellow;
      background-color: transparent;
      svg g path {
        fill: $color-yellow;
      }
      &:hover {
        color: $color-white;
        border: solid 1px $color-yellow;
        background-color: $color-yellow;
        svg g path {
          fill: $color-white;
        }
      }
    }
  }

  &.btn.btn-danger {
    color: $color-white;
    border: solid 1px $color-red;
    background-color: $color-red;
    svg g path {
      fill: $color-white;
    }
    &:hover {
      color: $color-white;
      border: solid 1px darken($color: $color-red, $amount: 10);
      background-color: darken($color: $color-red, $amount: 10);
    }
    &.outline {
      color: $color-red;
      border: solid 1px $color-red;
      background-color: transparent;
      svg g path {
        fill: $color-red;
      }
      &:hover {
        color: $color-white;
        border: solid 1px $color-red;
        background-color: $color-red;
        svg g path {
          fill: $color-white;
        }
      }
    }
  }

  &.btn.btn-secondary {
    svg g path {
      fill: $color-white;
    }
    &.outline {
      background-color: $color-white;
      border: 1px $color-gunmetal solid;
      color: $color-gunmetal;
      svg g path {
        fill: $color-gunmetal;
      }
      &:hover {
        background-color: $color-blueGray700;
        border: $color-blueGray700;
        color: $color-white;
        svg g path {
          fill: $color-white;
        }
      }
    }
  }

  &.btn.btn-sm {
    max-height: 30px;
    height: 30px;
  }
  &.btn.btn-lg {
    max-height: 45px;
    height: 45px;
  }
}

// .mcbtn.dark {
//   &.btn.btn-primary {
//     color: $color-white;
//     border: solid 1px $color-ethicsBlue;
//     background-color: $color-ethicsBlue;
//     &:hover {
//       border: solid 1px $color-oxfordBlue;
//       background-color: $color-oxfordBlue;
//     }
//     &.outline {
//       color: $color-ethicsBlue;
//       border: solid 1px $color-ethicsBlue;
//       background-color: transparent;
//       &:hover {
//         color: $color-white;
//         border: solid 1px $color-ethicsBlue;
//         background-color: $color-ethicsBlue;
//       }
//     }
//   }
// }
