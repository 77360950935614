@import "../../theme/colors";
@import "../../theme/fontFace";

// APLICACION GLOBAL //
* {
  font-family: "Segoe UI" !important;
}

body.overflow-hidden {
  overflow: scroll !important;
}



.notfound {
  display: block;
  text-align: center;
}

.notfound img {
  max-width: 800px;
  width: 70%;
}

.contents {
  width: calc(100% - 210px);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  flex: 1 1 auto;
}

// NAVBAR //
.mobile-search {
  width: 30px;
  margin-right: 0.8rem;
  cursor: pointer;
  display: none;
}

.tenant_navbar {
  padding: 0 30px 0 60px;
  width: 100%;
  color: $color-gunmetal;
  font-size: 1.5rem;
  font-weight: bold;

  #blue-logo {
    color: $color-ethicsBlue;
  }

  .navbar {
    .nav-item {
      display: flex;
      align-items: center;
      padding: 0;

      #login {
        padding: 5px;
        border-radius: 4px;
        border: solid 1px #bdcad0 !important;
        background-image: linear-gradient(
          to bottom,
          var(--white),
          #f0f2f4 120%
        );
        display: flex;
        margin-left: 0.5rem;

        svg {
          width: 1.5rem !important;
          height: 1.5rem !important;
        }
      }

      .control-theme {
        display: flex;
        margin-left: 30px;

        div,
        a {
          // margin-left: 1em;
          // margin-right:1em;
          text-align: center;
        }

        div {
          margin-right: 1em;
        }

        svg {
          width: 38px !important;
          height: 38px !important;
          padding: 7px;
          border-radius: 3px;
          cursor: pointer;
          margin-right: 0.5rem;

          // filter: invert(17%) sepia(7%) saturate(1542%) hue-rotate(169deg) brightness(91%) contrast(93%);

          &:hover {
            background-color: $color-ethicsBlue;
            g {
              fill: $color-white;
            }
          }

          // &#sun {
          //     g {
          //         fill: $color-white;
          //     }
          // }

          // &#dark-setting {
          //     g {
          //         fill: $color-white;
          //     }
          // }

          // &:last-child {
          //     margin-right: 0;
          // }
        }
      }
    }
    .profileItem {
      color: $color-blueGray700;
      margin-right: 2px;
    }
  }
}

@media screen and (max-width: 768px) {
  .tenant_navbar {
    padding: 0;
    .navbar {
      padding: 8px 30px 9px 30px;
      .nav-item .control-theme .desktop-setting {
        display: none !important;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .tenant_navbar {
    padding: 0;
    .navbar {
      padding: 8px 16px 9px 16px;
      .nav-item #login {
        margin-left: 0;
      }
    }
  }
}

.dashboard_nav {
  flex: 0 1 auto;

  &.dark-mode {
    #profile-dropdown .dropdown-menu {
      background: $color-darkThree !important;

      .dropdown-item {
        &:hover {
          background-color: $color-dark !important;
        }
      }

      .profile-header {
        background-color: $color-dark;
      }
    }

    .modal-content {
      background-color: $color-darkThree;
      color: $color-greyBlue;

      .card {
        background-color: $color-darkThree;

        .recent-search {
          background-color: $color-dark !important;
          color: $color-greyBlue;

          .search-title {
            color: $color-greyBlue !important;
          }
        }
      }
    }
  }

  .navbar-icon {
    width: 38px !important;
    height: 38px !important;
    padding: 7px;
    background-color: transparent;
    border-radius: 3px;
    cursor: pointer;

    &:hover {
      background-color: $color-ethicsBlue;

      g {
        fill: $color-white;
      }
    }
  }

  #profile-dropdown {
    position: relative;

    &.menu-dropdown {
      position: static;

      .dropdown-menu {
        left: 0 !important;
        width: 100%;
        border: none;

        .dropdown-item {
          font-size: 1rem;
          color: $color-greyBlue !important;
          padding: 10px 5px;

          &:hover {
            background-color: $color-paleBlue;
          }

          &:first-child {
            padding: 14px 0 30px;
            border-bottom: 1px solid $color-paleGrey2;
            border-radius: 0;

            .profile-svg {
              width: 44px !important;
              height: 44px !important;

              g {
                fill: transparent;
              }
            }
          }

          &:nth-child(2) {
            margin-top: 30px;
          }

          svg g {
            fill: $color-greyBlue;
          }
        }

        .powered_by {
          width: 100%;
          color: $color-ethicsBlue !important;
          background-color: $color-paleBlue;
          min-height: 45px;
          text-align: center;
          font-size: 16px !important;
          border: none !important;
          box-shadow: none;
          margin-top: 15px;
        }
      }

      .hamburger {
        margin-top: -6px;
        display: none;
      }
    }

    button {
      padding: 0;
      background: transparent;
    }

    .dropdown-menu {
      font-size: 14px;
      color: $color-greyBlue !important;
      left: -2.5rem !important;
      width: 20rem;

      .dropdown-item {
        border-radius: 4px;
        padding: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:hover {
          background-color: $color-landing;
        }

        svg {
          width: 1.5rem !important;
          height: 1.5rem !important;

          g {
            fill: $color-greyBlue;
          }
        }

        i {
          color: $color-greyBlue;
        }
      }

      a {
        color: $color-greyBlue !important;
      }

      .profile-header {
        background-color: $color-paleBlue;
        border-radius: 4px;
        padding: 10px;
        font-size: 12px;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        gap: 1vh;
        svg {
          width: 2rem !important;
          height: 1.2rem !important;

          path {
            fill: $color-blue;
          }
        }
      }
    }
  }
}

.tablet-logo {
  display: none;
}

@media screen and (max-width: 768px) {
  .header.dashboard_nav {
    .navbar {
      .navbar-nav {
        .athics-btn {
          width: 100%;
        }
      }
    }

    .tablet-logo {
      display: block;
    }
  }

  .colorSideBar {
    display: none;
  }

  .contents,
  .toggled.colorSideBar + .contents {
    width: 100%;
  }

  .desktop-search {
    position: relative;
  }

  // .mobile-search {
  //   display: inline-block;
  // }

  .dashboard_nav #profile-dropdown.menu-dropdown .hamburger {
    display: inline-block;
  }

  .dashboard_nav #profile-dropdown .dropdown-menu .settings {
    margin: 0% 40% 0% 825%;
  }

  .dashboard_nav #profile-dropdown .dropdown-menu .dashboard {
    margin: 0% 80% 0% 0%;
  }

  .dashboard_nav #profile-dropdown .dropdown-menu .reportes {
    margin: 0% 82.5% 0% 0%;
  }

  .dashboard_nav #profile-dropdown .dropdown-menu .estadisticas {
    margin: 0% 79.5% 0% 0%;
  }

  .dashboard_nav #profile-dropdown .dropdown-menu .notificaciones {
    margin: 0% 76.3% 0% 0%;
  }

  .dashboard_nav #profile-dropdown .dropdown-menu .buzon {
    margin: 0% 86% 0% 0%;
  }

  .dashboard_nav #profile-dropdown .dropdown-menu .ayuda {
    margin: 0% 85.3% 0% 0%;
  }
}


@media screen and (max-width: 390px) {
  .header.dashboard_nav {
    .navbar {
      .navbar-nav {
        .athics-btn {
          width: 100%;
        }
      }
    }

    .tablet-logo {
      display: block;
    }
  }

  .colorSideBar {
    display: none;
  }

  .contents,
  .toggled.colorSideBar + .contents {
    width: 100%;
  }

  .desktop-search {
    display: none;
  }

  .mobile-search {
    display: inline-block;
  }

  .dashboard_nav #profile-dropdown.menu-dropdown .hamburger {
    display: inline-block;
  }

  .dashboard_nav #profile-dropdown .dropdown-menu .settings {
    margin: 0% 40% 0% 825%;
  }

  .dashboard_nav #profile-dropdown .dropdown-menu .dashboard {
    margin: 0% 80% 0% 0%;
  }

  .dashboard_nav #profile-dropdown .dropdown-menu .reportes {
    margin: 0% 82.5% 0% 0%;
  }

  .dashboard_nav #profile-dropdown .dropdown-menu .estadisticas {
    margin: 0% 79.5% 0% 0%;
  }

  .dashboard_nav #profile-dropdown .dropdown-menu .notificaciones {
    margin: 0% 76.3% 0% 0%;
  }

  .dashboard_nav #profile-dropdown .dropdown-menu .buzon {
    margin: 0% 86% 0% 0%;
  }

  .dashboard_nav #profile-dropdown .dropdown-menu .ayuda {
    margin: 0% 85.3% 0% 0%;
  }
}

.nav-tabs .nav-link {
  color: #000;
  border: 2px solid transparent !important;

  &:hover {
    border-bottom: 2px solid $color-ethicsBlue !important;
  }
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border: 2px solid transparent;
  border-bottom: 4px solid $color-ethicsBlue !important;
  color: $color-black;
}

.all-report-tab > .nav-tabs .nav-link.active,
.all-report-tab > .nav-tabs .nav-item.show .nav-link {
  color: $color-ethicsBlue;
}

.navbar {
  border-bottom: 1px solid #dee1e4;
}

.tenant_navbar .navbar {
  border-bottom: none;
}

.tenant_navbar.dashboard_nav .navbar {
  border-bottom: 1px solid #dee1e4;
}

// .btn-form {
//   width: 160px;
//   height: 38px;
//   margin: 3px 20px 3px 30px;
//   padding: 14px 29px;
//   border-radius: 4px;
//   background-color: #009ed7 !important;

//   border: 0px solid #000 !important;
//   font-weight: 500 !important;
//   font-size: 14px !important;

//   &:hover {
//     background-color: #0089c4 !important;
//     box-shadow: 0px 0px 10px 0px rgba(0, 158, 215, 0.889);
//   }
// }

@media screen and (max-width: 576px) {
  .navbar-brand {
    margin-right: 0 !important;
  }

  .navbar-nav {
    padding-left: 0 !important;
  }

  .header.dashboard_nav .tablet-logo {
    display: none;
  }

  .dashboard_nav #profile-dropdown .dropdown-menu a:hover {
    color: #009ed7;
  }

  .dashboard_nav #profile-dropdown .dropdown-menu .icons {
    margin: 0% 0% 3% 65%;
  }

  .dashboard_nav #profile-dropdown .dropdown-menu .settings {
    margin: 0% 30% 0% 315%;
  }

  .dashboard_nav #profile-dropdown .dropdown-menu .dashboard {
    margin: 0% 74% 0% 0%;
  }

  .dashboard_nav #profile-dropdown .dropdown-menu .reportes {
    margin: 0% 77% 0% 0%;
  }

  .dashboard_nav #profile-dropdown .dropdown-menu .estadisticas {
    margin: 0% 73% 0% 0%;
  }

  .dashboard_nav #profile-dropdown .dropdown-menu .notificaciones {
    margin: 0% 68.3% 0% 0%;
  }

  .dashboard_nav #profile-dropdown .dropdown-menu .buzon {
    margin: 0% 81.3% 0% 0%;
  }

  .dashboard_nav #profile-dropdown .dropdown-menu .ayuda {
    margin: 0% 81% 0% 0%;
  }
}

// SIDEBAR //
// SIDEBAR SPACE FOR POSITION FIXED

.sidebar_space {
  background-color: transparent !important;
  width: 210px !important;
  height: 100vh;
  min-width: 210px !important;
  font-size: 14px;
}

// SIDEBAR

.sidebar {
  overflow: scroll;

  -ms-overflow-style: none;
  /* for Internet Explorer, Edge */
  scrollbar-width: none;
  /* for Firefox */
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari, and Opera */
  }

  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 210px !important;
  height: 100vh;
  min-width: 210px !important;
  font-size: 14px;

  a {
    width: 100%;
    text-decoration: none;
  }

  ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px !important;
    padding: 0px !important;
    list-style: none !important;
  }

  // SIDEBAR HEADER

  .sidebar_header {
    padding: 20px;
    display: flex;
  }

  .sidebar_space.collapsed {
    display: none;
  }

  // SIDEBAR HEADER

  .sidebar_menu {
    margin-bottom: 10px;
  }

  // SIDEBAR MENU ITEMS

  .sidebar_menu_item {
    cursor: pointer;
    width: 100%;
    color: $color-slate2;
    svg g {
      fill: $color-blueGray;
    }
  }

  a.active > .sidebar_menu_item,
  .sidebar_menu_item.active {
    color: $color-ethicsBlue;

    .sidebar_menu_item_content {
      div:nth-child(1) {
        background-color: $color-ethicsBlue;
      }

      div:nth-child(2) {
        transition: background-color 250ms ease-in-out;
        background-color: $color-paleBlue;
      }
    }

    .sidebar_menu_item_dropdown {
      ul {
        background-color: $color-paleBlue;
      }
    }

    svg g {
      fill: $color-ethicsBlue;
    }
  }

  .sidebar_menu_item_content {
    padding: 5px 0px;
    display: flex;

    div:nth-child(1) {
      // indicator
      border-radius: 0px 4px 4px 0px;
      width: 5px;
      background-color: transparent;
      margin-right: 10px;
    }

    div:nth-child(2) {
      // title
      padding-top: 5px;
      padding-bottom: 5px;
      width: 172px;
      border-radius: 4px;
      transition: background-color 250ms ease-in-out;

      svg {
        margin: 0px 10px;
      }
    }

    .submenu_arrow_collapse {
      width: 11px;
      transition: transform 500ms ease-in-out;
      filter: invert(98%) sepia(1%) saturate(6575%) hue-rotate(180deg)
        brightness(88%) contrast(96%);
    }

    span {
      font-size: 13px;
      font-weight: 500;
    }
  }

  // SIDEBAR MENU ITEM WITH SUBMENU

  .sidebar_menu_item.submenu {
    &.toggled {
      .sidebar_menu_item_dropdown {
        max-height: 1000px;
        transition: max-height 500ms ease-in;
        font-size: 13px;
      }

      .sidebar_menu_item_content {
        div:nth-child(2) {
          border-radius: 4px 4px 0px 0px;
        }
      }

      .submenu_arrow_collapse {
        transform: rotate(-180deg) !important;
      }
    }

    .sidebar_menu_item_content {
      padding-bottom: 0px;
    }

    
  }

  .sidebar_menu_item_dropdown {
    width: 100%;
    margin-top: -1px;
    margin-bottom: 5px;
    padding: 0px 20px 0px 15px;
    max-height: 0;
    transition: max-height 500ms ease-out;
    overflow: hidden;
    font-size: 13px;

    ul {
      border-radius: 0px 0px 4px 4px;
      width: 172px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      transition: background-color 500ms ease-in-out;

      a {
        color: $color-slate2;
        padding: 5px;
        padding-left: 10px;
      }
      li {
        padding: 5px;
        border-radius: 4px;
        text-wrap: nowrap;
        div {
          height: 100%;
          width: 0px;
          background-color: $color-black;
        }
      }
      a.active li {
        background-color: #fff;
        div {
          width: 20px;
        }
      }
    }
  }

  // SIDEBAR BOTTOM

  .sidebar_bottom {
    display: flex;
    flex-direction: column;
  }

  // SIDEBAR CARD

  .sidebar_card {
    text-align: center;
    font-size: 11px;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px solid $color-ethicsBlue;
    border-radius: 8px;
    margin: 0px 10px;
    background-color: $color-ethicsBlue;
    transition: background-color 500ms ease-in-out;

    .sidebar_card_icon_container {
      height: 60px;
    }

    span {
      color: #fff !important;
    }

    * {
      margin-bottom: 5px;
    }
  }

  // SIDEBAR FOOTER

  .sidebar_footer {
    display: flex;
    flex-direction: column;

    ul {
      margin: 10px 0px !important;
    }

    hr {
      margin: 0px 10px;
    }

    > span {
      margin: 20px 0px;
      align-self: center;
      color: $color-ethicsBlue !important;
      transition: color 500ms ease-in-out;
    }
  }

  // SIDeBAR BASTIAN
  .collapse {
    width: 80% !important;
  }

  // DARK MODE

  &.dark-mode {
    background-color: $color-darkSidebar;

    .sidebar_menu_item {
      color: $color-slate3;

      svg g {
        fill: $color-slate3;
      }
    }

    a.active > .sidebar_menu_item,
    .sidebar_menu_item.active {
      color: $color-white;

      .sidebar_menu_item_content {
        div:nth-child(1) {
          background-color: $color-ethicsBlue;
        }

        div:nth-child(2) {
          background-color: $color-darkThree;
        }
      }

      .sidebar_menu_item_dropdown {
        ul {
          background-color: $color-darkThree;
        }
      }

      svg g {
        fill: $color-ethicsBlue;
      }
    }

    .sidebar_card {
      background-color: $color-darkThree;
    }

    .sidebar_footer {
      > span {
        color: #fff !important;
      }
    }
  }
}

// MEDIA QUERYS

@media screen and (max-width: 768px) {
  .sidebar {
    display: none;
  }

  .sidebar_space {
    display: none;
  }
}

.notifications {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: auto;
  background-color: $color-blueGray100;
  border-radius: 4px;
  &__tab {
    min-height: 40vh;
  }
  &__noNotification {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40vh;
  }
}
