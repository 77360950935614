@import '../../../../theme/colors';

.container-fields {
    border: 1px $color-blueGray200 solid;
    border-radius: 5px;
    margin: 5px;
    padding: 5px;
}

.icon-btn{
    g{
        fill: $color-white;
    }
}