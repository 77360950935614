.buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

// 900 px
@media (max-width: 900px) {
    .buttons {
        flex-direction: column;
    }
}