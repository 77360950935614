@import "../../../theme/colors";


.configuration-notifications {

  .default-notification {
    animation: cambio-color 1s alternate; /* Aplicando la animación */
  }
  
  .list-of-notification-system {
    svg {
      path {
        fill: #0097ce;
      }
    }
  }

  @keyframes cambio-color {
    0% {
      background-color: rgba(255, 238, 0, 0.3);
    }
    100% {
      background-color: transparent;
    }
  }

}

