@import "../../../theme/colors";

.selected-kind-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.new-template-btn {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;

    .error-message {
        color: #d81b60;
        font-weight: 700;
    }
}

.is-invalid {
    background-color: rgba(216,27,96,0.3);
    border: 2px solid #d81b60;
}

.container-detail-fields {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: $color-ethicsBlue 2px dashed;
    padding: 1rem;
    border-radius: 15px;
}

.item-field {
    border: $color-ethicsBlue 1px solid;
    border-radius: 15px;
    padding: 1rem;
    margin-bottom: 10px;
    width: 100%;
}

.label-field {
    font-size: 15px;
    margin-bottom: 5px;
}

.sensitive {
    display: flex;
    align-items: center;
    justify-content: center;
    & .label-field {
        color: #d81b60;
        font-size: 16px;
    }
}

.modal-visible .modal-content {
    overflow: visible;
}

.modal-auto .modal-content {
    overflow: auto;
}