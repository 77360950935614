@import "../../../theme/colors";

.no-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    p {
        font-size: 2.5rem;
        font-weight: 500;
        color: $color-blueGray2;
        margin: 0;
    }
}

svg.icon-no-content {
    width: 10rem;
    height: 10rem;

    g {
        fill: $color-blueGray2;
    }
}