@import '../../theme/colors';
@import '../../theme/fontFace';

.mfa-instructions {
    
    background-color: #f0f0f0;
    border: 1px solid #e9e9e9;
    border-radius: 5px;

    text-align: center;
    font-size: 16px;
    
    margin: 1rem 0;

    p {
        font-size: 16px;
        padding: 1rem;
        display: block;
        margin-bottom: 0px;

        strong {
            font-weight: 500;
        }

        img {
            width: 60%;
            display: block;
            margin: 10px auto;

            border: 1px solid #e9e9e9;
            border-radius: 5px;
        }

        input[type=text] {
            width: 60%;
            margin: 10px auto;
            text-align: center;
        }

        .centered-input {
            text-align: center;
            transition: border 0.3s ease;
          }
          
          .input-invalid {
            border: 2px solid red;
            animation: blink 0.5s ease-in-out;
          }
          
          @keyframes blink {
            0%, 100% { border-color: red; }
            50% { border-color: transparent; }
          }
          

    }
}

.edit-profile-reset-password {
    animation: flashAnimation 800ms ease-in-out;
}

.rectangle {
    width: 78vw;
    height: 12vh;
    padding: 38px 40px 38px 30px;
    margin: 1% 0% 0% 3%;
    border-radius: 6px;
    border: solid 1px #e3ebf5;
    background-color: var(--white);
}

.idiomaSelect {
    width: 208px;
    height: 43px;
    margin: 3px 0 3px 384px;
    padding: 11px 20px 11px 12px;
    border-radius: 4px;
    border: solid 1px #bdcad0!important;
    background-color: var(--white);
}

.profileTabs {
    width: 660px;
    height: 50px;
    margin: 1% 0% 0% 2.3%;
    padding: 0px 40px 0 27px;
    border-radius: 4px;
    border: solid 1px #e3ebf5;
    background-color: var(--white);
}

.trialContainer {
    width: 420px;
    height: 90px;
    padding: 0 20px 0 0;
    border-radius: 4px;
    border: solid 1px #e3ebf5;
    background-color: var(--white);
}

.btnBlankBG {
    min-width: 150px;
    height: 38px;
    padding: 7px 32px 14px 33px;
    background-color: white;
    border-radius: 4px;
}
.btnTrialColor {
    border: solid 1px $color-purple!important;
    color: $color-purple!important;
}

.btnTrialColor:hover {
    background-color: $color-purple!important;
    color: white!important;
}

.Plan-actual {
    width: 180px;
    height: 10px;
    margin: 28px 160px 14px 22px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.83;
    letter-spacing: normal;
    color: var(--blue-grey-900);
    }
.Free-Trial---30-das {
    width: 180px;
    margin: 14px 0px 28px 22px;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: var(--blue-grey-900);
}

.profileInfoContainer {
    width: 660px;
    height: 367px;
    margin: 1% 0% 0% 5%;
    padding: 30px;
    border-radius: 4px;
    border: solid 1px #e3ebf5;
    background-color: var(--white);
}
.completeProfileContainer {
    width: 420px;
    height: 320px;
    margin: 1% 0% 0% 0%;
    padding: 30px 20px 28px;
    border-radius: 4px;
    border: solid 1px #e3ebf5;
    background-color: var(--white);
}
.passwordContainer {
    width: 660px;
    height: 264px;
    margin: 1% 0% 0% 5%;
    padding: 30px;
    border-radius: 4px;
    border: solid 1px #e3ebf5;
    background-color: var(--white);
}
.permisosContainer {
    width: 660px;
    height: 50px;
    margin: 1% 0% 0% 5%;
    padding: 16px 40px 0 27px;
    border-radius: 4px;
    border: solid 1px #e3ebf5;
    background-color: var(--white);
}
.Selecciona-un-avatar {
    height: 12px;
    margin: 0px 34px 14px 20px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: var(--blue-grey-900);
}

.btnUpload {
    width: 167px;
    height: 30px;
    min-height: 30px;
    margin: 0px 27px 27px 20px;
    padding: 0px 22px 3px 20px!important;
    border-radius: 4px;
    border: solid 1px #bdcad0!important;
    color: inherit;
}
.Sube-tu-foto {
    width: 66px;
    height: 12px;
    margin: 0px 0px 2px 0px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: var(--blue-grey-900);
}
.Escoge-tu-avatar {
    width: 66px;
    height: 12px;
    margin: 0px 0px 2px 0px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: var(--blue-grey-900);
}

.btnCambiarContra {
    width: 360px;
    height: 40px;
    margin: 17px 150px 0 0;
    padding: 10px 19px 10px 20px;
    border-radius: 4px;
    border: solid 1px $color-blueGray200;
    background-color: $color-blueGray200;
}

.btnPermisos {
    width: 360px;
    height: 40px;
    margin: 17px 29px 0 0;
    padding: 10px 46px 10px 47px;
    border-radius: 4px;
    border: solid 1px $color-ethicsBlue;
    background-color: white;
    color: $color-ethicsBlue;
}

.separator {
    color: #bdcad0;
}

.textDescription {
    width: 600px;
    height: 64px;
    margin: 0px 0 0px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #546e7a;
}

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 22px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: '';
    height: 18px;
    width: 18px;
    left: 3px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: #43ca66;
}

input:focus + .slider {
    box-shadow: 0 0 1px #43ca66;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.btnReemplazar {
    width: 120px;
    height: 38px;
    background-color: white;
    border-radius: 4px;
    border: solid 1px #bdcad0!important;
    color: #bdcad0;
    text-align: center;
}

.btnReemplazar:hover {
    background-color: $color-blue;
    color: white;
}

.btnMetodosPago {
    width: 100%;
    height: 30px;
    padding: 0px 22px 0 20px;
    text-align: center;
    border-radius: 4px;
    border: solid 1px #bdcad0!important;
    color: inherit;
}

#material-apoyo {
    .carousel-control-prev {
        top: -2.7rem;
        left: unset;
        bottom: unset;
        right: 2rem;
        cursor: pointer;
    }
    .carousel-control-next {
        top: -2.7rem;
        bottom: unset;
        cursor: pointer;
    }
    .carousel-control-next-icon {
        background-image: url('../../assets/icons/vuesax-outline-arrow-right_4.svg');
        width: 24px!important;
        height: 24px!important;
    }
    .carousel-control-prev-icon {
        background-image: url('../../assets/icons/vuesax-outline-arrow-left_3.svg');
        width: 24px!important;
        height: 24px!important;
    }
}

#collapseOne3 {
    background-color: $color-blueGray100;
}


.profile-nav{
    // width: 56%;
    // margin-right: 4%;
    .auth-nav{
        width: 56%;
        justify-content: space-around;
        margin-top: 30px;
        .nav-item{
            margin: 0;
            padding: 0;
            max-width: 110px;
            .nav-link{
                font-size: 14px!important;
                font-weight: bold;
                padding: 14px 16px;
            }
        }
    }
    .dashboard-carousel, .accordion-item{
        background-color: transparent!important;
    }
}

.profile-first-block{
    width: 56%;
}

.profile-second-block{
    width: 40%;
    margin-left: 4%;
    // margin-top: -80px;
    &.user-second-bar{
        height: fit-content;
        margin-top: 0;
    }
    .small-danger-btn, .small-warning-btn, .small-success-btn{
        min-height: 24px;
        width: 70px;
        font-size: 10px!important;
        padding: 0!important;
    }
    .new-ticket{
        min-height: 30px;
        padding: 4px 20px!important;
    }
}

.background .profile-first-block svg{
    width: unset!important;
}

.profile-accordions {
    font-size: 14px!important;
}

.profile-table{
    font-size: 12px;
    tr{
        border: none;
        &:first-child{
            //background-color: #f3f7fa;
            font-weight: 600;
        }
        &:nth-child(3){
            border-bottom: 1px solid #dee1e4;
        }
    }
    td{
        border: none;
        padding: 12px 20px;
    }
}

.bold-message{
    path{
        fill: #3181ff;
    }
}

.check-icons{
    h6{
        color: $color-silver;
    }
    svg{
        g{
            fill: $color-silver;
        }
    }
    &.active{
        h6{
            color: $color-blueGray900;
        }
        svg{
            g{
                fill: $color-green;
            }
        }
    }
}
@media screen and (max-width: 992px) {
    .profile-second-block{
        width: 100%;
        margin-left: 0;
        margin-top: 20px;
    }
    .profile-first-block{
        width: 100%;
        margin-right: 0;
    }
    .profile-nav .auth-nav{
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .btnMetodosPago {
        margin: 0 0 20px 0;
    }
    #material-apoyo{
        padding: 0;
    }
}

@media screen and (max-width: 576px) {
    .profile-nav{
        .auth-nav{
            overflow-x: auto;
            overflow-y: hidden;
            flex-wrap: nowrap;
            justify-content: space-between;
        }
    }
    .edit-profile-img{
        width: 50px!important;
        height: 50px!important;
    }
    .Selecciona-un-avatar{
        margin: 0px 0 0 10px;
    }
    .btnUpload{
        width: 95%;
        margin: 0 0 20px 10px;
    }
    .btnReemplazar{
        width: 100%;
        margin-left: 0!important;
        margin-top: 20px;
    }
    .social-img{
        width: 48%;
    }
    .profile-second-block .new-ticket{
        margin-top: 20px;
    }
}