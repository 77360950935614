@import "../../../theme/colors";

// === Settings
.file-upload {
  margin: 0;
  border: 1px dashed $color-slate2;
  .dropzone {
    padding: 1rem;
    text-align: center;
    p {
      margin: 0;
    }
    #preview {
      width: auto;
      margin-top: 2rem;
      max-width: 500px;
    }
  }
}
.file-upload.is-invalid {
  border: 1px dashed $color-red;
}

// ------> FileUpload.jsx <------ //
.fileUpload {
  &__attachments {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column nowrap;
    height: 16vh;
    transition: all 200ms;
    border-radius: 2px;
    text-align: center;
    border: 2px dashed $color-silver;
    &.drag-over {
      border: dashed 2px $color-ethicsBlue;
      color: $color-blue;
      animation: buzz 4s infinite;
      button {
        color: $color-green;
      }
    }
  }
  &__attachments-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row wrap;
    min-height: 35px;
    svg {
      height: 35px;
      width: 35px;
      margin: 0px 4px;
      filter: brightness(0%) contrast(100%) invert(57%) sepia(3%)
        saturate(2732%) hue-rotate(155deg) brightness(98%) contrast(80%);
    }
  }
  &__attachments-instructions {
    p:nth-child(1) {
      font-size: 24px;
      margin-bottom: 0px;
    }
    p:nth-child(2) {
      font-size: 18px;
    }
  }
  &__preview-container {
    display: flex;
    align-items: center;
    height: 16vh;
    flex-flow: column nowrap;
    border: solid 1px $color-silver;
    overflow-y: auto;
    ul {
      list-style: none;
      width: 95%;
      li {
        margin: 5px 0;
      }
    }
  }
  &__loaded-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    max-height: 16vh;
    overflow-y: auto;
  }
  &__file {
    border: $color-blueGray500 solid 1px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px;
    color: $color-ethicsBlue;
    background-color: $color-lightBackground;
    margin: 3px;
    box-shadow: 0 0 1px 1px $color-silver2;
    button {
      background-color: $color-red;
      border-radius: 50%;
      padding: 0;
      scale: 0.75;
      border: none;
      margin: auto 0;
      svg {
        scale: 1.6;
        margin: 1px;
        path {
          fill: $color-lightBackground;
        }
      }
    }
    button:hover {
      background-color: $color-lightBackground;
      svg {
        path {
          fill: $color-red;
        }
      }
    }
  }
  &__name {
    margin: auto 2px !important;
    word-break: break-all;
  }
}

@keyframes buzz {
  1% {
    -webkit-transform: rotateZ(0deg) scale(1);
  }
  25% {
    -webkit-transform: rotateZ(-1deg) scale(0.97);
  }
  50% {
    -webkit-transform: rotateZ(0deg) scale(1);
  }
  75% {
    -webkit-transform: rotateZ(1deg) scale(1.03);
  }
  100% {
    -webkit-transform: rotateZ(0deg) scale(1);
  }
}

.--paperClip {
  scale: 0.7;
  margin: auto 0;
  path {
    fill: $color-blueGray;
  }
}

.dark-mode .attachments-btn {
  color: $color-blueGray;
  &:hover {
    color: $color-white;
  }
}
