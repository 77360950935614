@import "../../../theme/colors";
// Styles by MCSelectSome

.selectSome {
  .title {
    display: flex;
    flex-flow: row wrap;
    margin: 0.4em auto;
    align-items: center;
    justify-content: space-between;
    &__label {
      font-weight: normal;
      color: $color-Gray;
    }
  }
  .chipBox {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: start;
    margin-top: 0.3vh;
    &__chip {
      border: $color-lightBlue2 1px solid;
      background-color: $color-paleBlue;
      color: $color-slate;
      padding: 3px 5px;
      border-radius: 4px;
      font-size: 0.73rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 2px 5px;
      min-width: 24%;
      min-height: 26px;
    }
    &__close {
      svg {
        width: 1rem;
        height: 1rem;
        path {
          fill: $color-blue2;
        }
      }
    }
  }
}
