@import "../../../theme/colors";
#greetings{
    padding: 2rem 0.3rem;
    border-radius: 6px;
    #noti-block{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        #notification{
            display: flex;
            align-items: center;
            position: relative;
            border-radius: 4px;
            border: 1px solid $color-paleGrey2;
            border-left: 4px solid $color-yellow;
            padding: 0.6rem 1rem;
            margin-right: 1rem;
            h6{
                margin: 0;
            }
            svg{
                width: 1rem!important;
                height: 1rem!important;
                margin-right: 0.5rem;
                g{
                    fill: $color-yellow;
                }
            }
            #close{
                position: absolute;
                top: 0.5rem;
                right: 0;
                cursor: pointer;
                g{
                    fill: $color-blueGray;
                }
            }
        }
        .report-btn{
            font-size: 11px!important;
        }
    }
}

@media (max-width: 1024px) {
    #greetings{
        .Nulla{
            width: 115%;
        }
        #noti-block{
            #notification{
                display: none;
            }
        }
    }   
}

@media screen and (max-width: 768px) {
    #greetings{
        #noti-block{
            #notification{
                margin-right: 0;
                display: none;
            }
            .report-btn{
                width: 100%;
                min-height: 45px;
            }
        }        
    }
}

@media screen and (max-width: 576px) {
    #greetings{
        padding: 2rem 0;
        margin: 0!important;
        border-radius: 0;
        #noti-block{
            .report-btn{
                max-width: unset;
            }
            #notification{
                display: none;
            }
        }
    }
}