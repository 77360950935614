
.single-row-text-loading {
  width: 100%;
  height: 10px;
  display: block;
  margin: auto;
  position: relative;
  background: rgb(226, 226, 226);
  box-sizing: border-box;
}
.single-row-text-loading::after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: 
    linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.5) 50%, transparent 100%),
    linear-gradient(#dddddd4b 10px, transparent 0);
  background-repeat: no-repeat;
  background-size: 75px 175px, 100% 100px;
  background-position: -185px 0, center 0;
  box-sizing: border-box;
  animation: singleRowLoadingAnim .5s linear infinite;
}

@keyframes singleRowLoadingAnim {
  to {
    background-position: 100% 0, center 0,;
  }
}
