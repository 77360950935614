@import "../../../../../theme/colors";

.porcess-closed {
  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    height: 3rem;
  }
  &__reportType {
    gap: 0 1.5vw;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-flow: row wrap;
  }
}
