@import '../../theme/colors';


.catalogues-page {

    .tooltip-icon {
        color: red;
        position: relative;

        list-style: none;

        span {
            margin-top: -10px;
        }

        
    }

    .form-control {
        margin-top: 15px;
    }
    .WrapButtons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }
    .btnCreatedCat {
        width: 180px;
        height: 38px;
        border-radius: 4px;
        padding: 14px 35px -2px 37px;
        border: 1px solid #009ed7;
        color: #009ED7;
        background-color: transparent;
        font-family: SegoeUI;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
    }
    .btnCancel {
        width: 100px;
        height: 30px;;
        border-radius: 4px;
        padding: 14px 35px -2px 37px;
        border: 1px solid #009ed7;
        color: #009ED7;
        background-color: transparent;
        font-family: SegoeUI;
        font-size: 14px;
    }
    
    .btnCancel:hover {
        background-color: #009ED7 !important;
        color: white;
    }
    
    .btnSave {
        width: 100px;
        height: 30px;;
        border-radius: 4px;
        padding: 14px 35px -2px 37px;
        border: 1px solid #009ed7;
        color: white;
        background-color: #009ED7!important;
        font-family: SegoeUI;
        font-size: 14px;
    }
    
    .btnSave:hover {
        background-color:transparent !important;
        color: #009ED7;
    }
    .btnEdit {
        width: 80px;
        height: 20px;;
        border-radius: 4px;
        padding: 14px 35px -2px 37px;
        border: 1px solid #009ed7;
        color: #009ED7;
        background-color: transparent;
        font-family: SegoeUI;
        font-size: 11px;
    }
    .btnEdit:hover {
        background-color: #009ED7 !important;
        color: white;
    }
    
    .btnPrview {
        width: 80px;
        height: 20px;
        border-radius: 4px;
        padding: 14px 35px -2px 37px;
        border: 1px solid #009ED7;
        color: white;
        background-color: #009ED7 !important;
        font-family: SegoeUi;
        font-size: 11px;
    }
    .btnPrview:hover {
    background-color: white !important;
    color: #009ED7;
    
    }
    .footer {
        display: flex;
        justify-content: space-between;
        border: 1px solid#d8dfe3;
        border-bottom: 0px;
        border-left: 0px;
        border-right: 0px;
        height: 100px;
        padding: 31px;
        color: #78909c;
        align-items: center;
        text-align: center;
        border-radius: 4px;
        background-color: transparent;
        font-family: Roboto;
        font-size:14px;
    }
    .btnEditCat {
        width: auto;
        height: 50px;;
        border-radius: 4px;
        padding: 10px !important;
        border: 1px solid #009ed7;
        color: #009ED7;
        background-color: transparent;
        font-family: SegoeUI;
        font-size: 13px;
    }
    .btnConfig {
        width: auto;
        height: 50px;
        border-radius: 4px;
        padding: 10px !important;
        border: 1px solid #009ED7;
        color: white;
        background-color: #009ED7!important;
        font-family: SegoeUi;
        font-size: 13px;
    }
    // .wrapperAll {
    //     height: 100%;
    //     padding: 30px;
    // }
    .header-prev-catalogue {
        background-color: #009ED7;
        color: white;
        height: 63px;
        text-align: center;
        padding: 10px;
        font-size:25px;
        border-radius: 4px;
    }
    .saved {
        background-color:transparent;
        margin-top: 20px;
        color: black;
        border-radius: 4px;
        border: 1px solid #009ED7;
        color: #009ED7;
        padding: 0.5rem 0.725rem;
        width: 100px;
        min-height: 40px;
        font-size: 13px;
        font-weight: 400;
        line-height: 1.5;
        transition: all 150ms ease-in-out;
        &:hover {
            background-color:#009ED7 !important;
            color:#fff;
        }
    }
    .edit {
        background-color:transparent;
        border-radius: 4px;
        color:black; 
        border: 1px solid #009ED7;
        padding: 0.5rem 0.725rem;
        width: 100px;
        min-height: 40px;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.5;
    }
    .edit:hover {
        background-color:#009ED7;
        color:#fff;
    }
    .wrapperEd {
        display: flex;
        justify-content: flex-end;
        
    }
    
    // #label {
    //     display: flex;
    //     flex-direction: row;
    //     justify-content: flex-start;
    //     align-items: center;
    //     width: auto;
    // }
    // .editLabel {
    //     background-color:transparent;
    //     color:#fff;
    //     border-radius: 4px;
    //     border: 1px solid #009ED7;
    //     border-top: none;
    //     border-right: none;
    //     border-left: none;
    //     padding-left: 10px;
    //     width: auto;
    //     min-height: 20px;
    //     font-size: auto;
    //     font-weight: auto;
    //     line-height: 1.5;
    // }
}



// MAIN PAGE CATALOGUES \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
// pagina de catalogos .../catalogues
// .catalogue-page {
//     display: grid;
//     grid-template-columns: 1fr 4fr;
//     height: calc(100% - 95px);
//     max-height: calc(100% - 95px);
//     column-gap: 50px;
// }


.aside-catalogue-page {
    display: flex;
    flex-direction: column;
    .nav-items {
        padding: 16px 16px;
    }
    .nav-link {
        font-size: small;
        font-weight: 600;
        padding: 0;
        padding-left: 4px;
        border-left: 2px solid transparent;
        color: $color-black;
    }
    .nav-link.active {
        border-left: 2px solid $color-ethicsBlue;
    }
}
.main-catalogue-page {
    height: 100%;
    .tab-content {
        height: 100%;
        .tab-pane.active {
            height: 100%;
        }
    }
}


// CREATE CATALOGUE \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
// la parte de crear catalogos
.single-catalogue-page {
    display: flex;
    flex-direction: column;
    // padding: 24px;
    // cambiar borde del input bootstrap
    .form-control {
        border-radius: 4px;
    }
    // contenedor del nombre del catalogo
    .catalogue-name-container {
        display:                flex;
        justify-content:        space-between;
        border:                 1px solid #D8DFE3;
        margin-top:             24px;
        padding:                24px;
    }
    // container de todos los children
    .children-container {
        padding:                16px;
        border:                 1px solid #D8DFE3;
    }
    // desplegar los catalogos con la flechita
    .catalogue-children {
        max-height:             0;
        transition: max-height 0.15s ease-out;
        overflow:               hidden;
    }
    .catalogue-children.show {
        max-height:             2000px;
        overflow: auto;
        transition:             max-height 0.25s ease-in;
    }
    // flechita de los catalogos
    .arrow-colapse {
        width:                 16px;
        filter: brightness(0) saturate(100%) invert(57%) sepia(62%) saturate(5088%) hue-rotate(165deg) brightness(93%) contrast(105%);
    }
    .arrow-colapse.collapsed {
        transform:          rotate(-90deg);
    }
    // icons de la derecha en cada child
    .catalogue-icons {
        display:            flex;
        align-items:        center;
        opacity:            0%;
        .catalogue-icon {
            border-right: 1px solid gray;
            padding: 0px 8px;
            svg {
                filter: brightness(0) saturate(100%) invert(57%) sepia(62%) saturate(5088%) hue-rotate(165deg) brightness(93%) contrast(105%);
                cursor: pointer;
            }
            &:last-child {
                border-right: none;
            }
        }
    }
    // linea de rayas a la izquierda de cada hijo despues del primero
    .doted-rline {
        border-left:        2px dashed #D8DFE3;
    }
    // mostrar las opciones en cada child cuando se le haga hover
    .child:hover .catalogue-icons {
        opacity:            100%;
        transition:         all 250ms;
    }
    // contenedor de cada hijo
    .child {
        display:            flex;
        // border:             1px solid #D8DFE3;
        // overflow:           hidden;
        margin-left:        10px;
        border-radius:      4px;
    }
    // el primer hijo de la cadena
    .child.first {
        margin-left:        0;
        border-radius:      0;
        background-color: #2da4f40c;
        box-shadow:         5px 5px 5px rgba(128, 128, 128, 0.1);
    }
    .color-lbar {
        width:             5px;
        height:            58px;
    }
    .second .color-lbar { background-color: $color-blue }
    .third .color-lbar { background-color: $color-yellow }
    .fourth .color-lbar { background-color: $color-teal  }
    // for edit catalogue
    .catalog-container.edit-catalogue {
        width: 482px;
        padding: 31px 91px 45px;
        border-radius: 4px;
        margin: 23px auto 0px auto;
        box-shadow: 0 20px 20px 0 rgba(50, 65, 72, 0.1);
        // border: solid 1px $color-silver2;
        display: flex;
        justify-content: center;
        .catalog-for-edition {
            min-width: 250px;
            max-width: 250px;
        }
        .catalogue-icon-for-edition {
            margin-left: 10px;
            width: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            filter: brightness(0%) contrast(100%) invert(47%) sepia(0%) saturate(2041%) hue-rotate(315deg) brightness(107%) contrast(90%);
        }
        .preview-input-container-inp {
            margin-bottom: 16px;
        }
    }
}


// MY CATALOGUES \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
.catalogues-page .my-catalogues-page {
    .rdt_Table, .rdt_TableHead, .rdt_TableHeadRow, .rdt_TableBody, .rdt_TableRow, .rdt_Pagination {
        background-color: transparent;
    }
    .rdt_TableCell, .rdt_TableCol {
        // flex-grow: 1 !important;
        &:nth-child(3) {
            min-width: 200px;
        }
    }
}

.dark-mode .catalogues-page .my-catalogues-page {
    .rdt_Table, .rdt_TableHeadRow, .rdt_TableBody, .rdt_TableRow, .rdt_Pagination {
        color: $color-blueGray;
        svg {
            filter: brightness(0%) contrast(100%) invert(100%) sepia(58%) saturate(0%) hue-rotate(312deg) brightness(104%) contrast(101%);;
        }
    }
    .rdt_TableHeadRow, .rdt_TableRow {
        border-bottom-color: $color-darkBorder;
    }
    .rdt_Pagination {
        border-top-color: $color-darkBorder;
    }
}



// PREVIEW CATALOGUE \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
.catalogues-prev-catalogue {
    .catalog-container {
        width: 482px;
        padding: 31px 91px 45px;
        border-radius: 4px;
        margin: 23px auto 0px auto;
        box-shadow: 0 20px 20px 0 rgba(50, 65, 72, 0.1);
        border: solid 1px $color-silver2;
        display: flex;
        justify-content: center;
    }
}

.catalogue-container-single {
    .preview-input-container-inp {
        margin-bottom: 16px;
    }
}


// 

.modal-edit-catalogue {
    .add-icon {
        svg {
            path {
                stroke: $color-ethicsBlue;
                transition: all 250ms;
            }
            &:hover {
                path {
                    stroke: $color-white;
                }
            }
        }
    }
}

.modal-preview-create-catalogue {
    .catalogue-select {
        animation: flashAnimation 800ms ease-in-out;
    }
}