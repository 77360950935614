@import "../../../theme/colors";

.info-container.inputs {
  min-width: unset;
  .input {
    border-radius: 4px;
    resize: none;
    display: flex;
    margin-bottom: 16px;
    &.error {
      border: 1px solid red;
    }
    .input-lbar {
      border-radius: 4px 0 0 4px;
      transition: all 250ms linear;
      min-width: 4px;
      background-color: transparent;
    }
    &.active {
      box-shadow: 0 6px 8px -7px rgba(50, 65, 72, 0.29);
      .input-lbar {
        background-color: #009ed7;
      }
    }
    .input-info {
      flex-grow: 1;
      width: 100%;
    }
    .input-info-title {
      cursor: pointer;
      display: flex;
      margin: 8px;
      padding: 4px 0px;
      border-bottom: 1px solid #bdcad0;
      justify-content: space-between;
      align-items: center;
      h6 {
        margin: 0;
      }
      .input-title {
        height: auto;
        flex-grow: 1;
      }
      .input-move {
        display: none;
        svg {
          height: 16px;
          width: 16px;
        }
      }
    }
    &.active .input-info-title {
      cursor: auto;
      .input-move {
        display: flex;
      }
    }
    .input-info-main {
      max-height: 0;
      transition: all 250ms linear;
      overflow: hidden;
      display: grid;
      // grid-template-columns: 1fr 1fr;
      grid-template-columns: 1fr;
      place-content: center;
      // display: flex;
      // justify-content: space-around;
      padding: 0px;
      border-bottom: none;
      .input-info-main-inputs {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 80%;
        margin: auto;
        display: flex;
        flex-direction: column;
      }
    }
    &.active .input-info-main {
      overflow: visible;
      max-height: 500px;
      padding: 8px 8px 24px 8px;
      border-bottom: 1px solid #bdcad0;
    }
    .input-info-extra {
      max-height: 0;
      overflow: hidden;
      transition: all 250ms linear;
      display: flex;
      justify-content: space-between;
      padding: 0px;
    }
    &.active .input-info-extra {
      max-height: 500px;
      padding: 8px;
      overflow: visible;
    }
    .input-info-extra-left {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      .input-info-extra-item {
        margin-bottom: 8px;
        padding-left: 16px;
        padding-right: 16px;
        border-right: 1px solid #bdcad0;
        .dyIconEg {
          path {
            fill: $color-blueGray2;
          }
          &.active {
            path {
              fill: $color-ethicsBlue;
            }
          }
        }
        &:last-child {
          border-right: none;
          border-bottom: none;
        }
        .form-check-input {
          top: 0px;
          height: 15px;
          width: 15px;
        }
      }
      .select-grid {
        width: 83px;
      }
    }
    .input-info-extra-right {
      display: flex;
      .input-info-extra-item {
        margin-left: 12px;
        padding-right: 12px;
        display: flex;
        align-items: center;
        border-right: 1px solid #bdcad0;
        &:first-child {
          margin-left: 0px;
        }
        &:last-child {
          padding-right: none;
          border-right: none;
        }
        svg {
          filter: brightness(0%) contrast(100%) invert(41%) sepia(19%)
            saturate(481%) hue-rotate(153deg) brightness(93%) contrast(85%);
          transition: all 250ms;
        }
        &:hover svg {
          filter: brightness(0%) contrast(100%) invert(40%) sepia(68%)
            saturate(1227%) hue-rotate(163deg) brightness(99%) contrast(101%);
        }
      }
    }
    .inactive-info {
      max-height: 500px;
      transition: all 250ms linear;
      overflow: hidden;
      display: flex;
      padding: 8px 8px 16px 8px;
      filter: brightness(0%) contrast(100%) invert(41%) sepia(19%)
        saturate(481%) hue-rotate(153deg) brightness(93%) contrast(85%);
      label {
        display: block;
        margin-bottom: 8px !important;
      }
    }
    &.active .inactive-info {
      max-height: 0;
      padding: 0px;
    }
    .inactive-info-properties {
      margin-right: 40px;
      .properties-list {
        display: flex;
      }
      .properties-item {
        display: flex;
        align-items: center;
        font-size: 12px;
        padding: 0px 8px;
        border-right: 1px solid #bdcad0;
        svg {
          width: 16px !important;
          height: 16px !important;
          margin-right: 2px;
        }
      }
      .properties-item:first-child {
        padding: 0px 8px 0px 0px;
      }
      .properties-item:last-child {
        padding: 0px 0px 0px 8px;
        border-right: none;
      }
    }
    .inactive-info-type {
      .type-item {
        display: flex;
        align-items: center;
        svg {
          height: 25px;
          width: 25px;
          margin-right: 8px;
        }
      }
    }
  }
  .error-msg-input-create {
    color: red;
    margin-bottom: 12px;
  }
  .add-input {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    background: rgb(231, 231, 231);
    background: linear-gradient(
      0deg,
      rgba(231, 231, 231, 1) 0%,
      rgba(255, 255, 255, 1) 100%,
      rgba(255, 255, 255, 1) 100%
    );
    border: 1px dashed black;
    border-radius: 4px;
    cursor: pointer;
    svg {
      margin-right: 16px;
    }
  }
}

@media (min-width: 883px) {
  .info-container.inputs {
    min-width: calc(509px - 24px);
    .input {
      .input-info-main {
        grid-template-columns: 1fr 1fr;
      }
      .input-info-extra-left {
        flex-direction: row;
        justify-content: start;
        .input-info-extra-item {
          margin-bottom: 0px;
          border-bottom: none;
          &:first-child {
            margin-left: 0px;
          }
          &:last-child {
            padding-right: none;
            border-right: none;
          }
          .form-check-input {
            top: 0px;
            height: 15px;
            width: 15px;
          }
        }
        .select-grid {
          width: 83px;
        }
      }
    }
  }
}

.dark-mode .info-container.inputs {
  color: $color-blueGray2;
  .input {
    background-color: transparent;
  }
  .active .input-info-main,
  .input-info-title {
    border-bottom: 1px solid $color-darkBorder !important;
  }
  .input-info-main-inputs {
    .input-label {
      border: 1px solid $color-darkBorder;
      &:hover {
        border: 1px solid $color-white;
      }
      &:focus {
        border: 1px solid $color-ethicsBlue;
      }
    }
  }
  .input-info-main {
    border-bottom: none !important;
  }
  .input-info-extra-left {
    .input-info-extra-item {
      border-right-color: $color-darkBorder;
    }
  }
  .add-input {
    background: none;
    border-color: $color-darkBorder;
    background: transparent;
    svg g {
      fill: $color-ethicsBlue;
    }
  }
}

.create-form {
  background-color: #f3f7fa;
  padding: 20px 30px 30px 60px;
  flex-grow: 1;

  .create-form-error-msg {
    background-color: #ffcdd2;
    border: 2px solid #de463d;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 100%;
    margin-bottom: 16px;
    border-radius: 4px;
  }

  .array-error-msgs {
    color: red !important;
    ul {
      list-style: none;
    }
  }

  .create-form-main {
    display: grid;
    grid-template-columns: 1fr;
    height: calc(100% - 95px);
    max-height: calc(100% - 95px);
    column-gap: 30px;
    box-sizing: border-box;
  }

  @media (min-width: 1070px) {
    .create-form-main {
      display: grid;
      grid-template-columns: 5fr 16fr;
      height: calc(100% - 95px);
      max-height: calc(100% - 95px);
      column-gap: 30px;
    }
  }

  .steps-list {
    small {
      color: #546e7ac6;
      font-size: 10px !important;
    }
  }

  .inputs-list {
    padding: 24px;
  }

  .input-title {
    transition: all 250ms linear;
    font-weight: 600;
    padding-left: 8px;
    width: 100%;
    height: 40px;
    border: none;
    border-bottom: 1px solid transparent;
    background-color: transparent;
    margin: 4px 4px 4px 0px;
    &:focus {
      border-bottom: 1px solid #009ed7;
    }
    &.error {
      border: 1px solid red;
    }
  }

  .step-item {
    height: 45px;
    display: flex;
    border-radius: 4px;
    margin-bottom: 8px;
    &.error {
      border: 1px solid red !important;
    }
    .step-item-lbar {
      width: 4px;
      background-color: transparent;
      margin-right: 4px;
    }
    &.active .step-item-lbar {
      background-color: #009ed7;
    }
    .step-item-info {
      width: 100%;
      justify-content: space-between;
      display: flex;
      padding: 2px;
    }
    .step-item-input {
      // min-width: 150px;
      width: 100%;
      height: 30px;
      border: none;
      border-bottom: 1px solid transparent;
      background-color: transparent;
      margin: 4px 4px 4px 0px;
      &:hover {
        cursor: pointer;
      }
    }
    &.active .step-item-input {
      border-bottom: 1px solid #009ed7;
      &:hover {
        cursor: text;
      }
    }
    .step-item-arrows {
      transition: all 250ms;
      opacity: 0%;
      display: flex;
      align-items: center;
      margin-right: 4px;
      div {
        height: 16px;
        width: 16px;
        display: flex;
        svg {
          height: 16px !important;
          width: 16px !important;
        }
      }
    }
    &:hover .step-item-arrows {
      opacity: 100%;
    }
    .step-item-more {
      width: 50px;
    }

    .dropdown-toggle {
      svg g {
        fill: $color-ethicsBlue;
      }
    }
  }

  .step-item.add {
    width: auto;
    margin-top: auto !important;
    padding: 0 !important;
    border: 1px dashed #8aa1ac;
    .step-item-info {
      justify-content: space-between;
    }
    .step-item-input {
      background-color: transparent;
    }
  }

  .info-container {
    display: flex;
    flex-direction: column;
    padding: 16px;
    border: 1px solid #d8dfe3;
    label {
      margin: 0;
    }
  }

  .info-container.title {
    textarea {
      padding: 8px;
      border-radius: 4px;
      border: 1px solid #bdcad0;
      resize: none;
    }
  }

  .info-container.buttons {
    align-items: flex-end;
    .button-list {
      display: flex;
      flex-direction: column;
      width: 100%;
      button {
        width: 100%;
        margin-bottom: 12px;
      }
    }
    @media (min-width: 883px) {
      .button-list {
        flex-direction: row;
        justify-content: end;
        button {
          width: 150px;
          margin-bottom: 0px;
        }
      }
    }
  }

  .btns-bottoms {
    $color-main: #009ed7;
    cursor: pointer;
    align-items: center;
    background-color: transparent;
    border-radius: 4px;
    border: 2px solid $color-main;
    color: $color-main;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    height: 30px;
    justify-content: center;
    line-height: 1.5;
    padding: 0 20px;
    transition: 250ms;
    margin-right: 16px;
    &:hover {
      background-color: $color-main !important;
      color: #fff;
    }
  }
}

.constraints-modal {
  .grid-constraints {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 8px;
    & > div {
      border: 1px solid $color-blueGray500;
      border-radius: 4px;
      padding: 8px;
    }
  }

  .constraints-list-catalogue {
    ul {
      list-style: none;
    }
  }
}

.dark-mode .create-form {
  color: $color-blueGray2;
  .step-item-input,
  .input-title,
  .input-with-ico > input {
    color: $color-blueGray2;
  }
}

.modal-preview-container {
  padding: 32px;
  .modal-preview-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .header-title {
      display: flex;
      margin-bottom: 8px;
    }
    .header-arrow-left {
      margin-right: 16px;
    }
    .header-description {
      color: #546e7a;
      font-size: 14px;
      margin-bottom: 8px;
    }
    .header-route {
      font-size: 12px !important;
    }
  }
  .modal-preview {
    padding: 32px;
    border: 1px solid #bdcad0;
  }
}

.modal-shadow {
  box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.112);
  border-radius: 16px;
}

.modal-select-type {
  padding: 50px;
  .modal-type-header {
    justify-content: space-between !important;
  }
}

.modal-select-type.dark-mode {
  .modal-content {
    background-color: $color-darkThree;
  }
}

.modal-config-conditionals,
.constraints-modal {
  max-width: 1400px;
  min-width: calc(100vw - 50px);
}

// MODAL DE LAS CONSTRAINTS
.constraints-modal {
  .list-of-catalogues {
    header {
      .eg-own-selector {
        padding: 4px 8px 0 0;
        display: flex;
        .shadow-selection {
          button {
            background-color: transparent;
            border: none;
            text-align: center;
            height: 32px;
            padding: 4px 8px;
            border-radius: 4px;
            margin-right: 0.1vw;
          }
          button:hover {
            background-color: $color-blueGray50;
          }
          .active {
            background-color: $color-blueGray100;
            border-bottom: $color-ethicsBlue 4px solid;
          }
          .active:hover {
            background-color: $color-blueGray100;
          }
        }
      }
    }

    .catalogues-list-container {
      .catalogues-list {
        list-style: none;
        padding: 0;
        li {
          cursor: pointer;
          padding: 8px;
        }
        li:hover {
          background-color: $color-blueGray50;
          transition: all 100ms ease;
        }
        li.selected {
          background-color: $color-blueGray200;
          border-radius: 4px;
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .modal-config-conditionals {
    min-width: calc(100vw - 50px);
  }
}
@media (min-width: 1200px) {
  .modal-config-conditionals {
    min-width: calc(100vw - 50px);
  }
}
@media (min-width: 1400px) {
  .modal-config-conditionals {
    min-width: calc(100vw - 100px);
  }
}
