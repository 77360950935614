@import "../../theme/colors";

//Styles from DataTable.jsx
.searcher {
  position: relative;
  scale: 0.8;
  width: 40%;
  &__input {
    padding-left: 40px !important;
    margin-bottom: 2vh;
  }
  &__icon {
    scale: 0.85;
    position: absolute;
    top: 8px;
    left: 10px;
  }
}

.dataTable {
  &__table {
    min-width: 40%;
    border-collapse: separate;
    border-spacing: 0 15px;
    margin: -10px auto 10px auto;
  }
  &__header {
    border-radius: 7px 7px 0 0;
    background-color: $color-blueGray100;
    box-shadow: $color-silver 0 0 5px 0.5px;
    tr {
      position: -webkit-sticky;
      position: sticky;
      th {
        padding: 9px;
        h5 {
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          padding: 5px 3px 5px 6px;
          margin: auto;
        }
      }
    }
  }
  &__body {
    tr {
      box-shadow: $color-silver2 1px 2px 6px 0;
    }
    td {
      padding: 5px 6px 5px 20px;
      div {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  &__body :hover {
    background-color: $color-blueGray50;
  }
}
//pagination from './DataTable.jsx'
.pagination {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-flow: row wrap;
  &__pages{
    display: flex;
    justify-content: space-around;
    text-align: center;
    max-height: 30px;
  }
  &__pages span{
    margin: 0 3px;
  }
  &__info{
    font-size: 0.7rem;
    margin: 1vh 0;
  }
  &__noData{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px auto;
  }
  &__rowsPerPage{
    display: flex;
    align-items: center;
  }
  .btnPage{
    border: none;
    border-radius: 6px;
    height: 30px;
    width: auto;
    scale: 0.7;
    margin: 0 -1px;
  }
  .btnPageAction{
    background-color: transparent;
    border: none;
    padding: 2px 5px;
  }
  .btnPageAction p {
    font-size: 1.1em;
  }
}

.--sortedColumn {
  background-color: $color-blueGray200;
}
.--scale {
  scale: 0.7;
}
.--current {
  background-color: $color-silver;
  color: $color-blueGray900;
}

//dark-mode
.dark-mode .dataTable {
  &__header {
    background-color: $color-darkSidebar;
    box-shadow: $color-darkBorder 0 0 5px 0;
    h5 {
      color: $color-silver2 !important;
    }
  }
  &__body {
    background-color: $color-darkBackground;
    tr {
      box-shadow: $color-darkBorder 0 0 3px 0.5px;
      span {
        color: $color-silver;
      }
    }
  }
  &__body :hover {
    background-color: $color-charcoalGrey;
    span {
      color: $color-white;
    }
  }
}
.dark-mode .--sortedColumn {
  background-color: $color-dark;
}
//Selection of rows per page
.selectRow{
  position: relative;
  width: 40px;
  height: 20px;
  display: flex;
  input{
    position: relative;
    width: 100%;
    padding: 7px 5px;
    outline: none;
    border-radius: 5px;
    font-size: 0.8rem;
    background-color: $color-lightBackground;
    color: $color-black;
    border: $color-blueGray200 1px solid;
    cursor: pointer;
  }
  .icon{
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%) rotate(-90deg);
    svg{
      scale: 0.6;
      path{
        fill: $color-black
      }
    }
    display: flex;
    align-items: center;
    padding-top: 5px;
    transition: transform .2s ease;
    pointer-events: none;
  }
  .icon.active{
    transform: translateY(-50%) rotate(0deg);
  }
  .options{
    position: absolute;
    width: 100%;
    background-color: $color-blueGray100;
    border-radius: 10px;
    top: -50%;
    left: 110%;
    transform: translateY(0);
    overflow: hidden;
    overflow-y: auto;
    visibility: hidden;
    opacity: 1;
    transition: 0.3s ease;
    color: $color-black;
  }
  .options.active{
    visibility: visible;
    opacity: 1;
    transform: translateY(5%);
  }
  li{
    list-style: none;
    font-size: 0.9rem;
    text-align: center;
    padding: 5px;
    cursor: pointer;
  }
  li:hover{
    background-color: $color-blueGray2;
    color: $color-white;
  }
}
