@import "../../../theme/colors";

.back-btn {
    display: flex;
    align-items: center;
    justify-content: left;
    width: 100%;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.textRedirect {
    margin-top: 20px;
    font-size: 30px;
}

.container-form-report-template {
    width: 100%;
    margin-top: 1rem;
    padding: 1rem;
    border-radius: 15px;
    border: 2px dotted $color-blueGray;

    & .input-data {
        margin-top: 1rem;
    }
}

.required-field-msg {
    color: #d81b60;
    font-size: 14px;
    margin-top: 0.2rem;
}

.is-invalid {
    background-color: rgba(216, 27, 96, 0.3);
    border: 2px solid #d81b60;
}