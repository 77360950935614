@import "../../../theme/colors";

.all-reports-page {
  .all-report-tab {
    .research-folder {
      font-size: 14px !important;
      font-weight: 500;

      .flex-lg-column {
        .nav-item {
          .nav-link {
            padding: 0;
          }
        }
      }
    }
    .nav-link.active .folder-block {
      border-bottom: 4px solid $color-ethicsBlue;
      color: $color-blueGray900;
      small {
        color: $color-blueGray900;
      }
      svg {
        g {
          fill: $color-ethicsBlue;
        }
      }
    }
    .folder-block {
      display: flex;
      align-items: center;
      margin: 0.8rem auto 0.5rem auto;
      padding: 1rem 1.25rem;
      border-radius: 4px;
      border: solid 1px $color-paleGrey;
      border-bottom: 4px solid $color-paleGrey;
      color: #a1b3bc;
      cursor: pointer;
      small {
        color: #a1b3bc;
      }
      svg {
        width: 1.5rem !important;
        height: 1.5rem !important;
        g {
          fill: #a1b3bc;
        }
      }
    }
    .nav-link.active .folder-green {
      border-bottom: 4px solid $color-green;
      color: $color-blueGray900;
      small {
        color: $color-blueGray900;
      }
      svg {
        g {
          fill: $color-green;
        }
      }
    }
    .folder-green {
      display: flex;
      align-items: center;
      margin: 0.8rem auto 0.5rem auto;
      padding: 1rem 1.25rem;
      border-radius: 4px;
      border: solid 1px $color-paleGrey;
      border-bottom: 4px solid $color-paleGrey;
      color: #a1b3bc;
      cursor: pointer;
      small {
        color: #a1b3bc;
      }
      svg {
        width: 1.5rem !important;
        height: 1.5rem !important;
        g {
          fill: #a1b3bc;
        }
      }
    }
    .accordion ul {
      display: block;
      border: none;
      padding-left: 0 !important;
      li {
        text-align: left;
        font-size: 12px;
      }
    }
  }

  .container-report-users {
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
    li {
      padding: 8px;
      border-radius: 4px;
      margin-bottom: 16px;
      border: 1px solid $color-blueGray100;
      background-color: $color-blueGray50;
      cursor: pointer;
    }
    hr {
      color: $color-silver2;
    }
    .inactive-users {
      li {
        border: 1px solid $color-blueGray100;
        background-color: $color-blueGray50;
      }
    }
    .active-users {
      li {
        border: 1px solid $color-lightBlue2;
        background-color: $color-paleBlue;
      }
      li.myself {
        border: 1px solid $color-blueGray100;
        background-color: $color-blueGray50;
        cursor: not-allowed;
      }
    }
  }
}

.dark-mode .all-reports-page {
  .border-bottom {
    border-bottom: 1px solid $color-darkBorder;
  }
  .all-report-tab .nav-link {
    .folder-block {
      border: 1px solid $color-darkBorder;
      border-bottom: 4px solid $color-darkBorder;
    }
    &.active .folder-block {
      border-bottom: 4px solid $color-ethicsBlue;
      h6,
      small {
        color: $color-ethicsBlue;
      }
    }
    .folder-green {
      border: 1px solid $color-darkBorder;
      border-bottom: 4px solid $color-darkBorder;
    }
    &.active .folder-green {
      border-bottom: 4px solid $color-green;
      h6,
      small {
        color: $color-green;
      }
    }
  }
  .accordion .accordion-item {
    .accordion-body {
      border: 1px solid $color-darkBorder;
    }
    .accordion-button {
      background-color: $color-dark !important;
      color: $color-blueGray2 !important;
    }
    .research-text {
      color: $color-blueGray2;
    }
  }
  .accordion-button::after {
    filter: invert(1) !important;
  }
  .btn.small-transparent-btn:hover {
    background-color: $color-darkTwo;
    color: $color-white;
  }
  .react-datepicker__month-container {
    background-color: $color-dark;
    .react-datepicker__header {
      background-color: $color-dark;
    }
    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header,
    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      color: $color-white;
    }
  }
  .btn.small-disable-btn {
    background-color: $color-darkFour !important;
  }
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}
.accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}
.accordion {
  .accordion-item {
    background-color: transparent;
    border: none !important;
    margin-bottom: 20px;
    .accordion-button {
      background-color: $color-blueGray50 !important;
      padding: 10px !important;
      color: $color-ethicsBlue;
      //background-color: #f3f7fa!important;
      font-size: 14px !important;
      font-weight: 600 !important;
      height: 40px !important;
      border-radius: 4px;
      color: inherit !important;
      box-shadow: none !important;
    }
    .accordion-body {
      border: 1px solid $color-paleGrey2;
      border-radius: 4px;
      margin-top: 20px;
      svg {
        g {
          fill: $color-ethicsBlue;
        }
      }
      .profile-icon {
        svg {
          width: 1.5rem !important;
          height: 1.5rem !important;
        }
      }
    }
  }
  .research-text {
    padding: 0.75rem;
    font-size: 12px;
    ul {
      border: none;
      padding-left: 1rem;
      display: block;
      li {
        list-style: circle;
        padding: 0;
        font-size: 11px;
        text-align: left;
      }
    }
  }
}

.box {
  padding: 1rem 0 1rem 2rem;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1;
  &.danger {
    background-color: $color-palePink;
    border: 1px solid $color-red;
    border-left-width: 10px;
  }
}
.monitor-icon {
  padding: 5px;
  border-radius: 4px;
  background-color: $color-yellow;
  width: 1.5rem !important;
  height: 1.5rem !important;
  g {
    fill: $color-white !important;
  }
}

.newmonitor-iconnew {
  padding: 5px;
  border-radius: 4px;
  width: 1.8rem !important;
  height: 1.8rem !important;
  background-color: $color-blue;
  g {
    fill: $color-white !important;
  }
}
.newmonitor-icondraft {
  padding: 5px;
  border-radius: 4px;
  width: 1.8rem !important;
  height: 1.8rem !important;
  background-color: $color-Gray;
  g {
    fill: $color-white !important;
  }
}

.newmonitor-iconpending {
  padding: 5px;
  border-radius: 4px;
  width: 1.8rem !important;
  height: 1.8rem !important;
  background-color: $color-purple;
  g {
    fill: $color-white !important;
  }
}
.newmonitor-iconprocess {
  padding: 5px;
  border-radius: 4px;
  width: 1.8rem !important;
  height: 1.8rem !important;
  background-color: $color-green;
  g {
    fill: $color-white !important;
  }
}
.newmonitor-iconfinish {
  padding: 5px;
  border-radius: 4px;
  width: 1.8rem !important;
  height: 1.8rem !important;
  background-color: $color-yellow;
  g {
    fill: $color-white !important;
  }
}
.newmonitor-iconclosed {
  padding: 5px;
  border-radius: 4px;
  width: 1.8rem !important;
  height: 1.8rem !important;
  background-color: $color-red;
  g {
    fill: $color-white !important;
  }
}

// ADRIAN

.react-datepicker__time {
  ul {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }
  }
}
.react-datepicker__time-list {
  padding-left: 0px !important;
}
.svg-dark svg {
  filter: brightness(0) saturate(100%) invert(64%) sepia(6%) saturate(1230%)
    hue-rotate(156deg) brightness(86%) contrast(82%);
}

.modal {
  .add-icon {
    svg {
      path {
        stroke: $color-ethicsBlue;
        transition: all 250ms;
      }
      &:hover {
        path {
          stroke: $color-white;
        }
      }
    }
  }
  .disabled-filter {
    filter: opacity(50%);
  }
}

// ADRIAN

@media screen and (max-width: 992px) {
  .all-reports-page {
    padding: 1rem;
    .all-report-tab .tab-content .research-folder .flex-lg-column {
      overflow-x: auto;
      max-width: 600px;
      .nav-item {
        .nav-link {
          min-width: 310px;
          margin-right: 1rem;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .all-reports-page {
    padding: 0 1rem 1rem 1rem;
    .all-report-tab {
      & > ul li {
        min-width: 135px;
        button {
          margin: 0;
          padding: 0.5rem;
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .all-reports-page {
    &.sub-reports {
      padding: 1rem;
    }
    .all-report-tab .tab-content {
      .research-contents {
        padding: 1rem 0;
      }
    }
  }
}
