.titleSlide{
    width: 116px;
    height: 24px;    
    margin: 8px 22px 11px 12px;
    font-family: SegoeUI !important;
    font-size: 18px !important;
    font-weight: bold;
    letter-spacing: normal;
    color: white;
}

.headerSlide{
    // background-image:url(''), linear-gradient(to bottom, #6aa4ff, #3886ff);
    background-image: url('../../assets/images/bgImageAI1.png');
    border-top-left-radius: 20px;
    display:flex;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    height: 90px;
}

.btnAi{
  // width: 125px;
  height: 38px;
  color: white;
  font-size: 13px;
  border: 1px solid #3886ff;
  padding: 8px 9px 7px 8px !important;
  border-radius: 23px !important;
  background-image: linear-gradient(to bottom, #6aa4ff, #3886ff);
  display: flex;
  align-items: center;
}
.logoIa{
  width: 37px;
  margin-top: 10px;
}

.closeButton{
    position: absolute;
    right: 24px;
  }

.containerHead{
    width: 600px !important;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: -20px 0 30px -10px rgba(28, 37, 41, 0.47);
}
.bodyContainer{
    padding-left: 32px !important;
    padding-right: 32px !important;
    padding-top: 50px !important;
    // padding:32px;
}

.alertAi{
    background-color: #fff6da !important;
    border: 1px solid #ffc35f !important;
    height: 95px !important;
    font-size: 12px;
    border-radius: 6px!important;
   
}

.wrapAlert{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.containerImg{
    width: 535px;
    height: 220px;
    margin: 33px 0px 0px 0px;
    padding-top: 5px;
    border-radius: 4px;
    background-image: url('../../assets/images/backGIA.png'), linear-gradient(to bottom, #2cc3f8, #00b8f7);
    background-color: linear-grandient ;
    display: flex;
    justify-content: center;
    align-items: center;
}


  p{
    font-size: 12px;
  }

  .subTitle{
    margin-top: 26px;
    margin-bottom: 20px;
  }

  .subTitle h4 { 
    margin-bottom :16px;
  }
  .alertSuccess{
    font-size: 12px !important;
    max-width: 280px !important;
    min-width: 230px !important;
    height: 30px !important;
    display: flex !important;
    align-items: center !important;
    border:0.5px solid #2474f2 !important;
    background-color: #e4f8ff !important;
    justify-content: center  !important;
    border-radius: 5px;
    margin-bottom: 16px !important;
    color: #008abc;
  }
 #renderBtn{
    background-color: #009ed7;
    color: white;
    padding: 10px 19px 10px 19px;
    border: 1px ;
    border-radius: 5px;
    font-size: 13px;
    margin-top: 27px;
 }
 .Policity{
    margin-top:27px;
 }
 .continerMore{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 535px;
    height: 50px;
    margin: 0px 1px 20px 37px;
    padding: 9px 24px 11px 16px;
    border-radius: 4px;
    border: solid 0.5px #2474f2;
    background-color: #e4f8ff;
 }
 .more{
  margin-left: 10px;
  font-family: SegoeUI;
  font-size: 13.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #008abc;
 }
 #renderBtn[disabled]{
    background-color: #d3dbe1;
    color: #909ca4;
    padding: 10px 19px 10px 19px;
    border: 1px ;
    border-radius: 5px;
    font-size: 13px;
    margin-top: 27px;
 }
 .seeMore{
    color: #008abc !important;
    font-size: 12px;
 }
 .loadingActivity{
  border:1px solid #bdcad0;
  border-radius: 6px;
  width: 536px;
  height: 384px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
 }
 .cancelSearch{
  background-color:transparent;
  color:#009ed7;
  padding: 10px 19px 10px 19px;
  border: 1px solid #009ed7;
  border-radius: 5px;
  font-size: 13px;
  margin-top: 27px;
 }
 .wrapperActivity{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
 }

 @keyframes sonar {
  0% {
    transform: scale(0.5);
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

.sonar-animation {
  animation-name: sonar;
  animation-duration: 2s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
  border: 1px solid grey;
  border-radius: 50%;
  width: 266px;
  height: 255px;
  padding: 27px 19px 112px 41px;
  opacity: 0.24;
  filter: blur(4px);
  position: fixed;
}


.wrapperLoading{
  height: 100%;
}


.activeContainer{
  border: 2px solid #c9e9ff;
  border-radius: 5px;
  margin-top: 20px;
  font-size: small;
}
.containerPlans{
  padding: 20px;
}
.containerInput{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.general{
  height: 100%;
}
.footerActivity{
  padding: 32px;
  font-size: 13.5px;
  font-family: SegoeUI;
}
.btnPlanes{
  background-color: #009ed7;
  color: white;
  padding: 10px 19px 10px 19px;
  border: 1px ;
  border-radius: 5px;
  font-size: 13px;
}
.btnPlanes:hover{
  background-color: transparent;
  color:  #009ed7;
  padding: 10px 19px 10px 19px;
  border: 1px solid #009ed7;
  border-radius: 5px;
  font-size: 13px;
}