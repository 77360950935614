@import "../../../theme/colors";


.buttonAdd{
  margin-top: -20px !important;
}
.buttonSee{
  margin-top: -20px;
}




.custom-modal .modal-dialog {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

button:focus {
  outline: none !important;
  box-shadow: none !important; /* También elimina cualquier sombra de enfoque que pueda estar presente */
}

.no-focus-outline:focus {
  outline: none !important;
  box-shadow: none !important;
}


// .custom-modal .modal-dialog {
//   width: 50%;       /* Ancho del 50% de la pantalla */
//   height: 50%;      /* Alto del 50% de la pantalla */
//   max-width: none;  /* Anula cualquier límite de ancho máximo predeterminado */
// }

.custom-modal .modal-content {
  height: 100%;     /* Asegura que el contenido del modal ocupe todo el contenedor */
}

// .form-check {
//   border-bottom: 1px solid #a6a4a4;  /* Ajusta el color del borde como desees */
// }
