
.loading_container {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  flex: 1 0 auto;
  height: 100%;
  width: 100%;
  user-select: none;
}
.loading_icon {
  -webkit-animation:spin 2s linear infinite;
  -moz-animation:spin 2s linear infinite;
  animation:spin 2s linear infinite;
  margin-bottom: 8px;
}

@-moz-keyframes spin { 
  100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
  100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
  100% { 
      -webkit-transform: rotate(360deg); 
      transform:rotate(360deg); 
  } 
}

.loading_text{
  display: flex;
  justify-content: space-around;
  font-weight: 800;
  padding: 0 10px 0 10px;
}

.loading_text span{
  animation: fade 0.9s alternate infinite;
  // background-color: aliceblue;
}
.loading_text span:nth-child(2){ 
  animation-delay: 0s; 
}
.loading_text span:nth-child(3){ 
  animation-delay: 0.10s; 
}
.loading_text span:nth-child(4){ 
  animation-delay: 0.20s; 
}
.loading_text span:nth-child(5){ 
  animation-delay: 0.30s; 
}
.loading_text span:nth-child(6){ 
  animation-delay: 0.40s; 
}
.loading_text span:nth-child(7){ 
  animation-delay: 0.50s; 
}
.loading_text span:nth-child(8){ 
  animation-delay: 0.60s; 
}
.loading_text span:nth-child(9){ 
  animation-delay: 0.70s; 
}
.loading_text span:nth-child(10){ 
  animation-delay: 0.80s; 
}
.loading_text span:nth-child(11){ 
  animation-delay: 0.9s; 
}

@keyframes fade {
  from{
    opacity: 1;
  }
  to{
    opacity: 0;
  }
}