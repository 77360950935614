@import '../../theme/colors';

.plan-container{
    background: $color-landing;
    padding: 21px 30px;
    #plan-left-card{
        width: 40%;
    }
    #plan-right-card{
        width: 56%;
        margin-left: 4%;
    }
    .accordion-body{
        svg{
            g{
                fill: $color-ethicsBlue;
            }
        }
    }
}

.customCardTitle{
    border-left:4px solid #980ac7;
    padding: 8px;
    min-height:45px;
    line-height:2em;
    font-weight: bold;
    border-radius: 5px;
    border-top: 1px solid #ced4da;
    border-right: 1px solid #ced4da;
    border-bottom: 1px solid #ced4da;
}

@media screen and (max-width: 992px) {
    .plan-container{
        #plan-left-card{
            width: 100%;
        }
        #plan-right-card{
            width: 100%;
            margin-left: 0;
            margin-top: 1.5rem;
        }
    }
}

@media screen and (max-width: 576px) {
    .plan-container{
        padding: 21px 15px;
        .accordion-body{
            padding: 0;
            &.auth-accordion-body{
                padding: 0.5rem;
            }
        }
    }
}