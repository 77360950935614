//COLORES PRIMARIOS
$color-ethicsBlue: #009ed7;
$color-blueGray: #546e7a;
$color-blueGray2: #78909c;
$color-oxfordBlue: #152235;
$color-oxfordBlue2: #32384d;
$color-white: #ffffff;
$color-black: #000000;
$color-lightBlue: #e9f5fd;
$color-lightBlue2: #a0d4fa;

//COLORES NEUTRALES
$color-blueGray50: #f3f7fa;
$color-blueGray100: #e6ebef;
$color-blueGray200: #d3dbe1;
$color-blueGray500: #b5c0c8;
$color-blueGray600: #909ca4;
$color-blueGray700: #5c666e;
$color-blueGray800: #262f38;
$color-blueGray900: #324148;

//COLORES SECUNDARIOS
$color-blue: #3181ff;
$color-blueII: #5a9aff;
$color-blueIII: #83b3ff;
$color-blueIV: #adcdff;
$color-blueV: #d6e6ff;
$color-green: #44cb67;
$color-greenII: #69d585;
$color-greenIII: #8fe0a4;
$color-greenIV: #b4eac2;
$color-greenV: #daf5e1;
$color-yellow: #ffaf00;
$color-yellowII: #ffbf32;
$color-yellowIII: #ffcf66;
$color-yellowIV: #ffdf9a;
$color-yellowV: #ffefcc;
$color-red: #de463d;
$color-redII: #e56b64;
$color-redIII: #eb908b;
$color-redIV: #f2b5b1;
$color-redV: #f8dad8;
$color-teal: #007b6f;
$color-tealII: #33958c;
$color-tealIII: #66b0a9;
$color-tealVI: #99cac6;
$color-tealV: #cce5e2;
$color-purple: #7c4dff;
$color-purpleII: #9671ff;
$color-purpleIII: #b094ff;
$color-purpleIV: #cbb8ff;
$color-purpleV: #e5dbff;
$color-pink: #d81b60;
$color-pinkII: #e04a80;
$color-pinkIII: #e876a0;
$color-pinkIV: #efa4bf;
$color-pinkV: #f7d1df;
$color-lime: #cddc39;
$color-limeII: #d7e361;
$color-limeIII: #e1ea88;
$color-limeIV: #ebf1b0;
$color-limeV: #f5f8d7;
$color-lightYellow: #ffefc0;
//COLORES DAShBOARD
$color-Gray: #66818d;
$color-gray2: #d8dfe3;
$color-blue2: #0277bd;

//COLORES JORGE
$color-slate: #455a64;
$color-slate2: #546e7a;
$color-slate3: #566d77;
$color-greyBlue: #66818d;
$color-gunmetal: #5d5e5e;
$color-dark: #2c2c3a;
$color-darkTwo: #263238;
$color-darkThree: #1a1d27;
$color-darkFour: #242836;
// $color-lightBlue: #0c0c0c;
$color-paleBlue: #e8f5fd;
$color-paleBlue2: #e1f5fe;
$color-paleGrey: #eceff1;
$color-paleGrey2: #e3ebf5;
$color-paleGrey3: #f0f2f4;
$color-silver: #bdcad0;
$color-silver2: #d8dfe3;
$color-landing: #f3f7fa;
$color-cobalt: #1a4792;
$color-lightishBlue: #536dfe;
$color-dodgerBlue: #3181ff;
$color-coolGray: #b0bec5;
$color-palePink: #ffd7da;
$color-charcoalGrey: #31323a;
$color-darkSidebar: #0d0c11;

$color-darkBorder: #363742;
$color-lightBorder: #dee2e6;
$color-lightBackground: #f3f7fa;
$color-darkBackground: #141319;
