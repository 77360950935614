@import "../../../theme/colors";


.outline-message{
    width: 1.5rem!important;
    height: 1.5rem!important;
    g{
        fill: $color-green;
    }
}

#activity-viewer{
    background-color: white;
    border:1px solid #e3ebf5;
    font-size: 13px !important;

    .title {
       
        svg {
            border-radius: 4px;
            margin-right: 10px;
        }

        h5 {
            padding-top: 7px;
            font-size: 13px !important;
            font-weight: 600;
            font-size: 13px;
        }
    }

    small {
        font-size: 12px;
        font-weight: 400;
    }

    .carousel-control-prev{
        top: -3rem;
        left: unset;
        bottom: unset;
        right: 2rem;
        cursor: pointer;
    }
    .carousel-control-next{
        top: -3rem;
        bottom: unset;
        cursor: pointer;
    }
    .carousel-control-next-icon{
        background-image: url("../../../assets/images/next.png");
        width: 24px!important;
        height: 24px!important;
    }
    .carousel-control-prev-icon{
        background-image: url("../../../assets/images/before.png");
        width: 24px!important;
        height: 24px!important;
    }
}

/* @media screen and (max-width: 768px) {
    #activity-viewer{
        order: 2;
    }    
} */