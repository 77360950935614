@import "../../../../../theme/colors";

//Icon from investigation basic/advance
.--advance {
  scale: 0.7;

  g {
    fill: $color-ethicsBlue;
  }
}

.--basic {
  scale: 0.7;

  g {
    fill: $color-green;
  }
}

.modalHeader {
  border-bottom: none !important;
}


.infractors {

  line-height: 2.5em;

  h6 {
    font-size: 16px !important;
    font-weight: 600 !important;
  }

  span {
    font-size: 14px !important;
    font-weight: 500 !important;
  }

  strong {
    font-weight: 600;
    font-size: 15px;
  }

  .accordion .accordion-item .accordion-body {
    margin-top: 0px !important;
  }

}

.inv-btn-small-text{
  font-size: 0.8rem !important;
}