@import '../../../theme/colors';
@import '../../../theme/fontFace';


.edit-tool {
    position: relative;
    overflow: hidden;
    /* Asegura que los elementos fuera del contenedor estén ocultos */
    padding: 0px;
    // margin: 1em 0px;
    transition: all 250ms;

    .loading-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(255, 255, 255, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5em;
        color: #000;
        z-index: 10;
    }

    .edit-tool-box {
        cursor: pointer;
        position: absolute;
        right: -100%;
        /* Inicialmente fuera de la vista por la derecha */
        top: 50%;
        margin-top:-50px;
        transform: translateY(-50%);
        /* Centra verticalmente */
        padding: 12px 0px;
        background-color: #546E7A;
        width: 50px;
        height: 50px;
        text-align: center;
        border-radius: 5px;
        transition: right 0.3s ease-in-out, background-color 0.3s ease-in-out;
        /* Transición suave para el movimiento y el cambio de color */

        z-index: 99;

        svg {
            width: 40px;

            g {
                fill: white;
            }
        }
    }

    &:hover .edit-tool-box:not(.fixed) {
        right: 0;
        /* Mueve el elemento a su posición visible */
        
    }

    .edit-tool-box.fixed {
        right: 0;
        /* Mantiene el elemento visible */
        background-color: #FF8C00;
        /* Cambia el color de fondo cuando está fijo */
    }

    .edit-tool-box-btn {
        position: absolute;
        right: 0;
        /* Posiciona debajo de la primera caja */
        padding: 12px 0px;
        width: 50px;
        height: 50px;
        text-align: center;
        border-radius: 5px;
        transition: top 0.3s ease-in-out;
        /* Transición suave para el movimiento */

        svg {
            width: 40px;

            g {
                fill: white;
            }
        }
    }

    .edit-tool-box-btn.save {
        top: 100%;
        margin-top: 0.5em;
        background-color: #8BC34A;
    }

    .edit-tool-box-btn.delete {
        top: 200%;
        margin-top: 1em;
        background-color: $color-red;
    }

    &:hover { 
        box-shadow: 0px 25px 20px 0px rgba(100,100,100,0.1);
        transform: scale(1.01);
    }
}