@import "../../../../theme/colors";

//From ToDoCare.jsx
.statusTask {
  padding: 10px;
  &__selectAll {
    text-indent: 0.75vw;
    border-bottom: $color-gray2 dotted 1px;
    padding: 5px;
  }
  &__list {
    list-style: none;
    li {
      border-bottom: $color-gray2 dotted 1px;
      padding: 5px 25px;
      text-indent: 0.75vw;
      .done {
        color: $color-slate;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          left: 6%;
          top: 49%;
          width: 105%;
          height: 2px;
          border-bottom: 2px solid $color-red;
          border-radius: 5px;
          box-shadow: $color-palePink 0 0 2px 1px;
        }
      }
    }
    label:hover {
      cursor: pointer;
    }
  }
}
