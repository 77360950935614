@import "../../../theme/colors";




.graphic-row {
    margin-right: -10px;
    margin-left: -10px;
}






.graphic-content {
    border-radius: 5px;
    margin-bottom: 20px;
    padding: 0 10px !important;  /* Añade 5px de padding a cada lado */
    background-color: white;

    &.light {
        border: solid 1px $color-paleGrey2;
    }

    &.dark {
        border: solid 1px $color-blueGray2;
        color: $color-blueGray2;
    }

    .subtitle {
        font-size: 14px;
        font-size: 16px !important;
        font-weight: 600 !important;
        color: #263238 !important;
    }

}

.graphic-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;

    &.light {
        border-bottom: solid 2px $color-paleGrey2;
    }

    &.dark {
        border-bottom: solid 2px $color-blueGray2;
    }

    .options {
        display: flex;
        justify-content: end;
        align-items: center;

        .maximize-button {
            background-color: #fff;
            border: #fff;
        }

        .form-select.form-select-sm {
            color: $color-blueGray2 !important;
            cursor: pointer;
        }

        &.light {
            .form-select.form-select-sm {
                border: solid 0px $color-paleGrey2 !important;
            }

            .form-select.form-select-sm:hover {
                border: solid 1px $color-paleGrey2 !important;
            }
        }

        &.dark {
            .form-select.form-select-sm {
                border: solid 0px $color-blueGray2 !important;
            }

            .form-select.form-select-sm:hover {
                border: solid 1px $color-blueGray2 !important;
            }
        }
    }



}

.option-menu {
    border-left: solid 1px $color-blueGray2;
    margin-left: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.graphic-options {
    &.dark {
        .dropdown-menu.show {
            position: absolute;
            inset: 0px auto auto 0px;
            transform: translate3d(851.5px, 264.5px, 0px);
            background-color: $color-darkThree;
            border: solid 1px $color-blueGray2;
            color: $color-blueGray2;
        }
    }
}

.graphic-options:hover {
    border-radius: 5px;
    max-width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.light {
        border: solid 1px $color-paleGrey2;
    }

    &.dark {
        border: solid 1px $color-blueGray2;
    }
}

.graphic-container {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    position: relative;
    margin: 1rem;
    max-width: 100%;
    // max-height: 2000px;
}

.graphic-body {
    padding: 20px;
    position: relative;

    .graphic-switch {

        // position: absolute;
        // right: 10px;
        // top: 10px;
        // width: 80px;
        // height: 20px;
        // margin-left: -40px;
        // z-index: 10;

        display: flex;
        flex-wrap: wrap-reverse;
        position: relative;
        width: 80px;
        height: 20px;
        margin-left: 90%;
        z-index: 10;

        .form-check-input {
            position: absolute;
            left: 50%;
            margin: 0px;
            margin-left: -16px;
        }

        .graphic-option {
            position: absolute;
            left: 0px;
            filter: invert(92%) sepia(8%) saturate(287%) hue-rotate(154deg) brightness(86%) contrast(92%);
        }

        .data-option {
            position: absolute;
            right: 0px;
            filter: invert(92%) sepia(8%) saturate(287%) hue-rotate(154deg) brightness(86%) contrast(92%);
        }
    }


    .graphic-indicators {
        min-height: 100%;
        // display: flex;
        // justify-content: center;
        // align-items: center;
    }

    .graphic-indicaors-right {

        min-height: 128%;
        min-width: 119%;
        margin-top: -105px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-left: 20px solid #F3F7FA;

    }

    .graphic-element {
        min-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        canvas {
            width: 600px;
        }
    }



}

@media (max-width: 991px) {
    .graphic-body {
        .graphic-indicaors-right {
            border-left: none;
            margin-top: 20px;
            padding-bottom: 20px;
            margin-left: -10%;
        }
    }

    .graphic-container {
        // display: flex;
        // justify-content: center;
        // align-items: center;
        position: relative;
        margin: 1rem;
        max-width: 100%;
        max-height: 6000px !important;
        min-height: 100% !important;
    }
}

@media screen and (max-width: 1100px) {

    .graphic-element {
        canvas {
            width: 100% !important;
        }

        min-height: 250px;
        animation: showComponent 0.6s forwards;
    }
}

// Animation
.center-body {
    position: absolute;
    width: 80px;
    left: 50%;
    margin-left: -40px;
    z-index: 2;
    opacity: 0;
    animation: hideComponent 0.3s forwards;
    min-height: 250px;
}

.show-loading .graphic-body {
    opacity: 0;
    animation: hideComponent 0.3s;
}

.show-loading .center-body {
    opacity: 1;
    animation: showComponent 0.6s;
}


.graphic-indicators {
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.graphic-indicaors-right {
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.graphic-element {
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/*
Animation to hide component
*/
@keyframes hideComponent {
    0% {
        opacity: 1;
    }

    20% {
        opacity: .8;
    }

    40% {
        opacity: .6;
    }

    60% {
        opacity: .4;
    }

    80% {
        opacity: .2;
    }

    100% {
        opacity: 0;
        display: none;
    }
}

/*
Animation to show component
*/
@keyframes showComponent {
    0% {
        display: flex;
        opacity: 0;
    }

    20% {
        opacity: .2;
    }

    40% {
        opacity: .4;
    }

    60% {
        opacity: .6;
    }

    80% {
        opacity: .8;
    }

    100% {
        opacity: 1;
    }
}