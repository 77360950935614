@import "../../../theme/colors";

#report-number{
    width: 110px;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-lightishBlue;
    border: 1px solid $color-paleGrey2;
    border-radius: 50%;
    font-size: 2rem;
    font-weight: bold;
    margin: 1rem auto;
}

#number-reports{
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.dashboard-carousel{
    padding: 0 20px;
    border-radius: 4px;
    background-color: $color-white;
    .container{
        background-color: transparent;
        box-shadow: none;
    }
    .carousel-inner{
        .btn{
            background-color: $color-white;
            color: $color-ethicsBlue;
            height: 30px;
        }
        #ticket-btn{
            background-color: #b3dcb6;
            border: 1px solid #5ab963;
            color: $color-blueGray900;
            font-size: 12px;
            padding: 0 5px!important;
            box-shadow: none;
            min-height: 28px;
        }
    }
}

.dashboard-card{
    .more{
        max-width: 1.5rem;
        max-height: 1.5rem;
        g{
            fill: $color-blueGray!important;
        }
    }
}