@import '../../../../../theme/colors';

.container-rectangle-card {
    display: none;
}

.rectangle-card:hover {
    background-color: $color-blueGray50;
    transition:       all ease-in-out .5s;
}

.rectangle-card-content {
    align-items:     center;
    display:         flex;
    flex-direction:  column;
    gap:             .5rem;
    justify-content: center;
    margin:          12px auto;
    padding:         .5rem 2rem;
    width:           min(520px, 100%);
}

.rectangle-card-information {
    align-items:     center;
    display:         flex;
    flex-wrap:       wrap;
    gap:             24px;
    justify-content: space-evenly;

    & span {
        color:       $color-blueGray2;
        font-size:   10px;
        font-weight: 600;
    }
}

.buttons-control {
    align-items:     center;
    display:         flex;
    flex-wrap:       wrap;
    gap: 8px;
    justify-content: space-around;
    margin:          8px auto;
    width:           100%;
}

// 1200 px
@media (max-width: 1200px) {
    .container-rectangle-card {
        display: block;
    }

    .container-card {
        display: none;
    }
}

// 1200 px
@media (max-width: 520px) {
    .buttons-control {
        & button {
            width: 100%;
        }
    }
}