@import "../../../theme/colors";

.icon-Header {
  border: 2px solid $color-ethicsBlue;
  border-radius: 50%;
  width: 38px;
  height: 38px;
  display: grid;
  place-content: center;
}
