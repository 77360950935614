@import "../../../theme/colors";
.landingPage {
  .error {
    color: $color-red;
    margin: 0;
    padding: 0 10px;
  }
  .images {
    &__image {
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-flow: row wrap;
      flex-grow: 1;
      gap: 1vh;
    }
  }
  .howWork {
    &__question {
      display: flex;
      flex-grow: 1;
      justify-content: space-around;
      align-items: center;
      flex-flow: row wrap;
      gap: 0.5vw;
      margin: -10px 0;
      .inputField {
        flex-grow: 1;
      }
    }
    &__img {
      padding: 10px;
      background-color: $color-landing;
      border-radius: 20%;
    }
  }
  &__editQuestions {
    .confirm {
      text-align: end;
    }
    .--save {
      background-color: transparent;
      border: none;
      svg {
        path {
          fill: $color-green;
        }
      }
    }
    .--noSave {
      background-color: transparent;
      border: none;
      opacity: 0.5;
      svg {
        path {
          fill: $color-blueGray;
        }
      }
    }
    .editingInputs {
      padding: auto 10px;
      .questionInput {
        font-weight: 600;
        font-size: 0.8rem;
      }
      .questionInput::placeholder {
        font-weight: normal;
      }
      .answerInput {
        font-size: 0.8rem;
        font-style: italic;
      }
    }
  }
  &__questions {
    .modifying {
      text-align: end;
      margin-top: -2px;
    }
    .answerContainer {
      border: $color-lightBorder 2px solid;
      border-radius: 8px;
      padding: 0.2rem 1rem;
      background-color: $color-lightBackground;
      font-style: italic;
      font-size: 0.9rem;
      text-align: justify;
    }

    .--close {
      background-color: transparent;
      border: none;
      svg {
        path {
          fill: $color-red;
        }
      }
    }
    .--edit {
      background-color: transparent;
      border: none;
      svg {
        path {
          fill: $color-ethicsBlue;
        }
      }
    }
  }
  .colors {
    &__section {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      gap: 1vh;
      flex-flow: row;
      margin: 1vh auto;
    }
    &__picker {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: column wrap;
      p {
        margin: 3px auto;
        font-size: 0.7rem;
      }
      fieldset {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
      }
    }
    &__mainTextDemo {
      width: 100%;
      p {
        margin: 3px auto;
        font-size: 0.7rem;
      }
      fieldset {
        border: $color-silver dotted 2px;
        border-radius: 8px;
        text-align: center;
        h1 {
          font-size: 1.7rem;
          margin: 0.5vh auto;
        }
      }
    }
    &__secondaryTextDemo {
      width: 100%;
      p {
        margin: 3px auto;
        font-size: 0.7rem;
      }
      fieldset {
        border: $color-silver dotted 2px;
        border-radius: 8px;
        padding: 10px;
        p {
          text-align: center;
          font-size: 14px;
        }
      }
    }
  }
  .--exampleBtn {
    margin-left: 2em;
    min-width: 180px;
    padding: 2px;
    // background-color: white;
    // border: 1px solid #1a4792;
    transition: background-color 0.4s, box-shadow 0.5s, color 0.4s;

    font-weight: 500;
    -webkit-box-pack: center;
    -webkit-box-align: center;
    color: white;
    padding: 8px 12px;
    font-size: 0.85rem;
    border-radius: 4px;
  }

  .--pulseBlue {
    animation: pulse-blue 2s infinite;
  }

  .--iconColor {
    scale: 0.6;
    margin-left: 1vw;
    g {
      animation: color-brush 30s infinite ease-in-out;
    }
  }

  @keyframes color-brush {
    0% {
      fill: $color-ethicsBlue;
    }
    10% {
      fill: $color-green;
    }
    20% {
      fill: $color-red;
    }
    30% {
      fill: $color-pink;
    }
    40% {
      fill: $color-purple;
    }
    50% {
      fill: $color-yellow;
    }
    60% {
      fill: $color-lime;
    }
    70% {
      fill: $color-silver;
    }
    80% {
      fill: $color-lightBlue;
    }
    90% {
      fill: $color-black;
    }
    100% {
      fill: $color-blue;
    }
  }

  .channels {
    &__title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 3.3vh;
    }
    .--whatsApp {
      scale: 0.5;
      margin: 0 -7px;
      border: $color-green 3px solid;
      border-radius: 100%;
      padding: 5px;
      svg {
        g {
          fill: $color-green;
        }
      }
    }
    .--phone {
      scale: 0.7;
      g {
        fill: $color-gunmetal;
      }
    }
    .--iconLanding {
      scale: 0.8;
      g {
        fill: $color-cobalt;
      }
    }
  }
  .sticky-col {
    position: -webkit-sticky;
    position: sticky;
    top: 1vh;
    max-height: 90vh;
  }

  @keyframes pulse-blue {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(52, 172, 224, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(52, 172, 224, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(52, 172, 224, 0);
    }
  }

  .supportMaterial {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-flow: row wrap;
    &__uploader {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: column nowrap;
    }
  }
}
