@import "../../../../../theme/colors";

.history {
  max-height: 60vh;
  overflow-y: auto;
}

.no-data-etiquin {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 15px;
}

.--showHistory {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -5px auto 7px;
  color: $color-blueGray600;
  width: 75%;
  cursor: pointer;
  &__line {
    flex-grow: 1;
    height: 1px;
    background-color: $color-blueGray200;
    margin: 0 5px;
  }
}

.--showHistory:hover {
  color: $color-ethicsBlue;
  .--showHistory__line {
    background-color: $color-ethicsBlue;
  }
}
