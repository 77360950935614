@import "../../theme/colors";

.users-page{
    .rdt_Table{

        .rdt_TableHead {

            .rdt_TableHeadRow {
                font-size: 15px;
                font-weight: 500;
            }

        }

        .rdt_TableBody {

            .rdt_TableRow {
                font-size: 14px !important;
                font-weight: 300;
            }

        }

        .form-check{
            min-height: 1rem!important;
            input{
                width: 22px!important;
                height: 22px!important;
                margin-top: 0!important;
            }
        }

        .table-icons {
        
            .icon {
                min-height: 32px !important;
                max-height: 32px !important;
                min-width: 32px !important;
                max-width: 32px !important;
                border-radius: 4px;
                transition: all 150ms ease;
                border: solid 1px transparent;
            }

            svg {
                min-height: 24px !important;
                max-height: 24px !important;
                min-width: 24px !important;
                max-width: 24px !important;
            }
        }


    }

    .sub-header {
        border:0px solid #000 !important;

        h5 {
            font-size: 18px !important;
            font-weight: 400 !important;
        }

        .mcbtn {

            font-size: 14px;
            font-weight: 400;
            // padding: 7px 10px;

            .btn-icon {

                // padding: 5px;
                

                svg {
                    min-height: 24px !important;
                    max-height: 24px !important;
                    min-width: 24px !important;
                    max-width: 24px !important;
                }
            }
        }
    }



}

.form-group  {
    
    small {
        font-size: 14px;
        color: #66818d;
        font-weight: 300;
    }

}

.user-button {
    

    .btn-primary {
        font-size: 15px !important;
        font-weight: 300 !important;
        width: 100% !important;
    }
}





.user-page {
    .forgot-password-text {
        font-style: normal;
        color: $color-ethicsBlue;
        cursor: pointer;
        font-size: 14px;
        transition: color ease 250ms;
        &:hover {
            color: darken($color: $color-ethicsBlue, $amount: 10);
        }
    }
}
.container{
    display:                flex;
    flex-direction:         row;

}
.wrapper-country{
    width:                  55px;
    border:                 1px solid #ced4da; 
    border-radius:          4px;
    margin-top: 15px;
    font-size: 13px;
}

.wrapper-country:focus{
    border-color:           #009ed7;
}

.container{
    display:                flex;
    flex-direction:         row;

}

.btnHR{
    width:                  100px;
    border-radius:          5px;
    background-color:       transparent;
    border:                 1px solid #009ed7;
    color:                  #009ed7;
    padding:                3px;
    font-size:              small;
    font-weight:            600;
}

.btnHR:hover{
    background-color:       #009ed7 !important;
    color:                  #fff;
}

.tree{
    ul{       
        li{
            padding: 0.3rem 0.5rem;
            background-color: $color-paleBlue;
            border-radius: 4px;
            border: 1px solid #a0d4fa;
            margin-top: 1rem;
            font-size: 12px;
            font-weight: 600;
        }
    }
}

.permit-preview #file-upload .dropzone #preview{
    width: 100%;
}

#filter-svg{
    svg{
        width: 1rem!important;
        height: 1rem!important;
        margin-left: 0.2rem;
    }
    cursor: pointer;
}

.segments-page {
    // container de todos los children
    .children-container {
        padding:                16px;
        padding-left:           0px;
        // border:                 1px solid #D8DFE3;
    }
    // desplegar los catalogos con la flechita
    .segment-children {
        max-height:             0;
        transition: max-height 0.15s ease-out;
        overflow:               hidden;
    }
    .segment-children.show {
        max-height:             2000px;
        overflow: auto;
        transition:             max-height 0.25s ease-in;
    }
    // flechita de los catalogos
    .arrow-colapse {
        width:                 16px;
        filter: brightness(0) saturate(100%) invert(57%) sepia(62%) saturate(5088%) hue-rotate(165deg) brightness(93%) contrast(105%);
        transition: all 250ms;
    }
    .arrow-colapse.collapsed {
        transform:          rotate(-90deg);
    }
    // linea de rayas a la izquierda de cada hijo despues del primero
    .doted-rline {
        border-left:        2px dashed #D8DFE3;
    }
    // contenedor de cada hijo
    .child {
        display:            flex;
        overflow: hidden;
        // border:             1px solid #D8DFE3;
        // overflow:           hidden;
        margin-left:        10px;
        border-radius:      4px;
    }
    // el primer hijo de la cadena
    .color-lbar {
        width:             5px;
    }
    .nest-lvl-1 .color-lbar { background-color: $color-blue }
    .nest-lvl-2 .color-lbar { background-color: $color-yellow }
    .nest-lvl-3 .color-lbar { background-color: $color-teal  }
    //Lista de valores
    .list-values {
        .list-values-value {
            color: $color-blueGray;
        }
    }
    .add-icon {
        svg {
            path {
                stroke: $color-ethicsBlue;
                transition: all 250ms;
            }
            &:hover {
                path {
                    stroke: $color-white;
                }
            }
        }
    }
}


@media  screen and (max-width: 576px) {
    #menu{
        width: 100%;
    }
}