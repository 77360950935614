@import "../../../theme/colors";

.tabletd {
  font-size: 13px !important;
  font-weight: 300;
}

//ReportList.jsx
.sub-header {
  min-height: 4vh;
  margin: 0 0 35px;
  padding: 13px 20px 13px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $color-darkBorder;

  small {
    font-size: 13px;
    font-weight: 300;
  }
  //Icons of settings
  &__settings {
    display: flex;
    margin-right: 1vw;
    gap: 2vh;
  }
  &__reportView {
    display: flex;
    justify-content: center;
    border-radius: 4px;
    align-items: center;
    padding: 1px;
  }
  //Filter Section
  .filters {
    border-left: $color-silver solid 1px;
    padding: 0 1.3rem;
    text-wrap: nowrap;
    .dropdown-menu ul li {
      color: $color-blueGray2;
    }
    .custom-dropdown {
      color: $color-blueGray2;
    }
  }
  svg g {
    fill: $color-blueGray2;
  }
  .filter-footer {
    margin-top: 2vh;
    display: flex;
    justify-content: end;
    flex-flow: row wrap;
    gap: 1vw;
  }
}

/** .reportTable contains the styles/css to customize the data table according to the React Data Table Component documentation (.rdt_TableProperty) **/
.reportsTable {
  margin-top: 20px;
  padding: 3px;
  border-radius: 5px;

  .rdt_Table {
    background-color: transparent;
    max-height: 65vh;
    overflow: auto;
    overflow-x: hidden;
    padding-bottom: 1rem;
  }
  .rdt_TableHeadRow {
    background-color: $color-white;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .rdt_TableCol {
    background-color: transparent;
  }
  .rdt_TableRow {
    background-color: transparent;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .rdt_TableRow:hover {
    background-color: $color-blueGray50;
    border: none;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);

    .rdt_TableCell {
      transform: scale(1.04);
      font-weight: 700 !important;
      color: #333;
      font-size: 13px;
    }
  }
  .reports-table {
    border-radius: 4px;
  }
}
//Styles od .reportstable in darkMode
.dark-mode .rdt_TableHeadRow {
  background-color: $color-darkThree;
}

//Footer paginator
.paginator {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-flow: row wrap;
  margin-top: 2vh;
  &__filesPerPage {
    display: flex;
    justify-content: center;
    font-size: 0.7rem;
  }
  &__select {
    font-size: 0.9rem;
    border: none;
    border-radius: 7px;
    scale: 0.8;
  }
  &__searchPage {
    border: none;
    width: 15px;
    height: 15px;
    text-align: center;
    font-size: 0.6rem;
  }
  &__btnsAction {
    display: flex;
    justify-content: space-around;
    flex-flow: row;
  }
  &__btnPag {
    scale: 0.8;
    min-width: 23px;
    height: 23px;
    margin: auto -1px;
    border: none;
    border-radius: 4px;
    background-color: $color-paleGrey;
    font-size: 0.7rem;
  }
  &__btnSection {
    background-color: transparent;
    scale: 0.8;
    padding: 2px;
    width: 25px;
    height: 20px;
    border: none;
    margin: auto -4px;
  }
  &__btnSection p {
    font-size: 1.8rem;
    text-align: center;
    letter-spacing: -3px;
    margin: -25px auto;
    font-weight: lighter;
  }
  &__btnIcon {
    background: transparent;
    border: none;
    margin: auto -4px;
  }
  &__icon {
    display: flex;
    justify-content: center;
    height: 2vh;
    padding: 2px;
  }
  &__renderPagesNumber {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-flow: row wrap;
    padding: 0 1rem;
  }
  &__results {
    font-size: 0.7rem;
  }
  .--disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
}

//Dark mode paginator
.dark-mode .paginator {
  &__select {
    background-color: transparent;
    color: $color-silver;
  }
  &__searchPage {
    background-color: transparent;
    color: $color-white;
  }
  &__searchPage::placeholder {
    color: $color-white;
  }
}

//General Styles for simple buttons with Icons
.btnIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
}
.btnIcon:active {
  transform: scale(1.1);
}
.btnIcon2 {
  display: flex;
  align-items: center;
  background: $color-white;
  border: none;
  gap: 3px;
}
.btnIcon2:active {
  transform: scale(1.1);
}
.arrowDwn {
  height: 1.3vh;
  padding-left: 2px;
  padding-right: 2px;
}
.dot {
  border-left: $color-silver solid 1px;
  padding: 0 1vw;
}
//Filter Modal
.modal {
  .form-check .form-check-input {
    width: 16px;
    height: 16px;
  }
}
//onClose() modal
.closeModal {
  background-color: transparent;
  border: none;
  border-radius: 100%;
  width: 2rem;
  height: 2rem;
  text-align: center;
  margin-top: -2rem;
  margin-right: -1vw;
  font-size: 1.5rem;
}
.closeModal:hover {
  background-color: $color-blueGray100;
}

//Grid to see reports in a diferent way
.mobile-grid {
  position: absolute;
  top: 19px;
  right: 16px;
  background-color: $color-paleGrey3;
  padding: 8px;
  width: 45px;
  height: 45px;
  border-radius: 6px;
  display: none;
}
//Effects to buttons
.--scale09:hover {
  scale: 0.9;
}
.--scale11:hover {
  scale: 1.1;
}
.--activate {
  background-color: $color-blueGray2;
  border: $color-blueGray solid 1px;
  color: $color-white;
}
// Dynamic theme for the table
.dark-mode .reports-table {
  .rdt_TableHead {
    color: $color-blueGray2;
  }
  .rdt_TableRow {
    color: $color-blueGray2;
  }
}
//Grid Section
.grid-block {
  width: 19%;
  margin-right: 1%;
  display: inline-block;
  vertical-align: top;
  margin-top: 1rem;
  .title {
    padding: 0.75rem;
    border: 1px solid $color-lightBlue2;
    font-size: 12px;
    line-height: 1;
  }
  .grid {
    .body {
      border: 1px solid $color-silver;
      border-radius: 4px;
    }
    .message-noti {
      svg {
        width: 1rem !important;
        height: 1rem !important;
        g {
          fill: $color-blueGray900;
          path:last-child {
            fill: $color-ethicsBlue;
          }
        }
      }
    }
    .monitor {
      width: 1.5rem !important;
      height: 1.5rem !important;
      padding: 5px;
      border-radius: 4px;
      g {
        fill: $color-white;
      }
    }
    .icon-block {
      img {
        width: 24px;
        height: 24px;
        padding: 4px;
        border-radius: 6px;
        background-color: $color-white;
        &.user-tick {
          background-color: $color-ethicsBlue;
        }
        &.alert {
          margin-bottom: 0;
          padding: 0;
        }
      }
    }
    &.warning {
      .body {
        border-bottom: 5px solid $color-yellow;
      }
      .monitor {
        background-color: $color-yellow;
      }
    }
    &.info {
      .body {
        border-bottom: 5px solid $color-ethicsBlue;
      }
      svg {
        background-color: $color-ethicsBlue;
      }
    }
    &.danger {
      .body {
        border-bottom: 5px solid $color-pink;
      }
      .monitor {
        background-color: $color-pink;
      }
    }
    &.success {
      .body {
        border-bottom: 5px solid $color-green;
      }
      .monitor {
        background-color: $color-green;
      }
    }
    &.teal {
      .body {
        border-bottom: 5px solid $color-teal;
      }
      .monitor {
        background-color: $color-teal;
      }
    }
    &.primary {
      .body {
        border-bottom: 5px solid $color-blue;
      }
      .monitor {
        background-color: $color-blue;
      }
    }
  }
}

.rdt_Table .indicator {
  border-radius: 0px 4px 4px 0px;
  width: 5px;
  height: 100%;
  position: absolute;
  left: -1rem;
}

.status-new-cell {
  position: relative;
}
.status-new-cell .indicator {
  background-color: #009ed772;
}
.status-closed-cell {
  position: relative;
}
.status-closed-cell .indicator {
  background-color: #ffe594;
}
.status-finish-cell {
  position: relative;
}
.status-finish-cell .indicator {
  background-color: #44cb6865;
}
.status-pending-cell {
  position: relative;
}
.status-pending-cell .indicator {
  background-color: #de453d7b;
}
.status-process-cell {
  position: relative;
}
.status-process-cell .indicator {
  background-color: #d81b6020;
}
.status-default-cell {
  position: relative;
}
.status-default-cell .indicator {
  background-color: $color-gray2;
}

#order-svg {
  cursor: pointer;

  svg {
    width: 1rem !important;
    height: 1rem !important;
    margin-left: 0.2rem;
  }
}

.orders {
  min-width: 7.5vw;
  position: relative;

  .dropdown-menu {
    position: absolute;
    top: 100%; /* Adjust as necessary */
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    list-style: none;
    background-color: #f9f9f9;
    border: 1px solid #ccc;

    li {
      font-size: 13px;
      font-weight: 500;
      color: #333;
      background-color: #f9f9f9;
      padding: 5px 10px;

      a {
        color: #555;
        text-decoration: none;

        &:hover {
          color: #009ed7;
        }
      }
    }
  }

  .dropdown-menu {
    display: block;
    position: absolute;
  }
}

.sub-header__settings {
  width: 80%;
}

//General Status Button
.btnStatus {
  min-width: 100px;
  padding: 3px;
  min-height: 30px;
  font-size: 11px !important;
  border-radius: 8px;
  color: $color-black;
  text-shadow: $color-blueGray500 0 0 1px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
  border: 0px solid #000;
}

//----- Status Buton Colors ------//
//--> Default <--//
.status-default {
  background-color: $color-gray2;
  border: 1px solid $color-blueGray2;
}
.dark-mode .status-default {
  background-color: $color-blueGray;
  color: $color-white;
  border: $color-darkBorder 1px solid;
  text-shadow: $color-black 0 0 1px;
}
//--> New <--//
.status-new {
  border: 2px solid $color-ethicsBlue;
  background-color: $color-lightBlue;
}
.dark-mode .status-new {
  // border: $color-ethicsBlue 1px solid;
  color: $color-paleBlue2;
  text-shadow: $color-blueGray700 0 0 1px;
}
//--> Finish <--//
.status-finish {
  border: 2px solid $color-yellow;
  background-color: $color-lightYellow;
}
.dark-mode .status-finish {
  color: $color-white;
  text-shadow: $color-black 0 0 2px;
}
//--> Pending <--//
.status-pending {
  border: 2px solid $color-purple;
  background-color: $color-paleGrey2;
}
.dark-mode .status-pending {
  color: $color-blueGray100;
  text-shadow: $color-black 0 0 5px;
}
//--> Process <--//
.status-process {
  border: 2px solid $color-green;
  background-color: $color-lightBackground;
}
.dark-mode .status-process {
  background-color: #d81b6094;
  // border: 1px solid #d81b60fc;
  color: $color-blueGray200;
  text-shadow: $color-black 0 0 1px;
}
//--> Close <--//
.status-closed {
  border: 2px solid $color-red;
  background-color: $color-palePink;
}
.dark-mode .status-closed {
  background-color: rgba(255, 175, 0, 0.6);
  color: $color-white;
  text-shadow: $color-blueGray900 0 0 2px;
}

// Styles icon
svg.icon-indicator {
  g {
    fill: $color-blueGray2;
  }
}

svg.icon-indicator:hover {
  g {
    fill: $color-ethicsBlue;
  }
}

svg.icon-indicator-is_severity {
  g {
    fill: red;
  }
}

@media screen and (max-width: 1200px) {
  .grid-block {
    width: 32.33%;
  }
}

@media screen and (max-width: 992px) {
  .grid-block {
    width: 49%;
  }
}

@media screen and (max-width: 576px) {
  .grid-block {
    width: 100%;
    margin-right: 0;
  }
  .mobile-grid {
    display: block;
  }
}
