@import "../../../theme/colors";

#settings {
  padding: 20px 30px 30px 60px;
  position: relative;
  flex-grow: 1;

  .access_links {
    .access_link:hover {
      color: $color-ethicsBlue;
    }
  }

  .nav-tabs {
    justify-content: space-between;
    padding: 0 1.5rem;
    // border: 1px solid #D3DBE1;
    border-radius: 6px;
    display: flex;
    overflow-x: auto;
    flex-direction: row;
    flex-wrap: nowrap;
    .nav-item {
      list-style: none;
      font-size: 14px;
      padding: 0;
      cursor: pointer;
      margin-bottom: 0;
      margin: 0;
      margin-right: 12px;
      text-align: -webkit-center;
      width: auto;
      &:nth-child(3) {
        min-width: 145px;
      }
      button {
        border: none;
        padding: 1rem;
        line-height: 1;
        margin: 0;
      }
      .nav-link {
        margin-bottom: 0;
      }
    }
  }
  .template-hero {
    width: 100%;
    height: 200px;
    background-image: url('../../../assets/images/template-hero.png');
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-white;
    border-radius: 6px;
  }
  .react-multi-carousel-list {
    .react-multi-carousel-track {
      padding-left: 0 !important;
      .card {
        height: 180px;
        margin: auto;
        width: 96%;
        text-align: center;
        border-radius: 4px;
        border: solid 1px $color-silver;
        background-color: transparent !important;
        &.carousel-hero {
          background-image: url("../../../assets/images/template-hero.png");
          background-size: cover;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $color-white;
          border-radius: 6px;
        }
        svg {
          margin: auto;
          width: 1.5rem !important;
          height: 1.5rem !important;
        }
      }
    }
  }
  #file-upload {
    margin: 0;
    .dropzone {
      padding: 1rem;
      #preview {
        margin-top: 1rem;
        width: 100%;
      }
    }
  }
  .dropdown-flags {
    width: 100% !important;
    height: 38px !important;
  }
  .setting-bar {
    min-height: 500px;
  }
  .table-icons {
    .icon {
      min-height: 24px;
      max-height: 24px;
      min-width: 24px;
      max-width: 24px;
      border-radius: 4px;
      transition: all 150ms ease;
      border: solid 1px transparent;
      &:hover {
        border: solid 1px $color-blueGray;
        background-color: $color-paleGrey3;
      }
    }
    svg {
      min-height: 16px;
      max-height: 16px;
      min-width: 16px;
      max-width: 16px;
    }
  }
  .settings-landing-upload-doc-btn {
    border-radius: 4px;
    border: solid 1px $color-lightBorder;
    padding: 4px;
    cursor: pointer;
    height: 100%;
    flex-grow: 1;
    &.is-invalid {
      border-color: $color-red !important;
    }
  }
}

.dark-mode #settings {
  .border-bottom {
    border-bottom: 1px solid $color-darkBorder !important;
  }
  h3 {
    color: $color-paleGrey;
  }
  p,
  h4,
  h5,
  h6 {
    color: $color-blueGray2;
  }
  .table-icons {
    .icon {
      &:hover {
        background-color: $color-charcoalGrey;
      }
    }
    svg {
      filter: brightness(0%) contrast(100%) invert(57%) sepia(9%) saturate(743%)
        hue-rotate(155deg) brightness(95%) contrast(89%);
    }
  }
  .form-control {
    background-color: #242836;
  }
  .border,
  .form-control,
  .react-datepicker-wrapper input {
    border: 1px solid $color-darkBorder;
    color: #b0bec5;
  }
  .form-control,
  .react-datepicker-wrapper input {
    &:hover {
      border: 1px solid #b3b3b3;
    }
  }
  .sub-header {
    border: 1px solid $color-darkBorder !important;
    .review-icon {
      .dropdown-menu ul li {
        color: $color-blueGray2;
      }
      .custom-dropdown {
        color: $color-blueGray2;
      }
    }
    svg g {
      fill: $color-blueGray2;
    }
  }
  .btn.athics-lignt-btn {
    background-color: transparent !important;
    color: $color-blueGray2 !important;
    border: 1px solid $color-darkBorder !important;
  }
  .btn.small-disable-btn {
    background-color: $color-dark !important;
  }
  .form-select option,
  select.form-control {
    background-color: $color-darkThree !important;
  }
  .accordion .accordion-item .accordion-button {
    background-color: $color-dark !important;
  }
  .accordion-button::after {
    filter: invert(1);
  }
  .assign-accordion .assign-check {
    background-color: $color-darkThree;
  }
  .btn.download-btn {
    color: $color-white !important;
    background-image: linear-gradient(
      to bottom,
      $color-dark,
      $color-darkTwo 120%
    ) !important;
  }
  .dropdown-flags + ul {
    background-color: $color-dark !important;
    li:hover {
      background-color: $color-darkThree;
    }
  }
  .form-check-input {
    background-color: $color-dark;
  }
  .rdt_Table {
    background-color: transparent;
    .rdt_TableHeadRow,
    .rdt_TableRow {
      background-color: transparent;
      color: $color-blueGray;
    }
    .rdt_TableRow:hover {
      background-color: $color-darkFour !important;
      border: 1px solid $color-darkFour;
    }
    .form-check input {
      background-color: $color-darkFour !important;
    }
  }
  .rdt_Pagination {
    background-color: $color-darkFour;
    color: $color-blueGray;
    svg {
      fill: $color-blueGray;
    }
  }
  .rdt_Table {
    background: transparent !important;
  }
  .grid-block .grid .icon-block img {
    background-color: transparent;
    border: 1px solid $color-ethicsBlue;
  }
  .mobile-grid {
    background-color: transparent;
  }
  .tree ul li {
    background-color: $color-dark;
  }
  .btnUpload {
    svg g {
      fill: $color-white;
    }
    &:hover {
      background-color: $color-darkFour;
      color: $color-white;
    }
  }
  .react-datepicker__month-container {
    background-color: $color-dark;
    .react-datepicker__header {
      background-color: $color-dark;
    }
    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header,
    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      color: $color-white;
    }
  }
  .form-select,
  .PhoneInput,
  .custom-search {
    border: 1px solid $color-darkBorder;
    color: $color-blueGray2;
  }
  .nav-tabs .nav-item .nav-link {
    color: $color-blueGray2 !important;
  }
  .react-multi-carousel-list {
    .react-multi-carousel-track {
      .card {
        border: solid 1px $color-darkBorder;
        background-color: transparent !important;
        &.carousel-hero {
          background-image: url("../../../assets/images/template-hero.png");
          background-size: cover;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $color-white;
          border-radius: 6px;
        }
        svg {
          margin: auto;
          width: 1.5rem !important;
          height: 1.5rem !important;
        }
      }
    }
  }
  .settings-landing-upload-doc-btn {
    border: solid 1px $color-darkBorder;
    svg {
      g {
        fill: $color-ethicsBlue;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  #settings {
    padding: 5px 14px 14px 14px;
    &.background {
      padding: 0;
    }
  }
}
