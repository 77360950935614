@import "../../../theme/colors";

.roles-page, .roles-page-modal-add {

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }
    .roles-list {
        ul {
            display: grid;
            grid-template-columns: 1fr;
            gap: 8px;
        }
        @media (min-width: 768px) {
            ul {
                grid-template-columns: repeat(2, 1fr);
            }
        }
        @media (min-width: 1200px) {
            ul {
                grid-template-columns: repeat(3, 1fr);
            }
        }
    }
    .role-list-item {
        font-size: 15px;
        border-radius: 4px;
        padding: 8px 16px;
        cursor: pointer;
        background-color: $color-blueGray50;
        border: 1px solid $color-lightBorder;
        h5 {
            margin: 0px;
            font-size: 16px;
            font-weight: 600;
        }
        &.modall {
            margin-top: 8px;
        }
    }

    .role-list-item.active {
        background-color: $color-paleBlue;
        border: 1px solid $color-lightBlue2;
    }

    .roles-groups {
        .accordion-body {
            margin-top: 0px;
            border: none;
            .accordion-container {
                padding: 16px 20px;
                border-radius: 4px;
            }
        }

        .accordion-item {
            background-color: $color-blueGray200 !important;
            .accordion-button {
                background-color: $color-blueGray200 !important;
            }
            .accordion-container {
                background-color: $color-blueGray50 !important;
            }
            .check_circle {
                path {
                    fill: $color-green;
                }
            }
            .cancel_circle {
                path {
                    fill: $color-red;
                }
            }
        }
    }

}