@import "../../theme/colors";

.close-icon {
  width: 16px;
  height: 16px;
  margin: -5px 0px 0px 4px;
  object-fit: contain;
  filter: invert(69%) sepia(18%) saturate(195%) hue-rotate(161deg)
    brightness(87%) contrast(83%);
}

.iconito {
  width: 16px;
  /* Ajusta el tamaño del ícono */
  height: 16px;
}

.searchIcon input {
  background: transparent !important;
}

.search-details {
  position: absolute;
  top: 100%;
  background-color: white;
  border: 1px solid #ccc;
  border-top: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 11;
  padding: 0px 20px 20px 20px;
  width: 50vw;
  margin-top: -7px;
}

.apply-filters-advanced {
  display: flex;
  margin-top: 23px !important;
  margin-left: 10px;
  min-height: 38px !important;
  width: 160px !important;
}

.search-details-searcher {
  position: absolute;
  top: 100%;
  width: 100%;
  background-color: white;
  border: 1px solid #ccc;
  border-top: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 11;
  padding: 0px 20px 20px 20px;
  width: 50vw;
  margin-top: -20px;
  // margin: -7px 20px 20px 20px;
}

.search-details-divider {
  border-top: 1px solid #b5c0c8;
  margin-bottom: 1px;
  max-width: 100% !important;
  min-width: 100% !important;
}

.search-details-section {
  margin-top: 20px;
}

.search-details-section-searcher {
  margin-top: 20px;
}

.search-details-section h3 {
  margin-bottom: 10px;
  font-size: 18px;
}

.search-details-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #ccc;
}

.search-details-content {
  display: flex;
  padding: 10px !important;
  margin-top: -15px;
  margin-right: 20px;
}

.search-details-rows {
  flex-direction: column;
  flex: 2;
  margin-left: -20px;
  margin-right: -20px;
}

.search-details-rows p {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.search-details-column-icon {
  margin-right: 30px !important;
  min-width: 35px !important;
  display: flex;
  align-items: center;
  margin-top: 8px;
  padding: 5px;
  border-radius: 7px;
  background-color: $color-Gray;

  g {
    fill: $color-white !important;
  }
}

.search-details-column-iconnew {
  margin-right: 35px !important;
  min-width: 35px !important;
  // display: flex;
  align-items: center;
  margin-top: 8px;
  padding: 5px;
  border-radius: 7px;
  max-width: 32px !important;
  background-color: $color-blue;

  g {
    fill: $color-white !important;
  }
}

.search-details-column-icondraft {
  margin-right: 35px !important;
  min-width: 35px !important;
  // display: flex;
  align-items: center;
  margin-top: 8px;
  padding: 5px;
  border-radius: 7px;
  background-color: $color-Gray;
  max-width: 32px !important;

  g {
    fill: $color-white !important;
  }
}

.search-details-column-iconpending {
  margin-right: 35px !important;
  min-width: 35px !important;
  // display: flex;
  align-items: center;
  margin-top: 8px;
  padding: 5px;
  border-radius: 7px;
  max-width: 32px !important;
  background-color: $color-purple;

  g {
    fill: $color-white !important;
  }
}

.search-details-column-iconprocess {
  margin-right: 35px !important;
  min-width: 35px !important;
  // display: flex;
  align-items: center;
  margin-top: 8px;
  padding: 5px;
  border-radius: 7px;
  max-width: 32px !important;
  background-color: $color-green;

  g {
    fill: $color-white !important;
  }
}

.search-details-column-iconfinish {
  margin-right: 35px !important;
  min-width: 35px !important;
  // display: flex;
  align-items: center;
  margin-top: 8px;
  padding: 5px;
  border-radius: 7px;
  max-width: 32px !important;
  background-color: $color-yellow;

  g {
    fill: $color-white !important;
  }
}

.search-details-column-iconclosed {
  margin-right: 35px !important;
  min-width: 35px !important;
  // display: inline-block;
  align-items: center;
  margin-top: 8px;
  padding: 5px;
  border-radius: 7px;
  max-width: 32px !important;
  background-color: $color-red;

  g {
    fill: $color-white !important;
  }
}

.search-details-column-icon-advancednew {
  margin-right: 65px !important;
  display: inline-block;
  align-items: center;
  margin-top: 8px;
  padding: 5px;
  border-radius: 7px;
  max-width: 32px !important;
  background-color: $color-blue;

  g {
    fill: $color-white !important;
  }
}

.search-details-column-icon-advanceddraft {
  margin-right: 65px !important;
  display: inline-block;
  align-items: center;
  margin-top: 8px;
  padding: 5px;
  border-radius: 7px;
  background-color: $color-Gray;
  max-width: 32px !important;

  g {
    fill: $color-white !important;
  }
}

.search-details-column-icon-advancedpending {
  margin-right: 65px !important;
  display: inline-block;
  align-items: center;
  margin-top: 8px;
  padding: 5px;
  border-radius: 7px;
  max-width: 32px !important;
  background-color: $color-purple;

  g {
    fill: $color-white !important;
  }
}

.search-details-column-icon-advancedprocess {
  margin-right: 65px !important;
  display: inline-block;
  align-items: center;
  margin-top: 8px;
  padding: 5px;
  border-radius: 7px;
  background-color: $color-green;
  max-width: 32px !important;

  g {
    fill: $color-white !important;
  }
}

.search-details-column-icon-advancedfinish {
  margin-right: 65px !important;
  display: inline-block;
  align-items: center;
  margin-top: 8px;
  padding: 5px;
  border-radius: 7px;
  background-color: $color-yellow;
  max-width: 32px !important;

  g {
    fill: $color-white !important;
  }
}

.search-details-column-icon-advancedclosed {
  margin-right: 65px !important;
  display: inline-block;
  align-items: center;
  margin-top: 8px;
  padding: 5px;
  border-radius: 7px;
  background-color: $color-red;
  max-width: 32px !important;

  g {
    fill: $color-white !important;
  }
}

.search-details-column-folio {
  display: flex;
  align-items: center;
  padding: 10px;
}

// .search-details-column-indicators {
//   display: flex;
//   flex-wrap: wrap;
//   align-items: center;
// }

// .search-details-column-indicators > * {
//   width: calc(50% - 5px); /* Divide en 2 columnas con espacio entre ellas */
// }

.search-details-column-indicators {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: -2px; /* Elimina el espacio entre los elementos */
  // border: 1px solid #ccc; /* Borde opcional para el recuadro */
  padding: 1px; /* Espaciado interno opcional */
}

.search-details-column-indicators > * {
  width: 50%; /* 2 columnas exactas */
  box-sizing: border-box; /* Evita que el padding o el borde afecten el tamaño */
  // border: 1px solid #ddd; /* Borde opcional para separar indicadores */
  padding: 2px; /* Espaciado interno para cada indicador */
  text-align: center; /* Centra el contenido dentro de cada indicador */
}

.search-details-column-folio p {
  margin: -10px 0 0 -10px !important;
  font-size: 16px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: normal;
}

.search-details-column {
  flex: 1;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.search-details-column-subject p {
  margin: 5px 0 0 -40%;
  font-size: 16px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: normal;
}

.search-details-column-tracking p {
  margin: 0 0 0 5px;
  font-size: 16px;
  // text-align: left;
  // white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  // max-width: 100%!important;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: normal;
}

.search-details-row {
  border-bottom: 1px solid #b5c0c8 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.search-details-row:hover {
  background-color: #f0f0f0;
  transform: scale(1.03);
  transition: transform 0.3s;
}

.vertical-line {
  border-left: 1px solid #b5c0c8;
  margin-left: -20px;
}

.section {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  /* Alineación en columna */
  margin-bottom: 20px;
  margin-right: -20px;
}

.Col {
  display: flex;
  flex-direction: column;
}

.entry-medium h6 {
  align-items: right;
  margin-left: 12px;
}

.entry-medium p {
  font-size: 14px;
}

.entry-medium {
  margin-top: 10px;
}

.entry-label {
  margin-bottom: 0;
  padding-bottom: 4px;
}

.entry-medium select {
  width: 100%;
  padding: 8px;
  font-size: 14px;
}

.status {
  margin-top: 30px;
}

.status p {
  font-size: 14px;
}

.status-label {
  margin-bottom: 0;
  padding-bottom: 4px;
}

.entryway {
  margin-top: 15px;
}

.severity {
  margin-top: 5px;
}

.created-to {
  margin-top: 10px;
  margin-left: 8px;
}

.created-from {
  margin-top: 10px;
  margin-left: 8px;
}

.col-0-5 {
  flex: 0 0 4.167%;
  max-width: 4.167%;
}

.custom-searchBiggest {
  width: 50vw;
  height: 38px;
  margin: 3px 0px 3px 0;
  padding: 7px 25px 7px 8px;
  border-radius: 4px;
  border: solid 1px #009ed7;
  background-color: white;
  box-shadow: 0px 1px 0px #dee1e4;
  transition: opacity 0.5s ease;

  .custom-search {
    width: 100%;
    max-width: 360px;
    height: 38px;
    margin: 3px 20px 3px 0;
    padding: 7px 25px 7px 8px;
    border-radius: 4px;
    border: solid 1px #dee1e4;
    background-color: white;

    .searchsInputs {
      width: 100%;
      height: 9px;
      margin: 8px 0 7px 8px;
      font-family: SegoeUI;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.75;
      letter-spacing: normal;
      color: #6a6a6a;
    }
  }

  #header-search {
    background-color: transparent !important;
    color: inherit !important;
  }
}

.select-container {
  position: relative;
  width: 100%;
}

.select-container .floating-label {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  font-size: 14px;
  color: #999;
  pointer-events: none;
  transition: all 0.3s ease;
}

.select-container .floating-label.active {
  top: -10px;
  left: 5px;
  font-size: 12px;
  color: #009ed7;
}

.scroll-container {
  max-height: 800px;
  overflow-y: hidden;
  overflow-x: hidden;
  padding-right: 15px;
}

.custom-datepicker {
  position: relative;
  // min-height: 300px !important;
  max-height: 400px !important;
}

.custom-datepicker .react-datepicker__tab-loop {
  z-index: 10;
}

.table-results {
  margin-top: -14px !important;
  margin-left: -33px !important;
  font-size: 3px !important;
}

.custom-form-control-label {
  margin-bottom: -15px !important;
  margin-left: 0px !important;
}

.custom-date-picker .MuiInputBase-root {
  height: 38px !important;
  margin-top: 13px !important;
  min-width: 100% !important;
}

.custom-date-picker input {
  margin-top: 0px !important;
  height: 13px !important;
  padding: 15px 14px !important;
}

.custom-date-picker label {
  padding: 5px 0px !important;
  margin-top: 9px !important;
}

.modal-content {
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  overflow: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.col {
  flex: 1;
  min-width: 0;
}

.datepickers,
.catalog,
.subcatalog,
.apply-filters {
  width: 100%;
  box-sizing: border-box;
}

.catalog {
  display: flex;
  flex-direction: column;
  // margin-top: 14px;
  width: 100%;
  min-height: 38px !important;
  max-height: 38px !important;
}

.subcatalog {
  display: flex;
  // flex-wrap: wrap;
  flex-direction: column;
  margin-top: 15px;
  // margin-left: 8px;
  width: 100%;
}

.apply-filters {
  display: flex;
  margin-top: 20px;
  margin-left: 10px;
  min-height: 38px !important;
  // max-height: 38px !important;
  min-width: 50% !important;
  max-width: 100% !important;
}

.search-details-column-subject-container {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  height: auto;
  max-height: 17em;
  text-align: left;
  line-height: 7em;
}

.search-details-column-subject-container-advanced {
  text-align: center !important;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  height: 8em;
  margin-right: 15px;
  height: auto;
  max-height: 17em;
  text-align: left;
  line-height: 7em;
  margin-top: 10px;
}

/* Estilos para pantallas medianas (tablets) */
@media screen and (max-width: 768px) {
  .input-search {
    display: none;
  }

  .custom-search {
    height: 38px;
    border: none !important;
    background-color: #f3f7fa !important;
    box-shadow: none !important;
    outline: none !important;
    margin-right: -55px !important;
    margin-top: -5px !important;
  }

  .iconito {
    width: 24px;
    height: 24px;
  }

  .apply-filters {
    margin-top: 15px;
    margin-left: 8px;
    min-height: 60px !important;
    max-height: 60px !important;
    min-width: 100% !important;
    max-width: 100% !important;
  }

  .custom-searchBiggest {
    position: fixed;
    top: 8px;
    left: 20px;
    background-color: #fff;
    z-index: 12;
    width: 95vw !important;
  }

  .search-details {
    position: fixed;
    top: 8px;
    left: 20px !important;
    background-color: white;
    border: 1px solid #ccc;
    border-top: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 11;
    padding: 0px 20px 20px 20px;
    margin-top: 10px !important;
    margin-left: -418px;
    width: 95vw !important;
  }

  .search-details-searcher {
    // position: absolute;
    top: 100%;
    width: 95vw !important;
    background-color: white;
    border: 1px solid #ccc;
    border-top: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 11;
    padding: 0px 20px 20px 20px;
    margin-top: -20px;
    // margin: -7px 20px 20px 20px;
  }

  .clasesitadeprueba {
    height: 300px;
  }

  .search-details-row {
    border-bottom: 1px solid #b5c0c8;
    width: 100%;
  }

  .search-details-column-icon {
    margin-right: 29px;
    display: flex;
    align-items: center;
    margin-top: 8px;
    padding: 5px;
    border-radius: 7px;
    background-color: $color-Gray;

    g {
      fill: $color-white !important;
    }
  }

  .search-details-column-iconnew {
    margin-right: 12px;
    min-width: 38px;
    max-width: 38px !important;
    display: flex;
    align-items: center;
    margin-top: 8px;
    padding: 5px;
    border-radius: 7px;
    max-width: 32px !important;
    max-width: 32px !important;
    background-color: $color-blue;

    g {
      fill: $color-white !important;
    }
  }

  .search-details-column-icondraft {
    margin-right: 12px;
    min-width: 38px;
    max-width: 38px !important;
    display: flex;
    align-items: center;
    margin-top: 8px;
    padding: 5px;
    border-radius: 7px;
    background-color: $color-Gray;

    g {
      fill: $color-white !important;
    }
  }

  // .search-details-column-iconpending {
  //   margin-right: 12px;
  //   min-width: 38px;
  //   // max-width: 38px !important;
  //   // display: flex;
  //   align-items: center;
  //   margin-top: 8px;
  //   padding: 5px;
  //   border-radius: 7px;
  //   background-color: $color-purple;
  //   g {
  //     fill: $color-white !important;
  //   }
  // }

  .search-details-column-iconpending {
    min-width: 38px;
    max-width: 38px !important;
    // display: flex;
    align-items: center;
    margin-top: 8px;
    padding: 5px;
    // border-radius: 7px;
    background-color: $color-purple;

    g {
      fill: $color-white !important;
    }
  }

  .search-details-column-iconprocess {
    margin-right: 12px;
    min-width: 38px;
    max-width: 38px !important;
    display: flex;
    align-items: center;
    margin-top: 8px;
    padding: 5px;
    border-radius: 7px;
    background-color: $color-green;

    g {
      fill: $color-white !important;
    }
  }

  .search-details-column-iconfinish {
    margin-right: 12px;
    min-width: 38px;
    max-width: 38px !important;
    display: flex;
    align-items: center;
    margin-top: 8px;
    padding: 5px;
    border-radius: 7px;
    background-color: $color-yellow;

    g {
      fill: $color-white !important;
    }
  }

  // .search-details-column-iconfinish {
  //   margin-right: 65px !important;
  //   display: inline-block;
  //   align-items: center;
  //   margin-top: 8px;
  //   padding: 5px;
  //   border-radius: 7px;
  //   background-color: $color-yellow;

  //   g {
  //     fill: $color-white !important;
  //   }
  // }

  .search-details-column-iconclosed {
    min-width: 38px;
    max-width: 38px !important;
    margin-right: 20px;
    display: flex;
    align-items: center;
    margin-top: 8px;
    padding: 5px;
    border-radius: 7px;
    background-color: $color-red;

    g {
      fill: $color-white !important;
    }
  }

  .search-details-row .col {
    flex: 1;
  }

  .search-details-row .col-3 {
    order: 3;
  }

  .search-details-row:hover {
    background-color: #f0f0f0;
    transform: scale(1.03);
    transition: transform 0.3s;
  }

  .search-details-column-folio {
    display: flex;
    align-items: center;
    padding: 1px;
    margin-left: -5px;
    gap: 5px;
  }

  .search-details-column-subject-container {
    text-align: center !important;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    height: 8em;
    margin-right: 15px;
    height: auto;
    max-height: 17em;
    text-align: left;
    line-height: 7em;
    margin-top: -5px;
  }

  .search-details-column-tracking p {
    margin: 0 0 0 5px !important;
    font-size: 16px;
    text-align: right !important;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-weight: normal;
  }
}

@media screen and (max-width: 767px) {
  .search-details {
    position: fixed;
    top: 8px;
    left: 20px !important;
    background-color: white;
    border: 1px solid #ccc;
    border-top: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 11;
    padding: 0px 20px 20px 20px;
    margin-top: 10px !important;
    margin-left: -30px;
    width: 95vw !important;
  }
}

/* Estilos para pantallas pequeñas (móviles) */
@media (max-width: 480px) {
  .apply-filters {
    margin-top: 10px;
    margin-left: 5px;
    min-height: 70px !important;
    max-height: 70px !important;
    min-width: 100% !important;
    max-width: 100% !important;
  }
}

.pruebita {
  margin-bottom: 20px;
  margin-left: -30px !important;
}

.tabs-class {
  margin-left: -10px !important;
  margin-top: -20px;
}

.icon-indicator {
  margin-left: 0;

  g {
    fill: #fff;
  }
}

.icon-indicator-advanced {
  margin-left: -1px;

  g {
    fill: #fff;
  }
}

.bordePrueba {
  border-left: 1px solid #b5c0c8 !important;
}
