@import "../../../theme/colors";

.supportUpload {
  position: relative;
  min-width: 16vw;
  min-height: 10vh;
  .supportUploader {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column wrap;
    padding: 10px;
    border-radius: 6px;
    border: $color-silver 3px dotted;
    cursor: pointer;
    &__icon {
      g {
        fill: $color-blueGray2;
      }
    }
    &__subtitle {
      color: $color-blueGray2;
    }
    &__file {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: column wrap;
      padding: 10px;
      border-radius: 6px;
      border: $color-ethicsBlue 3px dotted;
    }
    &__fileReady {
      position: relative;
    }
    &__fileTick {
      position: absolute;
      bottom: -0.6vh;
      right: -0.5vw;
      scale: 0.6;
      border-radius: 100%;
      background-color: black;
      path {
        fill: $color-green;
      }
    }
    &__delete {
      scale: 0.65;
      border: $color-red solid 2px;
      border-radius: 50%;
      padding: 5px;
      background-color: $color-palePink;
      position: absolute;
      top: -15px;
      right: -15px;
      g {
        fill: $color-red;
      }
    }
    &__fileIcon {
      g {
        fill: $color-ethicsBlue;
      }
    }
    &__fileSubtitle {
      text-align: center;
      color: $color-ethicsBlue;
      opacity: 0.8;
    }
    &.drag-over {
      border: $color-green dotted 3px;
      opacity: 0.8;
      code {
        color: $color-green;
      }
      g {
        fill: $color-green;
      }
      animation: buzz 4s infinite;
    }
  }
}
.--error {
  color: $color-red;
  font-size: 12px;
}

@keyframes buzz {
  0% {
    -webkit-transform: rotateZ(-2deg);
  }
  33% {
    -webkit-transform: rotateZ(0deg) scale(0.9);
  }
  66% {
    -webkit-transform: rotateZ(2deg);
  }
  100% {
    -webkit-transform: rotateZ(-1deg) scale(0.9);
  }
}

// from SupportFilesReader
.supportFilesReaders {
  display: flex;
  align-items: start;
  flex-flow: column;
  max-height: 22.3vh;
  min-height: 15.5vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 5px;
  width: 235px;
  border-top: dotted 1px $color-gunmetal;
  &__nothing {
    margin: 1vh auto;
  }
  &__file {
    position: relative;
    border: 1px solid $color-silver;
    border-radius: 4px;
    margin-bottom: 0.5vh;
    margin-top: 0.5vh;
    width: 215px;
    height: 30px;
    padding: 1px 40px 1px 1px;
  }
  &__viewer {
    border: transparent;
    margin: auto 0;
    width: 100%;
    height: 100%;
    text-align: start;
    font-size: 0.8rem;
    border-right: 1px solid $color-black;
    background-color: transparent;
  }
  &__viewer:hover {
    background-color: $color-silver2;
  }
  &__delete {
    position: absolute;
    top: 0;
    right: 2px;
    scale: 0.6;
    background-color: transparent;
    border: none;
    g {
      path {
        fill: $color-red;
      }
    }
  }
  &__delete:hover {
    background-color: red;
    border-radius: 100%;
    scale: 0.7;
    g {
      path {
        fill: $color-white;
      }
    }
  }
}

.--noToggled {
  transition: transform 400ms ease-in-out;
  filter: invert(98%) sepia(1%) saturate(6575%) hue-rotate(180deg)
    brightness(88%) contrast(96%);
}
.--toggled {
  transform: rotate(180deg);
  transition: transform 400ms ease-in-out;
  filter: invert(98%) sepia(1%) saturate(6575%) hue-rotate(180deg)
    brightness(88%) contrast(96%);
}
