@import "../../../theme/colors";



.buttons{
display: flex;
align-items: center;
justify-content: space-around;
flex-flow: row nowrap;
gap: 5px;
}


.graphic-content {
    border-radius: 5px;
    margin-bottom: 20px;
    background-color: white;

    &.light {
        border: solid 1px $color-paleGrey2;
    }

    &.dark {
        border: solid 1px $color-blueGray2;
        color: $color-blueGray2;
    }

    .subtitle {
        font-size: 14px;
        font-size: 16px !important;
        font-weight: 600 !important;
        color: #263238 !important;
    }
}

.graphic-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;

        border-bottom: solid 2px $color-paleGrey2;


    .options {
        display: flex;
        justify-content: end;
        align-items: center;

        .maximize-button {
            background-color: #fff;
            border: #fff;
        }

        .form-select.form-select-sm {
            color: $color-blueGray2 !important;
            cursor: pointer;
        }

        &.light {
            .form-select.form-select-sm {
                border: solid 0px $color-paleGrey2 !important;
            }

            .form-select.form-select-sm:hover {
                border: solid 1px $color-paleGrey2 !important;
            }
        }

        &.dark {
            .form-select.form-select-sm {
                border: solid 0px $color-blueGray2 !important;
            }

            .form-select.form-select-sm:hover {
                border: solid 1px $color-blueGray2 !important;
            }
        }
    }



}



.graphic-options {
    &.dark {
        .dropdown-menu.show {
            position: absolute;
            inset: 0px auto auto 0px;
            transform: translate3d(851.5px, 264.5px, 0px);
            background-color: $color-darkThree;
            border: solid 1px $color-blueGray2;
            color: $color-blueGray2;
        }
    }
}

.graphic-options:hover {
    border-radius: 5px;
    max-width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.light {
        border: solid 1px $color-paleGrey2;
    }

    &.dark {
        border: solid 1px $color-blueGray2;
    }
}

.graphic-container {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    position: relative;
    margin: 1rem;
    max-width: 100%;
    max-height: 2000px;
}

.graphic-body {

    position: relative;

   


    .graphic-indicators {
        min-height: 100%;
        // display: flex;
        // justify-content: center;
        // align-items: center;
    }


    .graphic-element {
        min-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        canvas {
            width: 600px;
        }
    }



}

@media (max-width: 991px) {
 

    .graphic-container {
        // display: flex;
        // justify-content: center;
        // align-items: center;
        position: relative;
        margin: 1rem;
        max-width: 100%;
        max-height: 6000px !important;
        min-height: 100% !important;
    }
}

@media screen and (max-width: 1100px) {

    .graphic-element {
        canvas {
            width: 100% !important;
        }

        min-height: 250px;
        animation: showComponent 0.6s forwards;
    }
}

// Animation
.center-body {
    position: absolute;
    width: 80px;
    left: 50%;
    margin-left: -40px;
    z-index: 2;
    opacity: 0;
    animation: hideComponent 0.3s forwards;
    min-height: 250px;
}

.show-loading .graphic-body {
    opacity: 0;
    animation: hideComponent 0.3s;
}

.show-loading .center-body {
    opacity: 1;
    animation: showComponent 0.6s;
}


.graphic-indicators {
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}



.graphic-element {
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/*
Animation to hide component
*/
@keyframes hideComponent {
    0% {
        opacity: 1;
    }

    20% {
        opacity: .8;
    }

    40% {
        opacity: .6;
    }

    60% {
        opacity: .4;
    }

    80% {
        opacity: .2;
    }

    100% {
        opacity: 0;
        display: none;
    }
}

/*
Animation to show component
*/
@keyframes showComponent {
    0% {
        display: flex;
        opacity: 0;
    }

    20% {
        opacity: .2;
    }

    40% {
        opacity: .4;
    }

    60% {
        opacity: .6;
    }

    80% {
        opacity: .8;
    }

    100% {
        opacity: 1;
    }
}


.add-dashboard-default-graphics {
    display: flex;
    flex-direction: row nowrap ;
    align-items: flex-end ;
    justify-content: space-around;
    gap: 5px;

}

