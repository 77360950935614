@import "../../../theme/colors";

.form-list {
  background-color: #f3f7fa;
  .main-container {
    padding: 24px;
    border-radius: 4px;
  }

  // contenedor de ambos formularios
  .forms-own-eg {
    margin-top: 24px;
    padding: 16px;
    border: 1px solid #dee2e6;
    border-radius: 4px;
  }
  // cada item de la lista de mis formularios
  // el item de nuevo formulario
  .form-list-item {
    .form-list-item-square {
      position: static;
      border: 1px dashed #bdcad0;
      border-radius: 4px;
      width: 100px;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white;
      cursor: pointer;
      svg {
        filter: brightness(0) saturate(100%) invert(78%) sepia(4%)
          saturate(1244%) hue-rotate(154deg) brightness(80%) contrast(88%);
      }
    }
  }
  // el item cuando un formulario existe
  .form-list-item.exist {
    margin-left: 24px;
    margin-right: 24px;
    .form-list-item-square {
      position: relative;
      flex-direction: column;
      border: 1px solid #31c7fd;
      background-color: #09a6de;
      svg {
        filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%)
          hue-rotate(49deg) brightness(103%) contrast(103%);
      }
    }
  }
  // icono de more en cada item
  .dropdown {
    position: absolute;
    bottom: 0;
    right: 0;
    .dropdown-toggle {
      padding: 0 6px;
    }
    .dropdown-menu {
      border-radius: 4px;
      .dropdown-item {
        // padding: 0.25rem;
        font-size: 16px;
        // margin: 0.3rem 0;
        svg {
          // width: 24px;
          // height: 24px;
          filter: none;
        }
        svg g {
          fill: $color-ethicsBlue !important;
        }
      }
    }
  }

  .own-forms {
    // background-color: #F0F2F4;
    border-radius: 4px;
    padding: 16px;
  }
  .own-forms-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px !important;
  }
  .own-form-list {
    display: flex;
    overflow-x: hidden;
    overflow-y: visible;
    padding-bottom: 250px;
    margin-bottom: -250px;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .own-form-new {
    .own-form-new-container {
      display: flex;
      align-items: center;
    }
    .own-form-new-border {
      margin-left: 24px;
      border-radius: 1px;
      background-color: #bdcad0;
      margin-bottom: 24px;
      height: 60px;
      width: 4px;
      max-height: 50px;
      max-width: 1px;
    }
  }
  // tabla para los formularios de ethicsglobal
  .table-border {
    border-radius: 8px !important;
    overflow: hidden;
  }
  .custom-table {
    thead {
      background-color: #009ed7;
      color: white;
    }
    th {
      text-align: center;
      font-weight: 600;
    }
    td {
      text-align: center;
      .custom-table-td {
        min-height: 100%;
        min-width: 100%;
      }
    }
  }
  .table-striped > tbody > tr:nth-of-type(odd) > * {
    background-color: #00b3ff0d !important;
  }
}

.dark-mode .form-list {
  background-color: #141319;
  .own-forms {
    background-color: #312d42;
    border-radius: 4px;
    padding: 16px;
  }
  .table-striped > tbody > tr > * {
    color: $color-blueGray2;
  }
  .table-striped > tbody > tr:nth-of-type(odd) > * {
    background-color: $color-darkBackground !important;
  }
}

.modal-shadow {
  box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.112);
  border-radius: 16px;
}

.containerFormName {
  max-width: 100px;
  overflow: hidden;
  .formName {
    width: 96%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
  }
  .formName::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 20px;
    height: 100%;
    background: linear-gradient(to right, transparent, white);
    pointer-events: none;
  }
}
