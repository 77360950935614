@import "../../../../theme/colors";

// From ./TextBubble.jsx
.textBubble {
  display: flex;
  align-items: center;
}
.chatBox {
  position: relative;
  display: flex;
  flex-flow: column;
  margin: 0.45rem 0.1rem;
  padding: 0.3rem 0.7rem;
  min-width: 30%;
  max-width: 70%;
  svg,
  img {
    width: 1rem;
    height: 1rem;
  }
  small {
    padding: 1px 10px;
  }
  &__titular {
    display: flex;
    align-items: center;
    h6 {
      padding: 3px 5px;
      font-size: 0.75rem !important;
      overflow: hidden;
      transition: opacity 0.3s ease;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin: auto 0;
    }
  }
  &__message {
    white-space: pre-wrap;
    .directMsg {
      border-top: 1px solid $color-blueGray200;
      position: relative;
      padding: 5px 10px;
      h6 {
        font-size: 0.7rem !important;
      }
      .contact-list {
        width: 100%;
        padding: 0 10px;
        border-left: 4px solid $color-green;
        background-color: $color-blueGray100;
        border-radius: 5px 2px 2px 5px;
        box-shadow: 3px 2px 6px -2px $color-blueGray500;
        margin: 3px auto 5px;
        list-style: inside;
        &.scroll {
          max-height: 300px;
          overflow-y: auto;
        }
        .show-more {
          background-color: transparent;
          border: none;
          text-decoration: underline;
          text-decoration-color: $color-ethicsBlue;
          color: $color-ethicsBlue;
          width: 100%;
          text-align: end;
        }
      }
    }
  }

  &__delete {
    cursor: pointer;
    border:0px solid #000;
    svg{ 
      fill: red;
    }
  }

  &__dataSend {
    display: flex;
    align-items: center;
    flex-flow: row;
    gap: 0.5vw;
    opacity: 0.6;
    overflow: hidden;
    transition: opacity 0.3s ease;
    text-overflow: ellipsis;
    white-space: nowrap;
    p {
      margin: 0;
      font-size: 0.6rem;
    }
    svg {
      scale: 0.8;
    }
  }
  &.--send {
    border-radius: 12px 12px 0 12px;
    box-shadow: $color-blueGray500 3px 2px 1px 1px;
    margin-right: 3rem;
    background-color: $color-paleGrey;
  }
  &.--send::after {
    position: absolute;
    bottom: -3px;
    right: -11px;
    content: "";
    border-top: 10px solid transparent;
    border-left: 1px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 15px solid transparent;
    border-bottom-color: $color-blueGray500;
  }
  &.--receive {
    border-radius: 12px 12px 12px 0;
    box-shadow: $color-blueGray500 -3px 2px 1px 1px;
    margin-left: 3rem;
    background-color: $color-blueGray50;
  }
  &.--receive::after {
    position: absolute;
    bottom: -3px;
    left: -11px;
    content: "";
    border-top: 10px solid transparent;
    border-left: 10px solid transparent;
    border-right: 1px solid transparent;
    border-bottom: 15px solid transparent;
    border-bottom-color: $color-blueGray500;
  }
  &__reply {
    g {
      fill: $color-blueGray500;
    }
    background-color: transparent;
    border: none;
    opacity: 0.01;
    .textBubble:hover &,
    &.chatBox__reply:hover {
      transition: 1s ease-out;
      opacity: 1;
    }
  }
}
// Dark-mode
.dark-mode .chatBox {
  .chat {
    border: 1px solid $color-darkBorder;
  }
  &.send {
    margin-left: auto;
    .chat {
      background-color: $color-darkFour;
    }
  }
  &.receive {
    margin-right: auto;
    .chat {
      background-color: $color-darkBackground;
    }
  }
}
// from ./Chat.jsx
.chat {
  height: 53vh;
  display: flex;
  flex-flow: column;
  &__title {
    height: auto;
  }
  &__messages {
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-flow: column-reverse;
    overflow-x: hidden;
  }
  .replyTo {
    border-top: 1px solid $color-blueGray200;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.6vh 1.5vw;
    &__contacts {
      max-width: 85%;
      padding: 3px 10px;
      border-left: 5px solid $color-ethicsBlue;
      border-radius: 5px 0 0 5px;
      small {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: opacity 0.3s ease;
      }
    }
    &__cancelMention {
      border-left: 1px dotted $color-silver;
      padding: 0 5px;
    }
  }
  .sendTo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.1vh 0.5vw;
    flex-flow: row wrap;
    border-top: 1px solid $color-blueGray200;
    padding: 0.5vh 1vw;
    &__title {
      display: flex;
      align-items: center;
    }
    &__contacts {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      button {
        padding: 0;
      }
    }
    &__at {
      color: $color-cobalt;
      text-decoration: none;
    }
    &__mentionName {
      text-decoration: underline;
      color: $color-ethicsBlue;
    }
  }
  //Dropdown Menu by Bootstrap (Contact List)
  .menuContacts {
    &__container {
      padding: 0;
    }
    &__title {
      position: sticky;
      top: 0;
      padding: 10px 20px;
      z-index: 1;
      background-color: $color-lightBackground;
      border-bottom: $color-blueGray200 1px solid;
      font-weight: 700;
    }
    &__option {
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: start;
      gap: 0.6vw;
      margin: 0.1vh auto;
      max-width: 93.3%;
      padding: 10px 15px;
      box-shadow: 0 0 4px -2px $color-silver;
      div {
        h6 {
          margin: 0;
        }
        small {
          width: 25vh;
          font-size: 0.75rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          transition: opacity 0.3s ease;
        }
      }
    }
  }
  &__sendMessage {
    border-top: 1px solid $color-blueGray200;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    textarea {
      width: 80%;
      margin: auto;
      border-radius: 12px;
      border: solid 1px $color-blueGray500;
      padding: 9px 15px;
      max-height: 16vh;
    }
  }
  &__paperPlane {
    opacity: 0.85;
    margin: 0 auto;
    height: 35px;
    width: 35px;
    border: none;
    border-radius: 50%;
    background-color: $color-green;
    g {
      fill: $color-white;
    }
  }
  .--disabled {
    opacity: 0.5;
    background: none;
    g {
      fill: gray;
    }
  }
  .--disabled:hover {
    background-color: transparent;
    opacity: 0.5;
  }
  &__paperPlane:hover {
    background-color: $color-green;
    opacity: 1;
  }
  .--noMessages {
    display: flex;
    align-items: center;
    flex-flow: column;
    margin: auto;
  }
}
//Custom toggle & menu inside Bootstrap Dropdown
.ethicsContact {
  &__header {
    padding: 12px 20px;
    border-radius: 4px 4px 0 0;
    background-color: $color-lightBackground;
    border-bottom: $color-blueGray200 1px solid;
    margin: 0 auto;
  }
  &__list-contact {
    max-height: 37.3vh;
    min-width: 30vw;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0;
    margin: 0 auto;
  }
  &__noContacts {
    padding: 12px;
  }
  &__footer {
    border-top: $color-blueGray200 1px solid;
    position: relative;
    .searchInput {
      margin: 5px auto;
      max-width: 70%;
      font-size: 0.85rem !important;
    }
  }
}
.--button {
  background-color: transparent;
  border: none;
}
.--cancel {
  path {
    fill: $color-red;
  }
}
