@import "../../../theme/colors";


.normal {
    font-weight: lighter;
    font-size: medium;
}

.cursor {
    cursor: pointer;
}

.template_list {
    list-style: none;
    margin: 0;
    padding-top: 0.5px;
    width: 100%;

    .field_info {
        display: flex;
        flex-direction: column;
        margin: 12px 0 24px 0;
        padding-top: 0.5px;
    }

    .field-rectangle {
        padding: 18px 12px;
        width: min(100%, 520px);
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
    }

    .is_sensitive {
        background-color: $color-yellowV;
    }

    .has_catalog {
        background-color: $color-paleBlue2;
    }
}

.tab:not(.selected-exportable-tab):hover {
    border-bottom: $color-blueIV solid 4px;
}

.tab {
    border-bottom: transparent solid 4px;
    padding: 12px;
    cursor: pointer;
}

.password-modal-icon {
    border: solid 1px rgb(0, 0, 0);
    cursor: pointer;
    margin-left: 2px;
    padding: 0 3px 3px 3px;
    border-radius: 4px;
}

.password-modal-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.password-modal-label span.text {
    font-size: .9rem;
}

.detail {
    color: $color-ethicsBlue;
    cursor: pointer;
}

.buttons {
    align-items: center;
    justify-content: end;
    display: flex;
    padding-top: 12px;
    gap: 8px;
    width: 100%;
    // flex-direction: row-reverse;
}

.table-head {
    background: $color-lightBlue;
}

.table-row:hover {
    background: $color-blueGray50;
    transition: all ease-in-out .3s;
    cursor: pointer;
}

.selected-exportable-tab {
    border-bottom: $color-ethicsBlue solid 4px;
    padding: 12px;
}

.file-alias {
    font-weight: bold;
    // font-size: 50px;
}

.list-fields {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: wrap;
    gap: 2%;
}

.card {
    flex: 0 0 calc(30% - 20px);
    border-radius: 10px;
    box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.1);
    margin: 10px;
    padding: 10px;
    text-align: center;
    min-height: 300px;
}

.card-body {
    display: flex;
    align-items: center;
    justify-content: center;

    & img {
        height: 100px;
    }
}

.card-data {
    display: grid;
    padding: 1rem;
    width: 80%;
}

.labels {
    color: $color-blueGray2;
    font-size: 10px;
}

.values {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 8px;

    &.comment {
        display: block;
        font-size: 13px;
        font-weight: 400;
        text-align: justify;
        margin-bottom: 5px;
        padding-right: 1rem;
    }

    &.range-date {
        font-size: 11px;
        font-weight: 800;
        color: $color-blueGray2;
        margin-bottom: 12px;
    }

    &.created-at {
        font-size: 11px;
        font-weight: 800;
        color: $color-blueGray2;
        margin-bottom: 15px;
    }
}

.password-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    gap: 5px;
}

.password {
    border: 1px solid $color-blueGray2;
    border-radius: 5px;
    font-size: 10px;
    font-weight: 600;
    padding: 5px 10px;
    color: $color-blueGray2;
    cursor: pointer;
}

.password:hover {
    box-shadow: 0px 0px 5px 1px $color-blueGray2;
}

.password-icon-eye {
    font-size: 15px;
    border-radius: 4px;
    border: 1px solid $color-green;
    padding: 1px;

    g {
        fill: $color-green;
    }

    &.show {
        border-radius: 4px;

        g {
            fill: $color-green;
        }
    }

    cursor: pointer;
}

.password-icon-eye:hover {
    border-radius: 4px;
    box-shadow: 0px 0px 5px 1px $color-green;

    & .show {
        background-color: white;

        g {
            fill: $color-green;
        }
    }
}

// 1960
@media (max-width: 1960px) {
    .card {
        min-height: 400px;
    }
}

// 1540 px
@media (max-width: 1540px) {
    .card {
        flex: 0 0 calc(40% - 20px);
    }

    .card-body {
        display: grid;

        & img {
            margin-left: calc(50% - 30px);
            height: 70px;
        }
    }

    .card-data {
        width: 100%;
    }
}

// 1090 px
@media (max-width: 1090px) {
    .card {
        flex: 0 0 calc(45% - 20px);
        min-height: 450px;
    }
}

@media (max-width: 1024px) {
    .card {
        flex: 0 0 calc(100% - 20px);
        min-height: 250px;
    }
}

@media (max-width: 768px) {
    .card {
        flex: 0 0 calc(100% - 20px);
    }
}

// 425 px
@media (max-width: 425px) {
    .card {
        flex: 0 0 calc(100% - 30px);
    }

    .card-body {
        display: grid;

        & img {
            margin-left: calc(50% - 30px);
            height: 60px;
        }
    }

    .card-data {
        width: 100%;
    }
}