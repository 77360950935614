@import "../../../theme/colors";

.confirmation-modal {
  position: relative;
  &.light {
    .modal-content {
      border: $color-blueGray600 solid 2px;
      padding: 5px;
    }
  }
  &.primary {
    .modal-content {
      border: $color-ethicsBlue;
      padding: 5px;
    }
  }
  &.secondary {
    .modal-content {
      border: $color-blueGray;
    }
  }
  &.success {
    .modal-content {
      border: $color-green solid 2px;
      padding: 5px;
    }
  }
  &.error {
    .modal-content {
      border: $color-red solid 2px;
      padding: 5px;
    }
  }
  &.warning {
    .modal-content {
      border: $color-yellow solid 2px;
      padding: 5px;
    }
  }
  &.info {
    .modal-content {
      border: $color-blue solid 2px;
      padding: 5px;
    }
  }
  img {
    max-height: 27vh;
  }
}
