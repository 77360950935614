@import "../../../../theme/colors";

//////// ----- ./Information.jsx ----- ////////
// General report information
.information {
  display: grid;
  column-gap: 20px;
  @media (min-width: 992px) {
    & {
      grid-template-columns: 20fr 13fr;
    }
  }
  &__involved {
    background-color: $color-red;
  }

  &__subject {
    font-size: 22px !important;
    font-weight: 400;
  }

  .--box-shdw {
    box-shadow: 2px 2px 7px -4px $color-charcoalGrey;
  }

  .--box-shdw2 {
    box-shadow: 1px 1px 5px -2px $color-blueGray600;
  }

  .involveds {
    width: 100%;
  }

  .involved_field {
    width: 100%;
    display: flex;
    flex-direction: column;

    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;

    .--detail {
      display: flex;
      flex-wrap: wrap;
      gap: 10px; /* Espacio entre los elementos */

      .involved {
        position: relative;
        background-color: $color-blueGray500;
        color: white !important;
        font-size: 14px;
        padding: 5px 10px;
        // padding-right: 50px;
        border-radius: 5px;
        display: flex;
        align-items: center;

        // margin: 5px;

        button {
          cursor: text;
          position: absolute;
          right: 0px;
          bottom: 0px;
          height: 100%;
          background-color: darkgray;
          /* Cambia este color según lo que necesites */
          border: none;
          color: white;
          margin-left: 10px;
          padding: 2px 10px;
          border-radius: 3px;
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
          font-size: 12px;
          cursor: pointer;
        }

        strong {
          color: white;
        }
      }

      .victim {
        // padding-right: 70px;
        //background-color: #5B9AFF;
        border: 1px solid #5b9aff;
        background-color: white;
        color: #3181ff !important;

        button {
          background-color: #3181ff;
        }

        strong {
          color: #5b9aff !important;
        }
      }

      .witness {
        // padding-right: 70px;
        background-color: #69d586;

        border: 1px solid #69d586;
        background-color: white;
        color: #44cb66 !important;

        button {
          background-color: #44cb66;
        }

        strong {
          color: #44cb66 !important;
        }
      }

      .offender {
        // padding-right: 80px;
        background-color: #e56b64;

        border: 1px solid #de463d;
        background-color: white;
        color: #de463d !important;

        button {
          background-color: #de463d;
        }

        strong {
          color: #de463d !important;
        }
      }

      .accomplice {
        // padding-right: 80px;
        background-color: #ffbf32;

        border: 1px solid darkgrey;
        background-color: white;
        color: darkgrey !important;

        button {
          background-color: darkgrey;
        }

        strong {
          color: darkgrey !important;
        }
      }
    }
  }
}

// Completion DeadLine
.conclusionDeadline {
  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;

    // @media (min-width: 992px) and (max-width: 1032px) {
    //   /* Estilos aquí se aplicarán solo cuando el ancho esté entre 600px y 1200px */
    //   .MuiInputAdornment-root {
    //     margin: 0px;
    //   }
    //   .MuiInputBase-input {
    //     display: none;
    //   }
    //   .MuiFormLabel-root {
    //     padding: 0;
    //   }
    // }
  }
}
// Report Severity Level
.severity {
  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__info {
    display: flex;
    flex-flow: column nowrap;
    background-color: $color-yellow;
    .label {
      color: $color-white;
      text-shadow: $color-blueGray700 0 0 2px;
      padding: 10px 20px;
    }
    .comment {
      padding: 2px;
    }
  }
}
// Block Report
.blocked {
  &.confirm {
    background-color: $color-redV;
    border: 2px solid $color-red;
  }
  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative;
  }
  &__info {
    display: flex;
    flex-flow: column nowrap;
    background-color: $color-red;
    .label {
      color: $color-white;
      text-shadow: $color-blueGray700 0 0 2px;
      padding: 15px 20px;
    }
    .comment {
      padding: 2px;
    }
  }
}
// Anonymity compromised
.anonymity {
  // position: relative;
  &__content {
    &.confirmed {
      background-color: $color-teal;
      padding: 2px;
    }
  }
  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative;
  }
  &__info {
    .comment {
      padding: 2px;
    }
  }
  &__divider {
    flex-grow: 1;
    height: 20px;
    // background-color: $color-blueGray50;
    background-color: transparent;
    // margin: 0.5rem auto;
    max-width: 90%;
  }
}
// Hostile environment
.hostile {
  &__content {
    &.confirmed {
      background-color: $color-purple;
      padding: 2px;
    }
  }
  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative;
  }
  &__info {
    .comment {
      padding: 2px;
    }
  }
}
// Animation switch off
.--getOut {
  animation: getOut 2s ease-in-out;
}

//////// ----- ./Comments.jsx ----- ////////
.comments {
  white-space: pre-line;
  overflow-wrap: break-word;
  text-align: justify;
  font-size: 0.9rem;
  font-weight: 400;
  // margin-top: 3px;
  background-clip: padding-box;
  appearance: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &.semi-rounded {
    border-radius: 0 0 6px 6px;
  }
  b {
    color: $color-ethicsBlue;
  }
  &.opacity {
    opacity: 0.7;
  }
}

//////// ----- Modal ./ConfirmationModal.jsx ----- ////////
.confirmationModal {
  .modal-content {
    border: $color-red solid 2px;
    padding: 5px;
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__title {
    color: $color-pink;
  }
  &__actions {
    small {
      color: $color-ethicsBlue;
      font-weight: 600;
      font-size: 1rem;
    }
    .btns {
      display: flex;
      align-items: center;
      justify-content: end;
      flex-grow: 1;
      width: 90%;
      align-self: end;
      margin-left: 2vw;
    }
  }
}
.complementsModal {
  // .modal-dialog {
  //   width: 935px !important;
  //   min-width: 935px !important;
  // }
  .modal-content {
    border: $color-red solid 2px;
    overflow: hidden;
    padding: 0;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    // min-width: 924px !important;
    height: 550px !important;
    min-height: 550px !important;
  }
  .modal-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    height: 20%;
    border-bottom: 2px solid $color-blueGray500;
  }
  .modal-body {
    height: 80%;
    padding: 0;
    max-height: 100%;
  }
  .nav {
    &.nav-mobile {
      display: flex;
    }
    &.nav-desktop {
      display: none;
    }
  }
  .nav-link {
    border-bottom: 4px solid transparent;
    font-size: 14px;
    font-weight: 600;
    color: #546e74;
    &.active {
      border-bottom: 4px solid $color-ethicsBlue;
      color: $color-ethicsBlue;
    }
    &:hover,
    &:hover g {
      color: $color-ethicsBlue;
      fill: $color-ethicsBlue;
    }
    &.disabled,
    &.disabled g {
      color: $color-blueGray200 !important;
    }
  }
  .tab-content,
  .tab-pane {
    height: 100%;
  }
  .folder {
    display: grid;
    height: 100%;
    grid-template-columns: 1fr;
  }
  .details {
    display: none;
  }
  @media (min-width: 950px) {
    .modal-dialog {
      width: 935px !important;
      min-width: 935px !important;
    }
    .modal-content {
      min-width: 924px !important;
      height: 550px !important;
      min-height: 550px !important;
    }
    .nav {
      &.nav-mobile {
        display: none;
      }
      &.nav-desktop {
        display: flex;
      }
    }
    .folder {
      grid-template-columns: 8fr 4fr;
    }
    .details {
      display: block;
    }
  }
}
.complementsModal-dialog {
  width: 924px;
  height: 550px;
}
// Report parameters
.properties {
  &__edit-btn {
    box-shadow: 0 0 5px -1px $color-blueGray600;
    width: 100%;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__edit-icon {
    g {
      path {
        fill: $color-ethicsBlue;
      }
    }
  }
  // Related report
  &__title-related {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__related-chip {
    border: $color-ethicsBlue 1px solid;
    background-color: $color-lightBackground;
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 2px;
    position: relative;
    width: 100%;
    margin: 1px auto;
    min-height: 6vh;
  }

  &__related-title {
    h6 {
      max-width: 100%;
      word-break: break-all;
    }

    small {
      max-width: 100%;
      white-space: pre-wrap;
      max-width: 91%;
    }
  }

  &__save-related {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  &__related {
    min-height: 4vh;
    max-height: 25vh;
    overflow-y: auto;
  }

  &__related-report {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $color-blueIV;
    border: 1px solid $color-ethicsBlue;
    margin: 5px 3px;
    font-size: 14px;
  }

  &__remove {
    background-color: $color-paleBlue;
    cursor: pointer;
    border: 1px solid $color-red;
    border-radius: 50%;
    padding: 3px;

    g {
      fill: $color-pink;
    }
  }
}

//////// ----- ./RelatedReport.jsx ----- ////////
.relatedReport {
  .comment {
    padding: 2px;
  }
}

@keyframes getOut {
  25% {
    transform: scale(0.9);
  }

  50% {
    transform: scale(1.1);
    opacity: 1;
  }

  100% {
    transform: scale(0);
    opacity: 0;
  }
}

//////// ----- from ./Complements.jsx ----- ////////
.complements {
  &__container {
    display: flex;
    align-items: flex-start;
    align-content: flex-start; /* Añade esta línea */
    justify-content: space-between;
    flex-flow: row wrap;
    min-height: 5vh;
    padding-top: 5px;
  }

  &__text {
    display: flex;
    flex-flow: column nowrap;
    width: 47%;

    small {
      padding: 3px;
      text-align: justify;
    }

    p {
      display: flex;
      justify-content: end;
      margin: 0;
      padding-right: 5px;
      font-style: italic;
    }
  }

  &__commentary {
    padding: 5px 5px;

    small {
      font-size: 14px !important;
    }
  }

  &__files {
    padding: 1px 5px;
    min-width: 45%;
    max-width: 49%;

    .--file {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: row wrap;
      min-height: 4.5vh;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: column;
      background-color: transparent;
      border: none;
      font-size: 0.6rem;

      svg {
        path {
          fill: $color-ethicsBlue;
        }
      }

      svg:hover {
        scale: 1.3;
      }
    }

    p {
      margin: 0 auto;
    }
  }
}

.cancel-edit-btn {
  .right {
    float: right;
    background-color: #d3dbe1;
    margin-top: 7px;
    color: #909ca4 !important;
    border-radius: 4px;
    border: solid 1px #bdcad0 !important;
    background-image: linear-gradient(
      to bottom,
      #d3dbe1,
      #d9d9d9 120%
    ) !important;
    padding: 0 0.5rem !important;
    box-shadow: none;

    svg {
      g {
        fill: #909ca4;
      }
    }
  }
}

.selectInvestigation__option {
  display: flex;
  align-items: center;
}

.circle-assign-investigation {
  height: 8px;
  width: 8px;
  border-radius: 100%;
  &.assigned {
    background-color: $color-green;
  }
  &.unassigned {
    background-color: red;
  }
}

.--line {
  flex-grow: 1;
  height: 1px;
  background-color: $color-ethicsBlue;
  margin: auto 5px;
}
