@import "../../../theme/colors";

#file-upload{
    margin: 1rem;
    border: 1px solid $color-slate2;
    border-style: dashed;
    .dropzone{
        padding: 2rem;
        text-align: center;
        p{
            margin: 0;
        }
        #preview{
            width: auto;
            margin-top: 2rem;
            max-width: 500px;
        }
    }
}

@media screen and (max-width: 768px) {
    #file-upload .dropzone #preview{
        max-width: 300px;
    }    
}

@media screen and (max-width: 576px) {
    #file-upload .dropzone{
        img, #preview{
            max-width: 100%;
        }
    }    
}