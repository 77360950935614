@import "../../../theme/_colors.scss";
.avatar {
  background-color: $color-yellow;
  color: $color-white;
  font-size: 10px;
  width: 15px;
  height: 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 8px;
  box-shadow: 0 0 1px 1.1px $color-ethicsBlue, 0 0 10px -3px $color-black;
  text-shadow: $color-cobalt 1px 1px 2px;
  position: relative
}
.contact-image {
  width: 25px !important;
  height: 25px !important;
  border-radius: 50%;
  margin-right: 8px;
  box-shadow: 0 0 1px 1.1px $color-ethicsBlue, 0 0 10px -3px $color-black;
}

.avatar-group {
  display: flex;
  align-items: center;
  .avatar,
  .contact-image {
    margin-right: -3px;
    z-index: 1;
  }
  :first-child{
    z-index: 10;
  }

  .extra-count {
    // width: 25px;
    // height: 25px;
    border-radius: 50%;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-blueGray500;
    font-size: 0.75rem;
    color: $color-white;
    text-shadow: $color-cobalt 0 0 3px;
    text-transform: uppercase;
    margin-left: -3px;
    z-index: 0;
  }
  &__list-tooltip {
    text-align: start;
  }
  &__modal-list {
    max-height: 65vh;
    overflow-y: auto;
  }
  &__modal-contact {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 5px auto;
    width: 100%;
    border-bottom: 1px solid $color-blueGray50;
  }
}
