@import "../../../theme/colors";

#recent-reports {
  background-color: $color-white;
  font-size: 13px;
  margin-top: 0px !important;

  .report {
    cursor: pointer;
    border: 1px solid $color-blueGray200;
    padding: 1rem;

    &.new {
      border-left: 2px solid $color-blue;
      svg {
        background-color: $color-blue;
        &.profile {
          background-color: transparent;
        }
      }
    }
    &.finish {
      border-left: 3px solid $color-yellow;
      svg {
        background-color: $color-yellow;
        &.profile {
          background-color: transparent;
        }
      }
    }
    &.pending {
      border-left: 3px solid $color-purple;
      svg {
        background-color: $color-purple;
        &.profile {
          background-color: transparent;
        }
      }
    }
    &.process {
      border-left: 3px solid $color-green;
      svg {
        background-color: $color-green;
        &.profile {
          background-color: transparent;
        }
      }
    }
    &.closed {
      border-left: 3px solid $color-red;
      svg {
        background-color: $color-red;
        &.profile {
          background-color: transparent;
        }
      }
    }
    &.draft {
      border-left: 3px solid $color-blueGray;
      svg {
        background-color: $color-blueGray;
        &.profile {
          background-color: transparent;
        }
      }
    }
    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: row nowrap;
      &__icon {
        min-width: 2vw;
        min-height: 2vh;
        margin: 0 auto;
        padding: 3px;
        g {
          fill: $color-white;
        }
      }
      &__folio {
        width: 95%;
        padding: 5px;
        word-wrap: break-word;
        overflow-wrap: break-word;
        text-align: justify;
      }
    }
    small {
      text-align: end;
      font-size: 11px;
    }
    p {
      font-size: 13px;
      color: $color-slate2;
      font-weight: 500;
      margin: 2px 0;
    }
  }
}
