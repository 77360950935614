@import "../../../theme/colors";

.selection-fields-container {
    padding: 0 !important;
}

.selection-actions {
    display: flex;
    justify-content: space-between;
    gap: 8px;

    button {
        font-size: 12px;
        padding: 4px 8px;
    }
}

.selection-container {
    flex-grow: 1;
    max-height: calc(3 * 56px);
    overflow-y: auto;
}

.item {
    background-color: #fff;
    padding: 8px;
    text-align: center;
    color: #000;
    cursor: pointer;
    transition: background-color 0.3s ease, border-color 0.3s ease;

    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 6px;

    &:hover {
        background-color: #f0f0f0;
        border-color: $color-ethicsBlue;
    }

    &.selected {
        background-color: $color-ethicsBlue;
        color: #fff;
        border-color: #0056b3;
    }
}
