@import "../../../theme/colors";

.imageUpload {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  &__title {
    margin-bottom: 0.7vh;
  }
  &__subtitle {
    color: $color-blueGray2;
    font-size: 0.8rem;
  }
}

.imageUploader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  border-radius: 6px;
  border: $color-silver dotted 3px;
  padding: 5px;
  cursor: pointer;
  &__inputImage {
    display: none;
  }
  &__icon {
    g {
      fill: $color-blueGray2;
    }
  }
  &__subtitle {
    color: $color-blueGray2;
    margin: 0 auto;
    text-align: center;
  }
  &.drag-over {
    border: dotted 3px $color-ethicsBlue;
    opacity: 0.8;
    code {
      color: $color-ethicsBlue;
    }
    g {
      fill: $color-ethicsBlue;
    }
    animation: buzz 4s infinite;
  }
}

@keyframes buzz {
  0% {
    -webkit-transform: rotateZ(-2deg);
  }
  33% {
    -webkit-transform: rotateZ(0deg) scale(0.9);
  }
  66% {
    -webkit-transform: rotateZ(2deg);
  }
  100% {
    -webkit-transform: rotateZ(-1deg) scale(0.9);
  }
}

.imageViewer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  position: relative;
  box-shadow: $color-silver 0 0 10px -1px;
  &__delete {
    background: transparent;
    border: none;
    border-radius: 100%;
    position: absolute;
    scale: 1.3;
    top: -18px;
    right: -18px;
    path {
      fill: $color-pink;
    }
  }
  &__image {
    max-width: 20vw;
    max-height: 20vh;
    border: $color-silver dotted 1px;
  }
  &__subtitle {
    padding: 5px;
    color: $color-blueGray2;
    text-align: center;
  }
}
