@import "../../theme/colors";
.filter-modal p {
  font-size: 13px !important;
  font-weight: 400 !important;
}

.main-component {
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-example-dialog.dark {
  background-color: $color-darkFour;
  border: 1px solid $color-darkBorder;
}

.container-body hr {
  margin-top: 30px;
}

.footer-modal {
  padding: 0 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .mcbtn {
    margin-inline: 3px;
  }
}

.turnBack {
  background-color: transparent;
  border: none;
  svg {
    g {
      fill: $color-ethicsBlue;
    }
  }
}
.turnBack:hover {
  background-color: $color-ethicsBlue;
  box-shadow: $color-blueGray200 0 0 1px 1px;
  border-radius: 50%;
  svg {
    g {
      fill: $color-white;
    }
  }
}

.container-btn-new-filter{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-flow: row wrap;
  padding: 1.3vh 0;
}

.filter-container {
  border-radius: 15px;
  border: 1px dashed $color-blueGray600;
  margin-top: 10px !important;
  padding: 20px;
}

.add-indicator{
  text-align: end;
}

.add-indicator-stacked {
  display: flex;
  justify-content: flex-end; 
  gap: 5px; 
}

.stack-filters-container {
  border-radius: 15px;
  border: 1px dashed $color-blueGray600;
  margin-top: 10px !important;
  padding: 20px;
  transition: all 0.3s ease-in-out;
  max-height: 300px;
  overflow-y: scroll;
}
.stack-filters-container::-webkit-scrollbar {
  width: 3px;
}

.stack-filters-container::-webkit-scrollbar-thumb {
  background: $color-ethicsBlue;
}

.head-container-filter {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.item-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid $color-blueGray600;
  .trash {
    cursor: pointer;
    padding: 1px;
    g {
      fill: $color-red !important;
    }
  }

  .trash:hover {
    cursor: pointer;
    padding: 1px;
    border-radius: 5px;
    background-color: $color-red !important;
    g {
      fill: $color-white !important;
    }
  }


  .add-filter{
    cursor: pointer;
    min-width: 24px;
    max-width: 24px;
    padding: 1px;
    margin-top: 3px;
    margin-bottom: 3px;
    min-height: 24px;
    max-height: 24px;
  }

  .add-filter:hover{
    cursor:pointer;
  }
}

.modal-keep-session {
  .modal-content {
    border-radius: 40px;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);;
    .image-container {
      width: 100%;
      position: relative;
      .main-img {
        width: 80%;
      }
    }
  }
  .crono-container {
    position: absolute;
    font-size: 24px;
    width: 100%;
    height: 16%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    bottom: 8%;
    .crono {
      width: 40%;
      display: flex;
      height: 100%;
      margin-left: -10px;
    }
    @media (min-width: 410px) {
      bottom: 7%;
      font-size: 36px;
    }
    @media (min-width: 576px) {
      bottom: 7%;
      font-size: 42px;
    }
  }
  .area {
    width: 47%;
    height: 100%;
  }
  .separator {
    width: 6%;
  }
  .digit-container {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    align-items: center;
  }
  .digit {
    width: 100%;
    z-index: 2;
  }
  .background-digit {
    object-fit: fill;
    position: absolute;
    height: 100%;
    width: 100%;
  }
  .content-session-expired {
    color: white;
    background-color: #544ea0;
  }
  .content-session-about-to-expire {
    color: white;
    background-color: #D3A161;
  }
  .btn-session {
    border-radius: 12px;
    font-size: 16px;
    height: 64px;
    font-weight: 600;
    text-align: center;
    width: 100%;
    border: none;
    transition: all 250ms;
    &.again {
      color: black;
      background-color: white;
    }
    &.yes {
      color: white;
      background-color: $color-ethicsBlue;
    }
  }

  // .prueba-giro {
  //   background-color: white;
  //   border-radius: 12px;
  //   font-size: 16px;
  //   height: 64px;
  //   font-weight: 600;
  //   text-align: center;
  //   width: 100%;
  //   border: none;
  //   transform-origin: bottom center; 
  //   &.volteado {
  //     transition: all 1000ms ease;
  //     transform-style: preserve-3d;
  //     transform: rotateX(-180deg);
  //     // transform: rotateX(-180deg) translateY(-100%);
  //   }
  // }
}


.modal-keep-session-vanilla {
  border-radius: 40px;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  padding: 0;
  color: white;
  border: none;
  max-width: 400px;
  margin-right: 16px;
  margin-left: 16px;
  .image-container {
    width: 100%;
    position: relative;
    .main-img {
      width: 80%;
    }
  }
  .modal-content {
    padding: 0;
  }
  .modal-body::backdrop {
    background-color: #544ea0;
  }
  .crono-container {
    position: absolute;
    font-size: 24px;
    width: 100%;
    height: 16%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    bottom: 8%;
    .crono {
      width: 40%;
      display: flex;
      height: 100%;
      margin-left: -10px;
    }
    @media (min-width: 410px) {
      bottom: 7%;
      font-size: 36px;
    }
    @media (min-width: 576px) {
      bottom: 7%;
      font-size: 42px;
    }
  }
  .area {
    width: 47%;
    height: 100%;
  }
  .separator {
    width: 6%;
  }
  .digit-container {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    align-items: center;
  }
  .digit {
    width: 100%;
    z-index: 2;
  }
  .background-digit {
    object-fit: fill;
    position: absolute;
    height: 100%;
    width: 100%;
  }
  .content-session-expired {
    color: white;
    background-color: #544ea0;
    padding: 32px;
  }
  .content-session-about-to-expire {
    color: white;
    background-color: #D3A161;
    padding: 32px;
  }

  .btn-session {
    border-radius: 12px;
    font-size: 16px;
    height: 64px;
    font-weight: 600;
    text-align: center;
    width: 100%;
    border: none;
    transition: all 250ms;
    &.again {
      color: black;
      background-color: white;
    }
    &.yes {
      color: white;
      background-color: $color-ethicsBlue;
    }
  }

  @media (min-width: 430px) {
    margin-right: auto;
    margin-left: auto;
  }
}