@import "../../../../../theme/colors";



.task {
  &__pending {
    display: flex;
    flex-flow: column nowrap;
    border: 6px solid $color-redIV;
    background-color: $color-redIV;

    .comment {
      padding: 2px;
      background-color: white;
      padding-left: 10px;
      // border: 2px solid $color-yellowII; 
    }
  }

  &__process {
    display: flex;
    flex-flow: column nowrap;
    border: 6px solid $color-blueIV;
    background-color: $color-blueIV;

    .comment {
      padding: 2px;
      background-color: white;
      padding-left: 10px;
      // border: 2px solid $color-yellowII; 
    }
  }

  &__completed {
    display: flex;
    flex-flow: column nowrap;
    border: 6px solid $color-greenIV;
    background-color: $color-greenIV;

    .comment {
      padding: 2px;
      background-color: white;
      padding-left: 10px;
      // border: 2px solid $color-yellowII; 
    }
  }
}

.sprint_column {
  background-color: $color-blueGray100;
  border: 2px solid $color-white;
}

