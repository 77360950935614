*:not(
    .swal2-container * 
) {
    box-sizing: border-box !important;
    -webkit-box-sizing: border-box !important;
    -moz-box-sizing: border-box !important;
}

html,
body {
    height: 100%;
    min-height: 100%;
    width: 100%;
    scroll-behavior: smooth;
    transition: none !important;
}

#root {
    margin: 0;
    height: 100%;
    width: 100%;
}

:focus {
    outline: none !important;
    transition: none !important;
}

::-moz-focus-inner {
    border: 0 !important;
}
  