@import '../../../theme/colors';
// Modal from BusinessUnitList.jsx
.modalUnitList {
  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column wrap;
    gap: 2vh;
    margin: 0 auto;
    width: 100%;
  }
  &__icon {
    scale: 1.5;
    path {
      fill: $color-yellow;
    }
  }
  &__title {
    margin: 0 auto;
  }
  &__text {
    text-align: center;
  }
  &__actionBtns {
    margin-top: 2vh;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
}

.actionBtn {
  &__edit {
    background: transparent;
    border: none;
    path {
      fill: $color-ethicsBlue;
    }
  }
  &__enable {
    background: transparent;
    border: none;
    g {
      fill: $color-green;
    }
  }
  &__disable {
    background: transparent;
    border: none;
    g {
      fill: $color-red;
    }
  }
}

.unitList {
  &__list {
    position: relative;
  }
  &__addNew {
    position: absolute;
    top: 45px;
    right: 60px;
    background-color: transparent;
    border: none;
    scale: 1.3;
    g {
      fill: $color-ethicsBlue;
    }
  }
  &__addNew:active {
    scale: 1.4;
  }
}
