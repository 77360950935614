.spacing {
  display:grid;
  grid-template-columns: 2fr 2fr 1fr 1fr;
  grid-gap: 10px;
  
  }
  .z-index{
    position: relative; /* Necesario para que z-index funcione */
    z-index: 11; /* El valor de z-index que desees */
  }
  