/* Theme typography variables */
// Font family
$font-family-primary: 'Segoe UI';

// Font weights
$font-weight-light: 200;
$font-weight-semiLight: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
