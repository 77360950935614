@import "../../theme/colors";
//Auth.jsx
.authContainer {
  overflow-y: hidden;
  background-color: $color-blueGray200;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  padding: 2px;
  .auth {
    flex-flow: row wrap;
    max-width: 1200px !important;
    max-height: 600px;
    &__right {
      background-color: $color-ethicsBlue;
      border-radius: 0 15px 15px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      max-height: 100%;
      max-width: 100%;
    }
    &__left {
      background-color: $color-white;
      border-radius: 15px 0 0 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      max-width: 100%;
      max-height: 100%;
      .login {
        padding: 0.33rem;
        background-color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .language {
        background-color: transparent;
        position: absolute;
        right: 2.5vw;
        top: 2.5vh;
        border: transparent;
        margin-right: -1vh;
        &__flag {
          height: 16px;
          width: 16px;
          border-radius: 100%;
        }
      }
    }
    &__form {
      max-height: 100% !important;
      max-width: 100%;
    }
    .createAccount__p {
      font-size: 13px !important;
      font-weight: 400 !important;
    }
  }
  .copyright {
    font-size: 1rem;
    width: 100%;
    opacity: 0.6;
    position: absolute;
    bottom: 0;
    margin: 1vh auto;
    padding: 3px;
    text-align: center;
  }
  //media Query
  @media screen and (max-width: 600px) {
    .auth {
      min-height: 100vh;
      min-width: 100vw;
      &__left {
        border-radius: 0;
        height: 100vh;
        width: 100vw;
      }
      &__right {
        display: none;
      }
    }
  }
  @media screen and (max-width: 999px) {
    .auth {
      height: 85vh;
      width: 90vw;
      &__left {
        width: 100%;
        height: 100%;
      }
    }
  }
  @media screen and (min-width: 1000px) {
    .auth {
      max-width: 60vw;
      width: 60%;
      height: 75vh;
    }
  }
  @media screen and (max-height: 600px) {
    .auth {
      height: 95vh;
    }
  }
}

//Signin.jsx
.signin {
  max-width: 95%;
  max-height: 90%;
  &__mainTitle {
    font-size: 1.7rem !important;
  }
  &__fieldSet {
    display: flex;
    align-items: center;
    flex-flow: column;
  }
  &__img {
    margin: 1vh auto;
  }
  &__form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column wrap;
  }
  &__group {
    margin: 0 auto;
    width: 90%;
  }
  &__label {
    width: 100%;
    font-size: 0.8rem;
    padding-left: 5px;
  }
  &__errorInput {
    color: $color-red;
    font-size: 0.72rem;
  }
  .--inputEmail {
    border-radius: 9px !important;
    height: 3.4em;
    padding: 1em;
  }
  .--inputPassword {
    border-radius: 9px 0 0 9px !important;
    border-right: none;
    padding: 1em;
  }
  &__inputBtn {
    background-color: transparent !important;
    border-left: none !important;
    border-radius: 0 9px 9px 0 !important;
    padding: 1em;
  }
  &__input::placeholder {
    color: $color-blueGray600;
  }
  .signinTools {
    font-size: 0.7rem;
    width: 90%;
    display: flex;
    justify-content: space-between;
    margin: 1vh auto 1vh auto;
    &__remember {
      color: $color-blueGray600;
    }
    &__forgotPassword {
      color: $color-ethicsBlue !important;
      font-size: 0.8rem;
      text-align: end;
    }
    &__forgotPassword:hover {
      color: $color-purple !important;
    }
  }
  &__btn {
    margin: 0.5vh auto;
  }
  .createAccount {
    margin: 2vh 0;
    display: flex;
    flex-flow: row wrap;
    width: 90%;
    justify-content: space-between;
    align-items: center;
    p {
      margin: 0;
      font-size: 0.8rem;
    }
    &__link {
      color: $color-ethicsBlue !important;
    }
    &__link:hover {
      color: $color-purple !important;
    }
  }

  .code-squeres {
    display: flex;
    // grid-template-columns: repeat(6, 1fr);
    // gap: 16px;
    // width: 100%;
    .code-squere {
      column-gap: 16px;
      width: 32px;
      height: 40px;
      margin-right: 8px;
      border: 1px solid $color-blueGray500;
      text-align: center;
      border-radius: 4px;
      &:last-child {
        margin-right: 0;
      }
      &:focus {
        box-shadow: 0px 0px 10px $color-ethicsBlue;
      }
    }
  }
}

.domain-selection {
  display: flex;
  flex-direction: column;  
  height: 100%;
  // overflow: auto;
  position: relative;
  background-color: white;
  border-radius: 15px;
  padding: 64px;
  align-items: center;
  .domain-list {
    max-height: 65%;
    position: relative;
    overflow: auto;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    @media (min-width: 768px) {
      & {
        width: 80%;
      }
    }
  }
  .domain-list-item {
    position: relative;
    display: flex;
    // justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 8px 0;
    height: 48px;
    padding: 8px;
    background-color: gainsboro;
    transition: 250ms all ease-in-out;
    font-weight: 400;
    border: 2px solid $color-blueGray500;
  }
  .domain-list-item.active {
    background-color: $color-ethicsBlue;
    color: white;
  }
  .domain-list-item:first-child {
    margin-top: 0;
  }
  .domain-list-item:last-child {
    margin-bottom: 0;
  }
  .arrow_in {
    position: absolute;
    right: 4px;
  }
}

//Carrousel.jsx
.containerCarousel {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  width: 95%;
  height: 95%;
}
.carousel {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row;
  position: relative;
  width: 100%;
  height: 80%;

  &__control {
    display: flex;
    width: 10%;
    align-items: center;
    justify-content: center;
  }
  &__btn {
    border-radius: 50%;
    opacity: 0.25;
    border: none;
    background-color: transparent;
  }
  &__btn:hover {
    opacity: 0.79;
    scale: 1.4;
  }
  &__img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    height: 90%;
  }
  img {
    max-width: 100%;
    max-height: 100%;
    opacity: 0;
    border-radius: 10px;
    transition: 1s;
    justify-content: center;
  }
  .loaded {
    opacity: 1;
  }
  &__footer {
    width: 25%;
    text-align: center;
    display: flex;
    justify-content: space-around;
    gap: 1vw;
    button {
      width: 0.1rem;
      height: 0.1rem;
      background-color: $color-white;
      box-shadow: $color-white 0 0 2px 0;
      border: none;
      opacity: 0.25;
    }
    .--active {
      opacity: 1;
      transition: 0.5s;
      scale: 1.5;
    }
    button:hover {
      scale: 1.4;
      opacity: 0.9;
    }
  }
}

#register-step {
  position: absolute;
  top: 27px;
  right: 31px;
  width: 216px;
}

.form-control:invalid {
  border: 1px solid red;
}

.btnVerifyCLose {
  border-radius: 5px;
  color: #009ed7;
  width: 70px;
  border: 1px solid #009ed7;
  margin-left: 20px;
  margin-top: 10px;
}

.btnVerifySubmit {
  border-radius: 5px;
  color: white;
  background-color: #009ed7 !important;
  width: 70px;
  margin-left: 20px;
  margin-top: 10px;
  border: 1px solid #009ed7;
}
