@import "../../../theme/colors";

label.selected,
p.selected {
  color: $color-slate2 !important;
  &.dark-mode {
    color: $color-white !important;
  }
}

input[type="radio"],
input[type="checkbox"] {
  cursor: pointer;
  border: 1px solid $color-darkBorder;
}

input[type="radio"]:hover,
input[type="checkbox"]:hover {
  border: 1px solid $color-ethicsBlue !important;
  box-shadow: #009ed750 0px 0px 3px 2px;
}

input[type="text"],
input[type="number"],
input[type="password"],
input[type="email"],
textarea {
  // background-color: $color-white;
  color: $color-blueGray900;
  &::-webkit-scrollbar {
    // display: none;
  }

  &.dark {
    background-color: $color-darkFour !important;
    color: $color-coolGray !important;
    border: 1px solid $color-darkBorder;
    &:focus,
    &:hover {
      border: 1px solid $color-blueGray600 !important;
    }
    &:disabled {
      color: $color-slate3 !important;
      cursor: not-allowed;
    }
  }

  input[type="text"],
  input[type="number"],
  input[type="password"],
  input[type="email"],
  &.is-invalid {
    border-color: $color-red !important;
  }

  input[type="email"],
  input[type="text"],
  input[type="number"],
  input[type="password"],
  &.is-invalid::placeholder {
    color: $color-red !important;
    opacity: 0.75;
    font-size: 14px;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.react-datepicker {
  display: flex !important;
}

.react-datepicker-wrapper input {
  width: 100%;
  padding: 6px;
  border-radius: 5px;
  background-color: transparent;
  color: inherit;
  border: 1px solid #009ed7;
}
//  input[disabled]{
//   background-color: #009ed7;
// }
