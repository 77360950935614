@import "../../../../../theme/_colors.scss";
.datos__menu {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-flow: row wrap;
  gap: 2px;
  .menu_tab {
    flex-grow: 1;
    transition: all 0.3s;
    border: none;
    padding: 5px;
    background-color: $color-white;
    box-shadow: 0 0 5px -1px $color-tealVI;
    color: $color-blueGray700;
    min-height: 3.5vh;
    &:hover {
      background-color: $color-blueGray100;
    }
  }
  .active {
    transition: all 0.3s;
    background-color: $color-ethicsBlue;
    color: $color-white;
    text-shadow: 0 0 1px $color-blueGray700;
    box-shadow: 0 0 5px -1px $color-darkBackground;
    &:hover {
      background-color: $color-blue2;
    }
  }
}
