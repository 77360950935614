@import '../../../theme/colors';



.indicator-body {
    width: 170px;
    height: 230px;
    margin: 20px 10px;
    padding: 1em;
    border-radius: 4px;

    &.light {
        border: solid 1px $color-paleGrey2;
        background-color: $color-white;
    }

    &.dark {
        border: solid 1px $color-blueGray2;
        background-color: $color-darkThree;
    }
}

.indicators-content {
    
    margin-bottom: 2em;

    .top-header{
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
    
    .indicators-subtitle {
        font-size: 14px;
        display: block;
        height: 2em;
        width: 100%;
        font-size: 16px !important;
        font-weight: 600 !important;
        margin: 1.5em 1.2em;
        border-bottom: 1px solid $color-paleGrey2;
        color:#263238 !important;
        // width: auto;
        // display: inline-block;
    }
}

.indicator-title {
    width: 140px;
    height: 28px;
    font-family: SegoeUI;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: $color-darkTwo;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.indicator-options {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    padding: 1em;
    cursor: pointer;


    &.dark {
        .dropdown-menu.show {
            position: absolute;
            inset: 0px auto auto 0px;
            transform: translate3d(851.5px, 264.5px, 0px);
            background-color: $color-darkThree;
            border: solid 1px $color-blueGray2;
            color: $color-blueGray2;
        }
    }
}

#dashboard {
    .dropdown-toggle.btn.btn-primary {
        background-color: transparent;
        border: none;
        box-shadow: none;
        color: $color-blueGray2;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        &:hover {
            background-color: transparent;
            border: none;
            box-shadow: none;
            color: $color-blueGray2;
        }
    }
}

.indicator-options:hover {
    border-radius: 5px;

    &.light {
        border: solid 1px $color-paleGrey2;
        span {
            color: #000000;
        }
    }

    &.dark {
        border: solid 1px $color-blueGray2;
        span {
            color: $color-white;
        }
    }
}

.indicator-count {
    width: 110px;
    height: 110px;
    margin: auto;
    padding: auto;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    &.light {
        border: solid 1px $color-paleGrey2;
    }

    &.dark {
        border: solid 1px $color-blueGray2;
    }

    span {
        font-family: SegoeUI;
        font-size: 40px;
        color: $color-lightishBlue;
    }
}


.indicator-count-right {
    width: 100px;
    height: 100px;
    margin: auto;
    padding: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    



    span {
        font-family: SegoeUI;
        font-size: 80px;
        color: $color-lightishBlue;
    }
}