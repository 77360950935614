@keyframes shake {
    0% { transform: rotate(0); }
    20% { transform: rotate(-10deg); }
    40% { transform: rotate(20deg); }
    60% { transform: rotate(-10deg); }
    80% { transform: rotate(20deg); }
    100% { transform: rotate(0); }
  }
  
  .bell {
    font-size: 50px;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
  
    &.shake {
      animation: shake 4s ease-in-out infinite;
      animation-delay: 5s;    
    }
  }