@import "../../theme/colors";

.calendar {
  border-radius: 7px;
  min-width: 75%;
  margin: 0 auto;
  box-shadow: $color-blueGray500 2px 5px 5px 1px;
  margin-bottom: 2vh;

  &__header {
    border-radius: 7px 7px 0 0;
    background-color: $color-darkBackground;
    color: $color-landing;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-flow: row wrap;
    padding: 12px;
  }
  &__nxtPrev {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-flow: row nowrap;
  }
  &__body {
    margin: 10px;
  }
  &__sevenColGrid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 2vw;
  }
  &__day {
    text-align: center;
    margin-bottom: 5px;
  }

  .btnsCalendar {
    background: transparent;
    border: none;
    padding: 1px;
    font-size: 0.8rem;
  }
  .btnsCalendar:disabled, .btnsCalendar[disabled]{
    opacity: 0.4;
    color: $color-black;
  }
  .--active {
    background-color: $color-ethicsBlue !important;
    color: $color-landing;
    border-radius: 17.5px;
  }
  .--today {
    background-color: $color-gray2;
    border-radius: 17.5px;
  }
}
//Dark-mode 
.dark-mode .calendar{
    box-shadow: $color-darkBackground 2px 5px 5px 1px;
    background-color: $color-darkBorder;
    &__day{
      color: $color-blueGray50;
    }
    .btnsCalendar{
      color: $color-silver2;
    }
    .btnsCalendar:disabled, .btnsCalendar[disabled]{
      opacity: 0.4;
    }
    .--today {
      background-color: $color-Gray;
    }
}
