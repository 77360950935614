// Mapa de valores comunes
$spacing-values: (
  0: 0, 
  2: 2px, 
  3: 3px, 
  4: 4px, 
  5: 5px, 
  6: 6px, 
  7: 7px, 
  8: 8px, 
  10: 10px, 
  12: 12px, 
  14: 14px, 
  15: 15px,
  16: 16px,
  18: 18px,
  20: 20px,
  22: 22px,
  24: 24px,
  25: 25px,
  26: 26px,
  28: 28px,
  30: 30px,
);

@each $key, $value in $spacing-values {
  .p-\[#{ $key }\] {
    padding: $value !important;
  }
  .px-\[#{ $key }\] {
    padding-left: $value !important;
    padding-right: $value !important;
  }
  .py-\[#{ $key }\] {
    padding-top: $value !important;
    padding-bottom: $value !important;
  }
  .ps-\[#{ $key }\] {
    padding-left: $value !important;
  }
  .pe-\[#{ $key }\] {
    padding-right: $value !important;
  }
  .pt-\[#{ $key }\] {
    padding-top: $value !important;
  }
  .pb-\[#{ $key }\] {
    padding-bottom: $value !important;
  }
  
  .m-\[#{ $key }\] {
    margin: $value !important;
  }
  .mx-\[#{ $key }\] {
    margin-left: $value !important;
    margin-right: $value !important;
  }
  .my-\[#{ $key }\] {
    margin-top: $value !important;
    margin-bottom: $value !important;
  }
  .ms-\[#{ $key }\] {
    margin-left: $value !important;
  }
  .me-\[#{ $key }\] {
    margin-right: $value !important;
  }
  .mt-\[#{ $key }\] {
    margin-top: $value !important;
  }
  .mb-\[#{ $key }\] {
    margin-bottom: $value !important;
  }

}