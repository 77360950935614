@import "../../../../../theme/colors";
.background{
    padding: 1rem;
    &.dark-mode{
        
        .border-bottom{
            border-bottom: 1px solid $color-darkBorder!important;
        }
        h3{
            color: $color-paleGrey;
        }
        p, h4, h5, h6{
            color: $color-blueGray2;
        }
        .border, .form-control, .react-datepicker-wrapper input {
            border: 1px solid $color-darkBorder!important;
            color: $color-blueGray2;
        }
        .form-select{
            border: 1px solid $color-darkBorder!important;
            color: $color-blueGray2!important;
            option{
                background: $color-dark!important;
            }
        }
        .btn.download-btn{
            background-image: linear-gradient(to bottom, $color-dark, $color-darkTwo 120%)!important;
            color: $color-blueGray2!important;
            border: 1px solid $color-dark!important;
            svg g { fill: $color-blueGray2; }
        }
        .form-check-input{
            background-color: $color-dark;
        }
        .react-datepicker__month-container{
            background-color: $color-dark;
            .react-datepicker__header{
                background-color: $color-dark;
            }
            .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header, .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
                color: $color-white;
            }
        }
        .btn.athics-lignt-btn{
            background-color: $color-dark!important;
            color: $color-white!important;
            svg g{
                fill: $color-white;
            }
        }
    }
    
    .attachments {
        transition: all 200ms;
        border-radius: 2px;
        text-align: center;
        padding: 16px;
        border: 2px dotted $color-blueGray;
        border-radius: 8px;
    }
    .attachments-icon-container {
        height: 35px;
        svg {
            height: 35px;
            width: 35px;
            margin: 0px 4px;
            filter: brightness(0%) contrast(100%) invert(57%) sepia(3%) saturate(2732%) hue-rotate(155deg) brightness(98%) contrast(80%);
        }
    }
    .attachments-instructions {
        p:nth-child(1) {
            font-size: 24px;
            margin-bottom: 0px;
        }
        p:nth-child(2) {
            font-size: 18px;
        }
    }
    svg{
        width: 1.5rem!important;
    }
    .interview-header{
        h5{
            color: $color-white;
        }
        small{
            color: #c6e5fc;
        }
    }
}
.no_sidebar .background{
    padding: 20px 30px;
}
.upload-block{
    position: relative;
    input{
        opacity: 0;
        position: absolute;
        top:0;
        left: 0;
        height: 50px;
    }
}
.config-dropdown{
    &.user-dropdown{
        .dropdown-menu{
            // top: 0px !important;
            // right: -40px!important;
            // width: 220px;
            font-size: 14px;
            a, small{
                padding: 0.5rem;
                color: $color-blueGray900!important;
                text-decoration: none;
                &:hover{
                    background-color: $color-landing;
                    font-weight: bold;
                }
            }
        }
    }
    &.report-dropdown{
        .dropdown-menu{
            width: 140px;
            font-size: 12px;
            right: -70px;
            // top: -10px!important;
            border-radius: 6px;
            div{
                &:hover{
                    background-color: $color-landing;
                }
            }
        }
    }
    &.flow-dropdown{
        .dropdown-menu{
            width: 120px;
            font-size: 12px;
            right: 0;
            // top: -10px!important;
            border-radius: 6px;
            div{
                &:hover{
                    background-color: $color-landing;
                }
            }
        } 
    }
    button{
        background-color: transparent;
        // padding: 0;
        &:hover{
            background-color: transparent;
        }
    }
    .dropdown-menu {
        right: 0;
        left: unset!important;
        font-size: 12px;
        text-align: left;
        svg{
            width: 1rem!important;
            height: 1rem!important;
            color: #000;
            font-size: 8px;
        }
        .dropdown-item{
            font-size: 12px;
        }
        a{
            color:#009ed7!important;
        }
    }
}

.dropdown-toggle {
    &:active {
        // background-color: #009ed7;
        border-color: transparent !important;
    }
    &::after {
        display: none !important;
    }
}

@media screen and (max-width: 992px) {
    .background{
        padding: 0;
    }
}



@media screen and (max-width: 576px) {
    .background{
        padding: 0;
    }
    .start_interview_btn, .involve-btn{
        width: 100%;
    }
    .no_sidebar .background{
        padding: 1rem;
    }
}