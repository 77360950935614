@import "../../../../theme/colors";

.drag-over {
  animation: buzz 4s infinite;
}

@keyframes buzz {
  1% {
    -webkit-transform: rotateZ(0deg) scale(1);
  }
  25% {
    -webkit-transform: rotateZ(-1deg) scale(0.97);
  }
  50% {
    -webkit-transform: rotateZ(0deg) scale(1);
  }
  75% {
    -webkit-transform: rotateZ(1deg) scale(1.03);
  }
  100% {
    -webkit-transform: rotateZ(0deg) scale(1);
  }
}
