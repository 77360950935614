.pointer {
  cursor: pointer;
}

.title-icon {
  padding-right: 1em;
}

img.mobile-logo {
  width: 100%;
}

.quit-pl {
  padding-left: 0px !important;
}
.quit-mb {
  margin-bottom: 0px !important;
}
textarea {
  resize: none !important;
}

body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Segoe UI", Arial, sans-serif !important;
  transition: none;
}

.row {
  margin: 0 !important;
}

h1 {
  font-size: 40px;
  font-weight: bold !important;
}

h3 {
  font-size: 24px !important;
  font-weight: 600 !important;
}

h4 {
  font-size: 20px;
  font-weight: 600 !important;
}

h5 {
  font-size: 16px !important;
  font-weight: 600 !important;
}

h6 {
  font-size: 14px !important;
  font-weight: 600 !important;
}

hr {
  background-color: #e3ebf5;
}

a {
  color: inherit;
  text-decoration: none;
}

.list-none {
  list-style: none;
}

.btn.primary-outline-btn {
  border-color: #1a4792 !important;
  color: #1a4792 !important;
}

.btn.primary-outline-btn:hover {
  background-color: #1a4792 !important;
  color: #fff !important;
}

.primary-white-outline-btn {
  background-color: #fff !important;
  border-color: #e2eaf9 !important;
  color: #1a4792 !important;
}

.primary-white-outline-btn:hover {
  background-color: #1a4792 !important;
  color: #fff !important;
}

.btn.primary-btn {
  background-color: #1a4792 !important;
}

.btn.primary-btn a {
  color: #fff;
  text-decoration: none;
}

.btn.transparent-btn {
  border-color: #bdcad0 !important;
  border-width: 1px;
  box-shadow: none;
  width: 100%;
  text-align: left;
  color: #66818d;
}

.btn.athics-btn {
  background-color: #009ed7 !important;
  border: 1px solid #009ed7 !important;
  box-shadow: none;
}

.btn.athics-lignt-btn {
  color: #009ed7 !important;
  background-color: #e8f5fd !important;
  border-color: #a0d4fa !important;
  padding: 0.5rem !important;
}

.btn.athics-lignt-btn svg {
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.btn.athics-outline-btn {
  background-color: transparent !important;
  border: 1px solid #009ed7 !important;
  color: #009ed7 !important;
  box-shadow: none;
}

.btn.athics-outline-btn:hover {
  background-color: #009ed7 !important;
  color: #fff !important;
  box-shadow: none;
}

.btn.small-danger-btn {
  background-color: #ffd7da !important;
  border: 1px solid #de463d !important;
  color: #000 !important;
  padding: 0 1rem !important;
  /* min-height: 32px; */
  box-shadow: none;
}
.btn.small-primary-btn {
  background-color: #b3e5fc !important;
  border: 1px solid #009ed7 !important;
  color: #000 !important;
  padding: 0 1rem !important;
  /* min-height: 32px; */
  box-shadow: none;
}
.btn.small-athics-btn {
  background-color: #009ed7 !important;
  color: #fff !important;
  padding: 0 1rem !important;
  /* min-height: 30px; */
  box-shadow: none;
}
.btn.small-primary-outline-btn {
  background-color: transparent !important;
  border: 1px solid #009ed7 !important;
  color: #009ed7 !important;
  padding: 0 1rem !important;
  /* min-height: 30px; */
  box-shadow: none;
}
.btn.small-warning-btn {
  background-color: #ffefc0 !important;
  border: 1px solid #ffaf00 !important;
  color: #000 !important;
  padding: 0 1rem !important;
  /* min-height: 32px; */
  box-shadow: none;
}
.btn.small-info-btn {
  background-color: #d6e6ff !important;
  border: 1px solid #198bdb !important;
  color: #000 !important;
  padding: 0 1rem !important;
  /* min-height: 32px;  */
  box-shadow: none;
}
.btn.small-success-btn {
  background-color: #c4f6d4 !important;
  border: 1px solid #449c4d !important;
  color: #000 !important;
  padding: 0 1rem !important;
  /* min-height: 32px; */
  box-shadow: none;
}
.btn.small-transparent-btn {
  border: 1px solid #bdcad0 !important;
  color: #66818d;
  padding: 0 1rem !important;
  /* min-height: 32px; */
  box-shadow: none;
}
.btn.small-disable-btn {
  border: 1px solid #d3dbe1 !important;
  background-color: #d3dbe1 !important;
  color: #66818d;
  padding: 0 1rem !important;
  /* min-height: 32px; */
  box-shadow: none;
}

.btn.download-btn {
  color: #324148 !important;
  border-radius: 4px;
  border: solid 1px #bdcad0 !important;
  background-image: linear-gradient(to bottom, #fff, #f0f2f4 120%) !important;
  padding: 0 0.5rem !important;
  box-shadow: none;
}

.btn.download-btn svg {
  width: 1rem !important;
  height: 1rem !important;
}

.btn.download-btn i {
  font-size: 14px;
}

.md-form {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.md-form input {
  margin: 0 !important;
}

.border-bottom {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.form-control.rounded-0 {
  border-radius: 4px !important;
  min-height: 47px;
}

.form-control {
  background-color: transparent;
  font-size: 14px;
  min-height: 38px;
}

.table {
  color: inherit !important;
}
.nav-tabs .nav-link {
  color: inherit;
  background-color: transparent !important;
}

.w-150 {
  width: 150px;
}

.w-160 {
  width: 160px;
  text-align: center;
  max-width: 160px;
}

.w-180 {
  width: 180px;
  text-align: center;
  max-width: 180px;
  font-size: 12px;
}

.w-200 {
  width: 200px;
  text-align: center;
}

small {
  display: block;
  font-size: 12px;
}

.font-14 {
  font-size: 14px;
}

/* .react-datepicker {
    display: flex!important;
}

.react-datepicker-wrapper input{    
    width: 100%;
    padding: 6px;
    border-radius: 5px;
    background-color: transparent;
    color: inherit;
    border: 1px solid #009ed7;
} */
.form-select {
  background: transparent !important;
  color: inherit !important;
}

.form-select option {
  background: #d9d9d9 !important;
  color: inherit !important;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}

div[data-test="card"] {
  border-radius: 6px !important;
  border: 1px solid #e3ebf5 !important;
}

.PhoneInputInput {
  background: transparent;
  color: inherit;
}

.progress {
  height: 4px !important;
}

.progress-bar {
  background-color: #009ed7 !important;
}

.form-check {
  min-height: 1rem !important;
}

.switch-icon {
  cursor: pointer;
}

.datatable {
  position: relative;
}

.rdt_Pagination {
  justify-content: start !important;
  margin-top: 30px;
}

.form-check .form-check-input {
  border-radius: 4px !important;
  margin-top: 6px !important;
}

.form-check-input:checked {
  background-color: #009ed7 !important;
  border-color: #009ed7 !important;
}

.accordion-button::after {
  width: 9px !important;
  height: 9px !important;
  background-size: 9px !important;
}

.form-group {
  margin-bottom: 0 !important;
}

.p-10 {
  padding: 10px !important;
}

.py-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.p-30 {
  padding: 30px !important;
}

.px-30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.py-30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.p-20 {
  padding: 20px !important;
}

.px-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.py-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.m-30 {
  margin: 30px !important;
}

.mx-30 {
  margin-left: 30px !important;
  margin-right: 30px !important;
}

.my-30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.m-20 {
  margin: 20px !important;
}

.mx-20 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.my-20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-50 {
  margin-top: 50px;
}

.carousel-control-next-icon {
  background-image: url("./assets/images/next.png");
  width: 24px !important;
  height: 24px !important;
}
.carousel-control-prev-icon {
  background-image: url("./assets/images/before.png");
  width: 24px !important;
  height: 24px !important;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #f0f2f4;
}

.form-control:focus {
  border: 1px solid #009ed7;
  box-shadow: none;
}

div[data-popper-placement="right"] {
  left: 10px !important;
  top: -15px !important;
}

.icon-input i {
  right: 7px;
  top: 12px !important;
  font-size: 1rem !important;
}

@media screen and (max-width: 576px) {
  .w-150,
  .w-160,
  .w-180,
  .w-200,
  .w-220,
  .w-300 {
    width: 100%;
    max-width: 100%;
  }
  h3 {
    font-size: 16px !important;
  }
  .p-30,
  .p-4 {
    padding: 16px !important;
  }
  .mt-50 {
    margin-top: 30px;
  }
}

@media (min-width: 992px) {
  .pr-lg-30 {
    padding-right: 30px !important;
  }
  .pl-lg-30 {
    padding-left: 30px !important;
  }
  .pr-lg-20 {
    padding-right: 20px !important;
  }
  .pl-lg-20 {
    padding-left: 20px !important;
  }
  .mr-lg-30 {
    margin-right: 30px !important;
  }
  .ml-lg-30 {
    margin-left: 30px !important;
  }
}

@media (min-width: 768px) {
  .pr-md-30 {
    padding-right: 30px !important;
  }
  .pl-md-30 {
    padding-left: 30px !important;
  }
  .pr-md-20 {
    padding-right: 20px !important;
  }
  .pl-md-20 {
    padding-left: 20px !important;
  }
  .mr-md-30 {
    margin-right: 30px !important;
  }
  .ml-md-30 {
    margin-left: 30px !important;
  }
}

@media (min-width: 576px) {
  .pr-sm-30 {
    padding-right: 30px !important;
  }
  .pl-sm-30 {
    padding-left: 30px !important;
  }
  .pr-sm-20 {
    padding-right: 20px !important;
  }
  .pl-sm-20 {
    padding-left: 20px !important;
  }
  .mr-sm-30 {
    margin-right: 30px !important;
  }
  .ml-sm-30 {
    margin-left: 30px !important;
  }
}
