@import "../../theme/colors";
// from './AlertFlags'
.snackbar {
  border: $color-blueGray200 0.5px solid;
  background: $color-white;
  padding: 8px 16px;
  min-height: 6vh;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-flow: row nowrap;
  box-shadow: $color-blueGray500 0 1px 1px 0.2px;
  position: relative;
  &__icon {
    display: flex;
    width: 2rem;
    height: 1.6rem;
    justify-content: center;
    text-align: center;
    align-items: center;
  }
  &__message {
    padding: 0.5rem 0.7rem;
    font-size: 0.9rem;
    font-weight: 600;
    text-align: justify;
  }
  &__close {
    scale: 0.8;
    border: none;
    position: absolute;
    top: -8px;
    right: -7px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 0;
    background: $color-blueGray500;
    svg {
      path {
        fill: $color-white;
      }
    }
  }
  &__close:hover {
    background: $color-black;
    scale: 1;
  }
  &__close:hover span {
    color: $color-charcoalGrey;
  }
  &__toggle {
    color: $color-ethicsBlue;
    display: flex;
    font-weight: bolder;
    font-size: 2rem;
    border-radius: 100px;
    background-color: transparent;
    height: 1.5rem;
    width: 1.5rem;
    justify-content: center;
    align-items: center;
    padding: 0;
    border: none;
  }
  &__toggle:hover {
    background: $color-oxfordBlue;
    scale: 0.9;
  }
}
.iconClose {
  text-align: center;
  margin: auto 0;
  color: $color-blueGray500;
  // path {
  // fill: $color-blueGray200;
  // }
}
//General DarkMode
.dark-mode .snackbar {
  border: $color-darkBorder 1px solid;
  background-color: $color-oxfordBlue2;
  box-shadow: $color-black 0 1px 1px 0.2px;
  &__message {
    color: $color-silver2;
  }
}
.--snackSuccess {
  border-left: $color-green solid 6px;
}
.dark-mode .--snackSuccess {
  border-left: $color-green solid 6px !important;
}
.--snackError {
  border-left: $color-red solid 6px;
  path {
    fill: $color-red;
  }
}
.dark-mode .--snackError {
  border-left: $color-red solid 6px !important;
}
.--snackInfo {
  border-left: $color-blue solid 6px;
  path {
    fill: $color-blue;
  }
}
.dark-mode .--snackInfo {
  border-left: $color-blue solid 6px !important;
}
.--snackWarning {
  border-left: $color-yellow solid 6px;
  path {
    fill: $color-yellow;
  }
}
.dark-mode .--snackWarning {
  border-left: $color-yellow solid 6px !important;
}
.--success {
  color: $color-green;
  path {
    fill: $color-green;
  }
}
.--error {
  color: $color-red;
}
.--info {
  color: $color-blue;
}
.--warning {
  color: $color-yellow;
}
