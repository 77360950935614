@import '../../theme/colors';

.searchsInputs {
  margin-top: 30px;
  margin-left: 10px;
  display: flex;
}

.searchsInputsbiggest {
  height: 30px;
  margin-left: 10px;
  padding: 10px 0 0 0;
  display: flex;
  border: 1px solid #6a6a6a;
  align-items: bottom;
  // height: 9px;
  margin: 8px 0 7px 8px;
  font-family: SegoeUI;
  // font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  // line-height: 0.75;
  letter-spacing: normal;
  color: #6a6a6a;
}


.input-search {
  font-size: 8px;
  align-items: baseline;
}

.search {
  width: 220px;
  height: 38px;
  margin: 3px 20px 3px 10px;
  padding: 7px 25px 7px 8px;
  border-radius: 4px;
  border: solid 1px #dee1e4;
}

.searchsInputs {
  width: 100%;
  height: 9px;
  margin: 8px 0 7px 8px;
  font-family: SegoeUI;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.75;
  letter-spacing: normal;
  color: #6a6a6a;
}

.searchIcon {
  width: 16px;
  height: 16px;
  margin: -5px 12px 0px -3px;
  object-fit: contain;
  filter: invert(69%) sepia(18%) saturate(195%) hue-rotate(161deg) brightness(87%) contrast(83%);
}

.close-icon {
  width: 16px;
  height: 16px;
  margin: -5px 0px 0px 4px;
  object-fit: contain;
  filter: invert(69%) sepia(18%) saturate(195%) hue-rotate(161deg) brightness(87%) contrast(83%);
}

.searchIconBiggest {
  width: 16px;
  height: 16px;
  margin: -19px 12px 0px 2px;
  object-fit: contain;
  filter: invert(69%) sepia(18%) saturate(195%) hue-rotate(161deg) brightness(87%) contrast(83%);
}

.searchIcon input {
  background: transparent !important;
}

#header-search {
  width: 100%;
  height: 20px;
  padding: 5px;
  margin: -5px 0 7px -5px;
  font-family: SegoeUI;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.75;
  letter-spacing: normal;
  color: #6a6a6a;
  border: red;
}

.custom-search {
  width: 100%;
  max-width: 360px;
  height: 38px;
  margin: 3px 20px 3px 0;
  padding: 7px 25px 7px 8px;
  border-radius: 4px;
  border: solid 1px #dee1e4;

  background-color: white;
  box-shadow: 0px 1px 0px #dee1e4;

  .searchsInputs {
    width: 100%;
    height: 9px;
    margin: 8px 0 7px 8px;
    font-family: SegoeUI;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: normal;
    color: #6a6a6a;
    border: none;

    &:focus {
      background-color: transparent;
    }
  }

  .custom-search {
    width: 100%;
    max-width: 360px;
    height: 38px;
    margin: 3px 20px 3px 0;
    padding: 7px 25px 7px 8px;
    border-radius: 4px;
    border: solid 1px #dee1e4;
    background-color: white;

    .searchsInputs {
      width: 100%;
      height: 9px;
      margin: 8px 0 7px 8px;
      font-family: SegoeUI;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.75;
      letter-spacing: normal;
      color: #6a6a6a;
    }

    .searchIcon {
      svg {
        width: 16px !important;
        height: 16px !important;
        object-fit: contain;
        margin-top: 0.3rem;
      }
    }

    #header-search {
      background-color: transparent !important;
      color: inherit !important;
    }
  }

  #header-search {
    background-color: transparent !important;
    color: inherit !important;
  }
}

.info-border-form .custom-search {
  max-width: 100%;
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .search {
    width: 100%;
    margin: 3px 0;
  }
}

.search-details {
  position: absolute;
  top: 100%;
  width: 100%;
  background-color: white;
  border: 1px solid #ccc;
  border-top: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 11;
  padding: 0px 20px 20px 20px;
  width: 50vw;
  margin-top: -7px;
  // margin: -7px 20px 20px 20px;
}

.search-details-searcher {
  position: absolute;
  top: 100%;
  width: 100%;
  background-color: white;
  border: 1px solid #ccc;
  border-top: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 11;
  padding: 0px 20px 20px 20px;
  width: 50vw;
  margin-top: -20px;
  // margin: -7px 20px 20px 20px;
}

.search-details-divider {
  border-top: 1px solid #B5C0C8;
  margin-bottom: 1px;
  max-width: 100% !important;
  min-width: 100% !important;
}

.search-details-section {
  margin-top: 20px;
}

.search-details-section-searcher {
  margin-top: 20px;
}


.search-details-section h3 {
  margin-bottom: 10px;
  font-size: 18px;
}

.search-details-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #ccc;

}

.search-details-content {
  display: flex;
  padding: 10px !important;
  margin-top: -15px;
  margin-right: 20px;

}

.search-details-rows {
  flex-direction: column;
  flex: 2;
  margin-left: -20px;
  margin-right: -20px;
}

.search-details-rows p {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px;
  font-weight: 400;
}


.search-details-column-icon {
  margin-right: 12px;
  display: flex;
  align-items: center;
  margin-top: 8px;
  padding: 5px;
  border-radius: 7px;
  background-color: $color-Gray;

  g {
    fill: $color-white !important;
  }
}

.search-details-column-iconnew {
  margin-right: 12px;
  display: flex;
  align-items: center;
  margin-top: 8px;
  padding: 5px;
  border-radius: 7px;
  max-width: 32px !important;
  max-width: 32px !important;
  background-color: $color-blue;

  g {
    fill: $color-white !important;
  }
}

.search-details-column-icondraft {
  margin-right: 12px;
  display: flex;
  align-items: center;
  margin-top: 8px;
  padding: 5px;
  border-radius: 7px;
  background-color: $color-Gray;

  g {
    fill: $color-white !important;
  }
}

.search-details-column-iconpending {
  margin-right: 12px;
  display: flex;
  align-items: center;
  margin-top: 8px;
  padding: 5px;
  border-radius: 7px;
  background-color: $color-purple;

  g {
    fill: $color-white !important;
  }

}

.search-details-column-iconprocess {
  margin-right: 12px;
  display: flex;
  align-items: center;
  margin-top: 8px;
  padding: 5px;
  border-radius: 7px;
  background-color: $color-green;

  g {
    fill: $color-white !important;
  }
}

.search-details-column-iconfinish {
  margin-right: 12px;
  display: flex;
  align-items: center;
  margin-top: 8px;
  padding: 5px;
  border-radius: 7px;
  background-color: $color-yellow;

  g {
    fill: $color-white !important;
  }
}

.search-details-column-iconclosed {
  margin-right: 12px;
  display: flex;
  align-items: center;
  margin-top: 8px;
  padding: 5px;
  border-radius: 7px;
  background-color: $color-red;

  g {
    fill: $color-white !important;
  }
}


.search-details-column-folio {
  display: flex;
  align-items: center;
  padding: 10px;
}


.search-details-column-folio p {
  margin: 5px 0 0 -10px;
  font-size: 16px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: normal;
}


.search-details-column {
  flex: 1;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.search-details-column-subject p {
  margin: 5px 0 0 -40%;
  font-size: 16px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: 100%;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: normal;
}



.search-details-column-tracking p {
  margin: 0 0 0 0;
  font-size: 16px;
  text-align: left;
  // white-space: nowrap;       
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100% !important;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: normal;
}


.search-details-row {
  border-bottom: 1px solid #B5C0C8 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  // margin-bottom: 10px;
  max-height: 80%;
}

.search-details-row:hover {
  background-color: #f0f0f0;
  transform: scale(1.03);
  transition: transform 0.3s;
}

// .search-details-row:hover  {
//   transform: scale(1.03);  /* Aumentar el tamaño del elemento al 110% */
//   transition: transform 0.3s;  /* Agregar una transición suave */
// }


.vertical-divider {

  width: 2px;
  height: 100%;
  background-color: #ccc;
}


.section {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  /* Alineación en columna */
  margin-bottom: 20px;
  margin-right: -20px;
}

.Col {
  display: flex;
  flex-direction: column;

}

.entry-medium h6 {
  align-items: right;
  margin-left: 12px;
}


.entry-medium p {
  font-size: 14px;
}

.entry-medium {

  margin-top: 10px;
}

.entry-label {
  margin-bottom: 0;
  padding-bottom: 4px;
}

.entry-medium select {
  width: 100%;
  padding: 8px;
  font-size: 14px;
}

.status {
  margin-top: 30px;
}

.status p {
  font-size: 14px;
}

.status-label {
  margin-bottom: 0;
  padding-bottom: 4px;
}

.entryway {
  margin-top: 15px;
}

.severity {
  margin-top: 5px;
}

.created-to {
  margin-top: 10px;
  margin-left: 8px;
}

.created-from {
  margin-top: 10px;
  margin-left: 8px;
}

.col-0-5 {
  flex: 0 0 4.167%;
  max-width: 4.167%;
}


.custom-searchBiggest {
  width: 50vw;
  height: 38px;
  margin: 3px 0px 3px 0;
  padding: 7px 25px 7px 8px;
  border-radius: 4px;
  border: solid 1px #009ed7;
  background-color: white;
  box-shadow: 0px 1px 0px #dee1e4;
  transition: opacity 0.5s ease;

  .searchsInputs {
    width: 100%;
    height: 9px;
    margin: 8px 0 7px 8px;
    font-family: SegoeUI;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: normal;
    color: #6a6a6a;
    border: none;

    &:focus {
      background-color: transparent;
    }
  }

  .custom-search {
    width: 100%;
    max-width: 360px;
    height: 38px;
    margin: 3px 20px 3px 0;
    padding: 7px 25px 7px 8px;
    border-radius: 4px;
    border: solid 1px #dee1e4;
    background-color: white;

    .searchsInputs {
      width: 100%;
      height: 9px;
      margin: 8px 0 7px 8px;
      font-family: SegoeUI;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.75;
      letter-spacing: normal;
      color: #6a6a6a;
    }

    .searchIcon {
      svg {
        width: 16px !important;
        height: 16px !important;
        object-fit: contain;
        margin-top: 0.3rem;
      }
    }



    #header-search {
      background-color: transparent !important;
      color: inherit !important;
    }
  }

  #header-search {
    background-color: transparent !important;
    color: inherit !important;
  }
}

.select-container {
  position: relative;
  width: 100%;
}

.select-container .floating-label {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  font-size: 14px;
  color: #999;
  pointer-events: none;
  transition: all 0.3s ease;
}

.select-container .floating-label.active {
  top: -10px;
  left: 5px;
  font-size: 12px;
  color: #009ed7;
}


.scroll-container {
  max-height: 800px;
  overflow-y: hidden;
  overflow-x: hidden;
  padding-right: 15px;
}


.custom-datepicker {
  position: relative;
  // min-height: 300px !important;
  max-height: 400px !important;

}

.custom-datepicker .react-datepicker__tab-loop {
  z-index: 10;
}

.custom-search {
  .form-control {
    min-width: 100%;
  }
}


.table-results {
  margin-top: -14px !important;
  margin-left: -33px !important;
  font-size: 3px !important;
}


.custom-form-control-label {
  margin-bottom: -15px !important;
  margin-left: 0px !important;
}



.custom-date-picker .MuiInputBase-root {
  height: 38px !important;
  margin-top: 13px !important;
  min-width: 100% !important;

}

.custom-date-picker input {
  margin-top: 0px !important;
  height: 13px !important;
  padding: 15px 14px !important;
}

.custom-date-picker label {
  padding: 5px 0px !important;
}



.modal-content {
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  overflow: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.col {
  flex: 1;
  min-width: 0;
}

.datepickers,
.catalog,
.subcatalog,
.apply-filters {
  width: 100%;
  box-sizing: border-box;
}



.catalog {
  display: flex;
  flex-direction: column;
  margin-top: 14px;
  width: 100%;
  min-height: 38px !important;
  max-height: 38px !important;
}


.subcatalog {
  display: flex;
  // flex-wrap: wrap; 
  flex-direction: column;
  margin-top: 15px;
  // margin-left: 8px;
  width: 100%;
}




.apply-filters {
  display: flex;
  margin-top: 23px;
  margin-left: 10px;
  min-height: 38px !important;
  // max-height: 38px !important;
  min-width: 50% !important;
  max-width: 100% !important;
}

/* Estilos para pantallas medianas (tablets) */
@media (max-width: 768px) {
  .apply-filters {
    margin-top: 15px;
    margin-left: 8px;
    min-height: 60px !important;
    max-height: 60px !important;
    min-width: 100% !important;
    max-width: 100% !important;
  }
}

/* Estilos para pantallas pequeñas (móviles) */
@media (max-width: 480px) {
  .apply-filters {
    margin-top: 10px;
    margin-left: 5px;
    min-height: 70px !important;
    max-height: 70px !important;
    min-width: 100% !important;
    max-width: 100% !important;
  }
}



.pruebita {
  margin-bottom: 20px;
  margin-left: -30px !important;
}

.tabs-class {
  margin-left: -10px !important;
  margin-top: -20px;
}

.icon-indicator {
  g {
    fill: #fff;
  }
}

.bordePrueba {
  border-left: 1px solid #B5C0C8 !important;
}