@import "../../theme/colors";
#dashboard{
    flex-grow: 1;
    padding: 20px 18px 30px 48px;
    .dashboard-card{
        border-radius: 4px;
    }
    &.dark-mode{
        .border-bottom{
            border-bottom: 1px solid $color-darkBorder!important;
        }
        h3{
            color: $color-paleGrey;
        }
        p, h4, h5, h6{
            color: $color-blueGray2;
        }
        .border, .form-control, .react-datepicker-wrapper input {
            border: 1px solid $color-darkBorder!important;
            color: $color-blueGray2;
        }
    }
}

.dashboard-default{
    padding: 30px;
}

.new-graph {
    width: auto;
    height: 98px;
    margin-bottom: 20px;
    border-radius: 4px;
    border:1px dashed #8aa1ac !important;
    display: flex;
    align-items: center;
    justify-content: center;
    
    background:  linear-gradient(180deg, #ffffff 0%, #f3f8fb 100%);
}

.indicators-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

@media screen and (max-width: 992px) {
    #dashboard{
        padding: 1rem;
    }    
}

@media screen and (max-width: 576px) {
    #dashboard.dashboard{
        padding: 0;
    }
    
    .move{
        order: 2;
    }
}
